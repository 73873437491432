import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import actions from "./actions";
import AxiosInterceptor from "../../Services/axiosInterceptor";

const addToCart = async(payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.post(url, body);
  return result;
};
const getCart = async(payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.get(url);
  return result;
};
const deletecart = async(payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.delete(url);
  return result;
};
const editCart = async(payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.put(url, body);
  return result;
};

export function* addTocart() {
  yield takeEvery("ADD_TO_CART", function* ({ payload }) {
    try {
      const res = yield call(addToCart, payload);
      if (res) {
        yield put({
          type: actions.ADD_TO_CART_SUCCESS,
          message: res.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.ADD_TO_CART_ERROR,
        errorMessage: "Something went wrong. Please try after some time.",
      });
    }
  });
}
export function* getcartSaga() {
  yield takeEvery("GET_CART_REQUEST", function* ({ payload }) {
    try {
      const res = yield call(getCart, payload);
      if (res) {
        yield put({
          type: actions.GET_CART_SUCCESS,
          data: res?.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.GET_CART_ERROR,
        errorMessage: "Something went wrong. Please try after some time.",
      });
    }
  });
}
export function* editcartSaga() {
  yield takeEvery("EDIT_CART_REQUEST", function* ({ payload }) {
    try {
      const res = yield call(editCart, payload);
      if (res) {
        yield put({
          type: actions.EDIT_CART_SUCCESS,
          message: res.message,
          status_code: res.status_code,
        });
      }
    } catch (error) {
      yield put({
        type: actions.EDIT_CART_ERROR,
        errorMessage: "Something went wrong. Please try after some time.",
      });
    }
  });
}
export function* deleteCart() {
  yield takeEvery("DELETE_CART_REQUEST", function* ({ payload }) {
    try {
      const res = yield call(deletecart, payload);
      if (res.status_code===1) {
        yield put({
          type: actions.DELETE_CART_SUCCESS,
          message: res.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.DELETE_CART_ERROR,
        errorMessage: "Something went wrong. Please try after some time.",
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(addTocart)]);
  yield all([fork(getcartSaga)]);
  yield all([fork(editcartSaga)]);
  yield all([fork(deleteCart)]);
}
