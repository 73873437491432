import actions from "../../Redux/search/action";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  isFetching: false,
  searchResult: [],
  successMessages: "",
  errorMessage: "",
  actionTypes: "",
  statuscodes: 0,
});

export default function searchReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SEARCH_PRODUCTS_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionType: "SEARCH_PRODUCTS_REQUEST",
      });

    case actions.SEARCH_PRODUCTS_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        actionTypes: "SEARCH_PRODUCTS_SUCCESS",
        searchResult: action.data,
        successMessages: action.message,
        statuscodes: action.statuscode,
      });

    case actions.SEARCH_PRODUCTS_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionTypes: "SEARCH_PRODUCTS_ERROR",
        errorMessage: action.message,
      });
    case actions.RESET_SEARCH_STATE:
      return Immutable.merge(state, {
        actionTypes: "",
        successMessages: "",
        statuscodes: 0,
      });

    default:
      return state;
  }
}
