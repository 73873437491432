import React, { useEffect } from "react";
import { Form, Input, Button, Divider, Checkbox, notification } from "antd";
import {
  GoogleOutlined,
  FacebookOutlined,
  LinkedinOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../../Styles/login.css";
import actions from "../../Redux/login/actions";
import config from "../../Services/siteConfig";

const Login = () => {
  const [form] = Form.useForm();
  const [formErrorMessage, setFormErrorMessage] = React.useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUser, actionType, errorMessage } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (actionType === "LOGIN_SUCCESS" && currentUser.is_verified === true) {
      navigate("/homepage");
      setTimeout(() => {
        notification.success({
          message: "Login Successful",
        });
      }, 500);
      dispatch(actions.resetActionType());
    } else if (
      actionType === "LOGIN_SUCCESS" &&
      currentUser.is_verified === false
    ) {
      setTimeout(() => {
        notification.success({
          message: "Please Verify OTP",
        });
      }, [500]);
      navigate("/otpverification");

      dispatch(actions.resetActionType());
    } else {
      if (actionType === "LOGIN_ERROR") {
        setTimeout(() => {
          notification.error({
            message: errorMessage,
          });
          dispatch(actions.resetActionType());
        }, 500);
      }
    }
  }, [actionType, currentUser, dispatch, navigate, errorMessage]);

  const handleSubmit = (values) => {
    const { user_name, password } = values;
    setFormErrorMessage({});
    console.log(values)

    let inputType = null;
    if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()\[\]\\.,;:\s@"]+\.)+[^<>()\[\]\\.,;:\s@"]{2,})$/i.test(
        user_name
      )
    ) {
      inputType = "email";
    } else if (/^[6789]\d{9}$/.test(user_name)) {
      inputType = "mobile";
    } else {
      setFormErrorMessage({
        user_name: "Please enter a valid email or mobile number.",
      });
      return;
    }
    sessionStorage.setItem("type", inputType);
    console.log("Input Type:", inputType); // Console log the input type

    if (!user_name || !password) {
      setFormErrorMessage({
        user_name: "Please enter your email or mobile number.",
        password: "Please enter your password.",
      });
      return;
    }

    // const payload = {
    //   type: inputType,
    //   user_name: values.user_name,
    //   password: values.password,
    // };
    // console.log(payload)

    dispatch(
      actions.login(
        `${config.login}?type=${encodeURIComponent(inputType)}&user_name=${encodeURIComponent(values.user_name)}&password=${encodeURIComponent(values.password)}`
      )
    );
    
  };

  return (
    <div className="container">
      <div className="login-box">
        <h1 className="login-title">
          <UserOutlined style={{ marginRight: "10px", color: "blue" }} />
          User Login
        </h1>
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          form={form}
          initialValues={{
            user_name: "",
            password: "",
          }}
        >
          <Form.Item
            label={<b>Email or Mobile Number</b>}
            name="user_name"
            validateStatus={formErrorMessage.user_name ? "error" : ""}
            help={formErrorMessage.user_name}
            rules={[
              {
                required: true,
                message: "Please enter your email or mobile number!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={<b>Password</b>}
            name="password"
            validateStatus={formErrorMessage.password ? "error" : ""}
            help={formErrorMessage.password}
            rules={[
              {
                required: true,
                message: formErrorMessage.password
                  ? formErrorMessage.password
                  : "Please enter your password!",
              },
              {
                validator: (_, value) => {
                  if (!value || value.length >= 8) {
                    return Promise.resolve();
                  }
                  return Promise.reject();
                },
                validateTrigger: "onSubmit",
                message: "Password must be at least 8 characters.",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Checkbox style={{ marginRight: "10px" }}>Remember me</Checkbox>
            <Link to="/forgetPassword" className="form-link">
              Forgot password?
            </Link>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%", borderRadius: "40px" }}
            >
              Login
            </Button>
          </Form.Item>
        </Form>
        <Divider>Or continue with</Divider>
        <div className="social-buttons">
          <Button
            icon={<GoogleOutlined style={{ fontSize: "1.5em" }} />}
            className="social-button"
            style={{
              background: "#db4437",
              color: "white",
              marginRight: "5px",
            }}
          />
          <Button
            icon={<FacebookOutlined style={{ fontSize: "1.5em" }} />}
            className="social-button"
            style={{ background: "#4267B2", color: "white", margin: "0 5px" }}
          />
          <Button
            icon={<LinkedinOutlined style={{ fontSize: "1.5em" }} />}
            className="social-button"
            style={{ background: "#0a66c2", color: "white", marginLeft: "5px" }}
          />
        </div>
        <div className="signup-link">
          <Divider>Don't have an account?</Divider>
          <div className="create-account-box">
            <Link to="/registration" className="signup-link">
              Create your account
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
