import { Rate } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Form, Input, Upload, Card,notification } from "antd";
import { UploadOutlined, StarFilled } from "@ant-design/icons";
import { useParams,useNavigate} from "react-router-dom";
import AxiosInterceptor from "../../Services/axiosInterceptor";
import config from "../../Services/siteConfig";
import actions from "../../Redux/reviews/actions"
import { useDispatch,useSelector } from "react-redux";

const Review = () => {
  const dispatch = useDispatch();
  const navigate=useNavigate()
  const { id } = useParams();
  const [uploadedImages, setUploadedImages] = useState([]);
  const [uploadedVideos, setUploadedVideos] = useState([]);
  const { actionType_review ,successMessage2 } = useSelector((state) => {
    return state.productreview;
  });
  useEffect(() => {
    if (actionType_review === "CREATE_REVIEW_SUCCESS" ) {
      notification.success({
        message: successMessage2,
      });
      dispatch(actions.resetActionType());
      navigate("/")
      
    }
  }, [actionType_review, dispatch, successMessage2]);
  const onFinish = async (values) => {
    try {
      // Upload files to S3 bucket
      const imageUrls = await Promise.all(
        uploadedImages?.map((file) => uploadFile(file))
      );
      const videoUrls = await Promise.all(
        uploadedVideos?.map((file) => uploadFile(file))
      );

      // Prepare form data with URLs
      const formData = {
        user_id: sessionStorage.getItem("id"),
        product_id: id,
        rating: values.rating,
        review_title: values.review_title,
        review_text: values.review_text,
        tags: ["quality", "value"],
        locale: "en-US", 
        reviewer_name:"harish",
        images: [
          ...imageUrls?.map((url) => ({
           url: url,
            type: "image",
          })),
          ...videoUrls?.map((url) => ({
            url:url,
            type: "video",
          })),
        ]        
      };

      console.log(formData);
      try {
          dispatch(actions.createReview(config.addreview, formData));
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.error("Error submitting review:", error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };



  const uploadFile = async (file) => {
    try {
      const formData = new FormData();
      formData.append("image", file.originFileObj);
      const resultData = await AxiosInterceptor.post(
        `${config.upload}`,
        formData
      );

      if (resultData?.status_code === 1) {
        console.log(resultData?.data.Location);
        return resultData?.data?.Location;
      } else {
        console.error("Failed to upload file:", resultData?.data);
        throw new Error("Failed to upload file to S3");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  const containerStyle = {
    backgroundImage: "linear-gradient(to bottom, #f0f2f5, #fff)",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "50px",
    fontFamily: "Open Sans, sans-serif",
  };

  const titleStyle = {
    textAlign: "center",
    fontSize: "2rem",
    color: "#333",
    marginBottom: "30px",
    fontWeight: "bold",
    textShadow: "0 2px 4px rgba(0,0,0,0.1)",
  };

  const cardStyle = {
    width: "90%",
    maxWidth: "600px",
    borderRadius: "16px",
    boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
    padding: "30px",
    backgroundColor: "#fff",
    border: "none",
    overflow: "hidden",
    transition: "all 0.3s ease",
  };

  const labelStyle = {
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: "#333",
    textShadow: "0 1px 2px rgba(0,0,0,0.05)",
  };

  const inputStyle = {
    borderRadius: "8px",
    height: "45px",
    padding: "12px",
    fontSize: "1.1rem",
    border: "1px solid #e8e8e8",
    boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
    transition: "all 0.3s ease",
  };

  const textareaStyle = {
    ...inputStyle,
    minHeight: "120px",
  };

  const buttonStyle = {
    borderRadius: "8px",
    height: "45px",
    padding: "0 25px",
    fontSize: "1.1rem",
    fontWeight: "bold",
    border: "none",
    backgroundColor: "#1890ff",
    color: "#fff",
    cursor: "pointer",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    transition: "all 0.3s ease",
  };

  return (
    <div style={containerStyle}>
      <h1 style={titleStyle}>Add Review</h1>
      <Card style={cardStyle}>
        <Form
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="rating"
            label={
              <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                Rate this product
              </span>
            }
            rules={[{ required: true, message: "Rate the product required!" }]}
          >
            <Rate
              style={{ fontSize: "2rem", color: "#faad14" }}
              character={<StarFilled />}
            />
          </Form.Item>

          <Form.Item
            label={<span style={labelStyle}>Review Title</span>}
            name="review_title"
            rules={[{ required: true, message: "Review title required!" }]}
          >
            <Input style={inputStyle} placeholder="Enter your review title" />
          </Form.Item>

          <Form.Item
            label={<span style={labelStyle}>Review Text</span>}
            name="review_text"
            rules={[{ required: true, message: "Please input your review text!" }]}
          >
            <Input.TextArea
              style={textareaStyle}
              placeholder="Enter your review text"
            />
          </Form.Item>

          <Form.Item
            label={<span style={labelStyle}>Add a photo or video</span>}
          >
            <Upload
              name="images"
              accept="image/*, video/mp4, video/x-m4v, video/*"
              multiple
              listType="picture-card"
              onRemove={(file) => {
                if (file.type.startsWith("image/")) {
                  setUploadedImages((prevImages) =>
                    prevImages.filter((img) => img.uid !== file.uid)
                  );
                } else {
                  setUploadedVideos((prevVideos) =>
                    prevVideos.filter((vid) => vid.uid !== file.uid)
                  );
                }
              }}
              onChange={(info) => {
                const { fileList } = info;
                const images = fileList.filter((file) => file.type.startsWith("image/png"));
                const videos = fileList.filter((file) => file.type.startsWith("video/mp4"));
                setUploadedImages(images);
                setUploadedVideos(videos);
              }}
            >
              <Button icon={<UploadOutlined />}></Button>
            </Upload>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={buttonStyle}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Review;