import { takeEvery, put, call, all, fork } from "redux-saga/effects";
import actions from "../MobileEmailverify/actions";
import AxiosInterceptor from "../../Services/axiosInterceptor";

const emailOtpsend = async (payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.post(url, body);
  return result;
};

const emailOtpResend = async (payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.post(url, body);
  return result;
};

const emailOtpVerify = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.get(url);
  return result;
};
const mobileOtpSend = async (payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.post(url, body);
  return result;
};

const mobileOtpResend = async (payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.post(url, body);
  return result;
};

const mobileOtpVerify = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.get(url);
  return result;
};
export function* emailOTPRequest() {
  yield takeEvery(actions.EMAIL_OTP_SEND_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(emailOtpsend, payload);
      if (res) {
        yield put({
          type: actions.EMAIL_OTP_SEND_SUCCESS,
          data: res,
          status_code: res.status_code,
          message: res.message,
        });
        localStorage.setItem("email_otp_id", res.userData.id);
      } else {
        yield put({
          type: actions.EMAIL_OTP_SEND_ERROR,
          errorMessage:
            res.data.message || "Something went wrong. Please try again later.",
        });
      }
    } catch (error) {
      yield put({
        type: actions.EMAIL_OTP_SEND_ERROR,
        errorMessage: "Something went wrong. Please try again later.",
      });
    }
  });
}

export function* emailOTPResendRequest() {
  yield takeEvery(actions.EMAIL_OTP_RESEND_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(emailOtpResend, payload);
      if (res) {
        yield put({
          type: actions.EMAIL_OTP_RESEND_SUCCESS,
          data: res,
          status_code: res.status_code,
          message: res.message,
        });
      } else {
        yield put({
          type: actions.EMAIL_OTP_RESEND_ERROR,
          errorMessage:
            res.data.message || "Something went wrong. Please try again later.",
        });
      }
    } catch (error) {
      yield put({
        type: actions.EMAIL_OTP_RESEND_ERROR,
        errorMessage: "Something went wrong. Please try again later.",
      });
    }
  });
}

export function* emailOTPVerifyRequest() {
  yield takeEvery(actions.EMAIL_OTP_VERIFY_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(emailOtpVerify, payload);
      if (res) {
        yield put({
          type: actions.EMAIL_OTP_VERIFY_SUCCESS,
          data: res,
          status_code: res.status_code,
          message: res.message,
        });
        localStorage.setItem("email_otp_id", res.userData.id);
      } else {
        yield put({
          type: actions.EMAIL_OTP_VERIFY_ERROR,
          errorMessage:
            res.data.message || "Something went wrong. Please try again later.",
        });
      }
    } catch (error) {
      yield put({
        type: actions.EMAIL_OTP_VERIFY_ERROR,
        errorMessage: "Something went wrong. Please try again later.",
      });
    }
  });
}

export function* mobileOTPSendRequest() {
  yield takeEvery(actions.MOBILE_OTP_SEND_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(mobileOtpSend, payload);
      if (res) {
        yield put({
          type: actions.MOBILE_OTP_SEND_SUCCESS,
          data: res,
          status_code: res.status_code,
          message: res.message,
        });
        localStorage.setItem("mobile_otp_id", res.userData.id);
      } else {
        yield put({
          type: actions.MOBILE_OTP_SEND_ERROR,
          errorMessage:
            res.data.message || "Something went wrong. Please try again later.",
        });
      }
    } catch (error) {
      yield put({
        type: actions.MOBILE_OTP_SEND_ERROR,
        errorMessage: "Something went wrong. Please try again later.",
      });
    }
  });
}

export function* mobileOTPResendRequest() {
  yield takeEvery(actions.MOBILE_OTP_RESEND_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(mobileOtpResend, payload);
      if (res) {
        yield put({
          type: actions.MOBILE_OTP_RESEND_SUCCESS,
          data: res,
          status_code: res.status_code,
          successmessage: res.message,
        });
      } else {
        yield put({
          type: actions.MOBILE_OTP_RESEND_ERROR,
          errorMessage:
            res.data.message || "Something went wrong. Please try again later.",
        });
      }
    } catch (error) {
      yield put({
        type: actions.MOBILE_OTP_RESEND_ERROR,
        errorMessage: "Something went wrong. Please try again later.",
      });
    }
  });
}

export function* mobileOTPVerifyRequest() {
  yield takeEvery(actions.MOBILE_OTP_VERIFY_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(mobileOtpVerify, payload);
      if (res) {
        yield put({
          type: actions.MOBILE_OTP_VERIFY_SUCCESS,
          data: res,
          status_code: res.status_code,
          message: res.message,
        });
        localStorage.setItem("mobile_otp_id", res.userData.id);
      } else {
        yield put({
          type: actions.MOBILE_OTP_VERIFY_ERROR,
          errorMessage: res.message,
          status_code: res.status_code,
        });
      }
    } catch (error) {
      yield put({
        type: actions.MOBILE_OTP_VERIFY_ERROR,
        errorMessage: "Something went wrong. Please try again later.",
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(emailOTPRequest)]);
  yield all([fork(emailOTPResendRequest)]);
  yield all([fork(emailOTPVerifyRequest)]);
  yield all([fork(mobileOTPSendRequest)]);
  yield all([fork(mobileOTPResendRequest)]);
  yield all([fork(mobileOTPVerifyRequest)]);
}
