import actions from "../login/actions";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  currentUser: {},
  verifyotp:'',
  isFetching: false,
  successMessage: "",
  errorMessage: "",
  actionType: "",
  status_code:0
});

export default function authLoginReducer(state = initialState, action) {
  switch (action.type) {
    case actions.LOGIN_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.LOGIN_SUCCESS:
      return Immutable.merge(state, {
        currentUser: action.data,
        actionType: "LOGIN_SUCCESS",
        successMessage: action.message,
      });
    case actions.LOGIN_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: "LOGIN_ERROR",
        errorMessage: action.errorMessage,
      });
   
    case actions.LOGIN_OTP_RESEND_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionType: "LOGIN_OTP_RESEND_REQUEST",
      });
    case actions.LOGIN_OTP_RESEND_SUCCESS:
      return Immutable.merge(state, {
        actionType: "LOGIN_OTP_RESEND_SUCCESS",
        successMessage: action.message,
      });
    case actions.LOGIN_OTP_RESEND_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: "LOGIN_OTP_RESEND_ERROR",
        errorMessage: action.errorMessage,
      });
    case actions.RESET_ACTIONTYPE:
      return Immutable.merge(state, {
        actionType: "",
      });
    default:
      return state;
  }
}
