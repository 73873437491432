import { takeEvery, put, call, all, fork } from "redux-saga/effects";
import actions from "../../Redux/updatemobileverify/actions";
import AxiosInterceptor from "../../Services/axiosInterceptor";

const editmobileOtpSend = async (payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.post(url, body);
  return result;
};

const editmobileOtpresend = async (payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.post(url, body);
  return result;
};

const editmobileOtpVerify = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.get(url);
  return result;
};

export function* editmobileOTPSendRequest() {
  yield takeEvery(
    actions.EDIT_PROFILE_MOBILE_OTP_SEND_REQUEST,
    function* ({ payload }) {
      try {
        const res = yield call(editmobileOtpSend, payload);
        if (res) {
          yield put({
            type: actions.EDIT_PROFILE_MOBILE_OTP_SEND_SUCCESS,
            status_code: res.status_code,
            message: res.message,
          });
        } else {
          yield put({
            type: actions.EDIT_PROFILE_MOBILE_OTP_RESEND_FAILURE,
            errorMessage: res.data.message,
          });
        }
      } catch (error) {
        yield put({
          type: actions.EDIT_PROFILE_MOBILE_OTP_RESEND_FAILURE,
          errorMessage: "Something went wrong. Please try again later.",
        });
      }
    }
  );
}

export function* editmobileOTPResendRequest() {
  yield takeEvery(
    actions.EDIT_PROFILE_MOBILE_OTP_RESEND_REQUEST,
    function* ({ payload }) {
      try {
        const res = yield call(editmobileOtpresend, payload);
        if (res) {
          yield put({
            type: actions.EDIT_PROFILE_MOBILE_OTP_RESEND_SUCCESS,
            data: res,
            status_code: res.status_code,
            message: res.message,
          });
        } else {
          yield put({
            type: actions.EDIT_PROFILE_MOBILE_OTP_RESEND_FAILURE,
            errorMessage: res.data.message,
          });
        }
      } catch (error) {
        yield put({
          type: actions.EDIT_PROFILE_MOBILE_OTP_RESEND_FAILURE,
          errorMessage: "Something went wrong. Please try again later.",
        });
      }
    }
  );
}

export function* editmobileOTPVerifyRequest() {
  yield takeEvery(
    actions.EDIT_PROFILE_MOBILE_OTP_VERIFY_REQUEST,
    function* ({ payload }) {
      try {
        const res = yield call(editmobileOtpVerify, payload);
        if (res) {
          yield put({
            type: actions.EDIT_PROFILE_MOBILE_OTP_VERIFY_SUCCESS,
            data: res,
            status_code: res.status_code,
            message: res.message,
          });
        } else {
          yield put({
            type: actions.EDIT_PROFILE_MOBILE_OTP_VERIFY_FAILURE,
            errorMessage: res.message,
            status_code: res.status_code,
          });
        }
      } catch (error) {
        yield put({
          type: actions.EDIT_PROFILE_MOBILE_OTP_VERIFY_FAILURE,
          errorMessage: "Something went wrong. Please try again later.",
        });
      }
    }
  );
}

export default function* rootSaga() {
  yield all([fork(editmobileOTPSendRequest)]);
  yield all([fork(editmobileOTPResendRequest)]);
  yield all([fork(editmobileOTPVerifyRequest)]);
}
