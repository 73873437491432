import React, { useEffect, useState } from "react";
import { Form, Input, Button, Card, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../Redux/changepassword/actions";
import config from "../../Services/siteConfig";
import { useNavigate } from "react-router-dom";

const Changepassword = () => {
  const [form] = Form.useForm();
  const [newPassword, setNewPassword] = useState("");

  const handlePasswordChange = (value) => {
    form.setFields([{ name: "new_password", errors: [] }]);

    if (newPassword === value && value !=="") {
      form.setFields([
        {
          name: "new_password",
          errors: ["Old password and new password should not be same"],
        },
      ]);
    }
  };
  const handleChange = (value) => {
    setNewPassword(value);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { successMessage, actionType, status_code } = useSelector((state) => {
    return state.changePassword;
  });

  const openNotificationWithIcon = (type, message) => {
    return notification[type]({
      window: window.scroll(0, 0),
      message: message,
      duration: 3,
    });
  };
  useEffect(() => {
    if (actionType === "CHANGE_PASSWORD_SUCCESS" && status_code === 1) {
      openNotificationWithIcon("success", successMessage);
      setNewPassword("")
      form.resetFields();
      dispatch(actions.resetActionType());
      navigate("/");
    } else if (actionType === "CHANGE_PASSWORD_SUCCESS" && status_code === 0) {
      openNotificationWithIcon("success", successMessage);
      dispatch(actions.resetActionType());
    }
  }, [actionType, successMessage, status_code, dispatch, navigate,form]);

  const onFinish = (values) => {
    delete values.new_password;
    console.log("Received values:", values);
    const id = sessionStorage.getItem("id");
    values["id"] = parseInt(id);

    try {
      dispatch(actions.changePasssword(config.changepassword, values));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          maxWidth: "600px",
          width: "100%",
          padding: "10px",
          position: "relative",
        }}
      >
        <Card title="Change Password" style={{ borderRadius: "22px" }}>
          <Card
            style={{
              boxShadow: "0 2px 4px 0 rgba(0,0,0,0.10)",
              borderRadius: "6px",
              border: "1px solid #ddd",
              backgroundColor: "#fff",
              transition: "box-shadow 0.3s",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "-40px",
                left: "50%",
                transform: "translateX(-50%)",
                textAlign: "center",
              }}
            >
              <img
                src="https://media.istockphoto.com/id/1322898677/vector/change-password-icon-password-reset-icon-circular-arrow-lock-reload-concept-update-password.jpg?s=612x612&w=0&k=20&c=Pfdy1jc--1W-Z80jReMTceKEOiRnMWOW3xoWgCNih1E="
                alt="password-icon"
                style={{
                  height: "70px",
                  borderRadius: "50%",
                  transition: "transform 0.3s",
                }}
              />
            </div>
            <Form
              name="change_password_form"
              onFinish={onFinish}
              layout="vertical"
              form={form}
              style={{ marginTop: "20px" }}
            >
              <Form.Item
                label="Current Password"
                name="password"
                rules={[
                  { required: true, message: " Current password required!" },
                ]}
              >
                <Input.Password
                  placeholder="Current Password"
                  maxLength={8}
                  onChange={(e) => {
                    handlePasswordChange(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="New Password"
                name="new_password"
                rules={[
                  {
                    required: true,
                    message: "New password required!",
                  },
                  {
                    validator(_, value) {
                      const passwordRegex =
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!/@#$%^&*()_\-+={}[\]:;"'<>,.?|\\])[A-Za-z\d~`!/@#$%^&*()_\-+={}[\]:;"'<>,.?|\\]+$/;
                      if (value && !passwordRegex.test(value)) {
                        return Promise.reject(
                          new Error(
                            "Password must include uppercase, lowercase, digit, and special character!"
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") !== value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "Old password and new password should not be same"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder="New Password"
                  maxLength={8}
                  onChange={(e) => {
                    handleChange(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                name="newpassword"
                dependencies={["new_password"]}
                rules={[
                  { required: true, message: "Confirm password required!" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("new_password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("The two passwords do not match!")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm Password" maxLength={8} />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: "60%",
                    transition: "background-color 0.3s",
                    marginLeft: "100px",
                    marginTop: "10px",
                  }}
                  hoverable
                >
                  Change Password
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Card>
      </div>
    </div>
  );
};

export default Changepassword;
