export const config = {
  login: "/user/login",
  forgotpassword:"/user/update",
  forgotpasswordotp:"/user/resend",
  forgotpasswordotpveify:"/user/verifyOTP",
  registration:'/user',
  otp:'/user/verifyOTP',
  resendotp:'user/resend',
  changepassword:'/user/changePassword',
  logout:"user/logout",
  adduseraddress:"/tbl-address/address",
  getuseraddress:"/tbl-address/userDtails",
  deleteuseraddress:"/tbl-address/deleteAddress",
  edituseraddress:"/tbl-address/updateAddress",
  upload:"user/upload",
  getstates:"/tbl-states",
  getcity:"/tbl-city/userDtails",
  getuser:"user/getuser",
  update:"user/profileupdate",
  productcategories:"/get/categories",
  getcategory:"get/category",
  addtowishlist:"/wishlist",
  addtocart:'/addtocart',
  getcart:"getcart",
  deleteCartItem:"deletecart",
  updateCartItem:"updatecart",
  getallorderdetails:"tbl-order/userOrder",
  getsingleorderdetails:"tbl-order/orderDetails",
  updateorderdetails:"tbl-order/updateOrder",
  cancelorder:"tbl-order/cancelOrder",
  addreview:"/createreview",
  getreview:"/getreview",
  delete_Review:"/deletereview",
  search:"/get/search",
  create_question:"/question",
  getQues_And_Ans:"/question",
  create_answer:"/answer",
  create_vote:"/createvote",
  search:"/get/search"
};
export default config;
