// sagas.js
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import actions from "./actions";
import AxiosInterceptor from "../../Services/axiosInterceptor";

const getProductlist = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.get(url);
  return result;
};

const menulist = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.get(url);
  return result;
};

const menucat = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.get(url);
  return result;
};

export function* getProductSaga() {
  yield takeEvery("GET_PRODUCT_CATEGORIES", function* ({ payload }) {
    try {
      const res = yield call(getProductlist, payload);
      if (res) {
        yield put({
          type: actions.GET_PRODUCT_CATEGORIES_SUCCESS,
         data:res.data
        });}

    } catch (error) {
      yield put({
        type: actions.GET_PRODUCT_CATEGORIES_ERROR,
        errorMessage: "Something went wrong. Please try after some time.",
      });
    }
  });
}


export function* getmenuItemSaga() {
  yield takeEvery("GET_MENU_NAMES", function* ({ payload }) {
    try {
      const res = yield call(menulist, payload);
      if (res) {
        yield put({
          type: actions.GET_MENU_NAMES_SUCCESS,
         data:res.data
        });}

    } catch (error) {
      yield put({
        type: actions.GET_MENU_NAMES_ERROR,
        errorMessage: "Something went wrong. Please try after some time.",
      });
    }
  });
}


export function* getmenucatSaga() {
  yield takeEvery("GET_MENU_subcategory", function* ({ payload }) {
    try {
      const res = yield call(menucat, payload);
      if (res) {
        yield put({
          type: actions.GET_MENU_subcategory_SUCCESS,
         data:res.data
        });}

    } catch (error) {
      yield put({
        type: actions.GET_MENU_subcategory_ERROR,
        errorMessage: "Something went wrong. Please try after some time.",
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getProductSaga)]);
  yield all([fork(getmenuItemSaga)]);
  yield all([fork(getmenucatSaga)]);
}
 