import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../Components/User/LoginScreen";
import CustomLayout from "../Layout/layout";
import PasswordAssistanceScreen from "../Components/User/forgetpassword";
import PrivateRoute from "../Routes/Privateroute";
import PublicRoute from "../Routes/Publicroute";
import Registration from "../Components/User/registration";
import AccountCreatedMessage from "../Components/User/activateaccount";
import UpdatePassword from "../Components/User/updatepassword";
import MobileScreen from "../Commonmobilescreen/Mobilescreen";
import OTPVerificationScreen from "../Components/User/otpVerification";
import Loader from "../screens/loader";
import UserVerify from "../Components/User/UserVerifyscreen";
import EditProfile from "../Components/Profile/editprofile";
import Useraddress from "../Components/Profile/address/viewuseraddress";
import ProductGrid from "../Components/Home/homescreen";
import Layout1 from "../Layout/layout1";
import Changepassword from "../Components/User/changepassword"
import ProfileOtpVerify from "../Components/Profile/profileotpverify";
import Wishlist from "../Components/Shopping/wishlist";
import CategoreyDetails from "../Commonmobilescreen/categoreydetails";
import AddToCartScreen from "../Components/Shopping/addtocart";
import Orderhistory from "../Components/Shopping/orderhistory";
import SearchScreen from "../Components/Shopping/searchScreen";
import Review from "../Components/ reviewsAndQuestion/review";
import orderconfirmation from "../Components/Placeorder/OrderConfirmation"
import orderReview from "../Components/Placeorder/OrderReview"
import MenuproductDetails from "../Commonmobilescreen/menuproducts"
import Product from "../Components/productpage/Mainproductpage";


const Router = () => {
  return (
    <Routes>
      <Route path="/login" element={<PublicRoute component={Login} />} />
      <Route path="/otpverification" element={<PrivateRoute component={UserVerify} />} />
      <Route path="/otpverify" element={<PublicRoute component={OTPVerificationScreen} />} />
      <Route path="/registration" element={<PublicRoute component={Registration} />} />
      <Route path="/activatemessage" element={<PublicRoute component={AccountCreatedMessage} />} />
      <Route path="/updatepassword" element={<PublicRoute component={UpdatePassword} />} />
      <Route path="/forgetPassword" element={<PublicRoute component={PasswordAssistanceScreen} />} />

      <Route path="/activation" element={<PublicRoute component={Loader} />} />
      
    
      <Route path="/" element={<PrivateRoute component={CustomLayout} />}>
        <Route index element={<ProductGrid />} />
        <Route path="/wishlist" element={<Wishlist />} />
        <Route path="/categorydetails/:id" element={< PrivateRoute component={CategoreyDetails} />} />
        <Route path="/subcategory/:id" element={< PrivateRoute component={MenuproductDetails} />} />
        <Route path="/product/:id" element={<PrivateRoute component={MobileScreen} />} />
        <Route path="/product1/:id" element={<PrivateRoute component={Product} />} />
        <Route path="/reviews/:id" element={<PrivateRoute component={Review} />} />
        <Route path="/addtocart" element={<PrivateRoute component={AddToCartScreen}/>}/>
        <Route path="/orderhistory" element={<PrivateRoute component={Orderhistory}/>}/>
        <Route path="/searchresults" element={<PrivateRoute component={SearchScreen} />} />
        <Route path="/orderreview" element={<PrivateRoute component={orderReview} />} />
        <Route path="/orderconfirmation" element={<PrivateRoute component={orderconfirmation} />} />

       
        <Route path="/layout" element={<PrivateRoute component={Layout1} />}>
        <Route path="wishlist" element={<Wishlist />} />
        <Route index element={<EditProfile />} />
        <Route path="editprofile" element={<EditProfile />} />
        <Route path="product" element={<MobileScreen />} />
        <Route path="address" element={<Useraddress/>} />
        <Route path="changepassword" element={<Changepassword/>} />
        <Route path="verifyotp" element={<UserVerify />} />
        <Route path="profileotpverify" element={<ProfileOtpVerify />} />
        
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default Router;
