import { combineReducers } from "redux";
import auth from "./login/reducer";
import registerRequest from "./register/reducer";
import resendReducer from "./resendotp/reducer";
import forgotPasswordReducer from "./forgetpassword/reducer";
import otpReducer from "./Otp/reducer";
import verifyOTPRequest from "../Redux/LoginOtpverify/reducer";
import changePassword from "../Redux/changepassword/reducer";
import LogoutRequestReducer from "../Redux/Logout/reducer";
import useraddress from "../Redux/address/reducer";
import authemailmobileReducer from "../Redux/MobileEmailverify/reducer";
import userDetailsUpdateReducer from "../Redux/userDetailsupdate/reducer";
import SingleuserDetailsReducer from "../Redux/Singleuserdetails/reducer";
import editprofileupdatemobileReducer from "../Redux/updatemobileverify/reducer";
import editprofileupdateemailReducer from "../Redux/updateemailverify/reducer";
import PRODUCT_CATEGORIESReducer from "./productcategories/reducer";
import SingleproductReducer from "./SingleProduct/reducer";
import wishlistReducer from "./wishlist/reducer"
import userCart from "./addtocart/reducer"
import orderDetailsReducer from "./orderhistory/reducer";
import searchReducer from "./search/reducer"
import productreview from "./reviews/reducer";
import questionsAnswers from "./questionanswer/reducer"

export default combineReducers({
  auth,
  forgotPasswordReducer,
  otpReducer,
  registerRequest,
  resendReducer,
  verifyOTPRequest,
  changePassword,
  LogoutRequestReducer,
  useraddress,
  authemailmobileReducer,
  userDetailsUpdateReducer,
  SingleuserDetailsReducer,
  editprofileupdatemobileReducer,
  editprofileupdateemailReducer,
  PRODUCT_CATEGORIESReducer,
  SingleproductReducer,
  wishlistReducer,
  userCart,
  orderDetailsReducer,
  searchReducer,
  productreview,
  questionsAnswers,
});
