import React, { useState, useEffect } from "react";
import { Form, Input, Button, notification } from "antd";
import { MailOutlined, MobileOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import "../../Styles/forgetpassword.css";
import { Link, useNavigate } from "react-router-dom";
import actions from "../../Redux/forgetpassword/actions";
import config from "../../Services/siteConfig";

const PasswordAssistanceScreen = () => {
  const [form] = Form.useForm();
  const [verificationTarget, setVerificationTarget] = useState("");
  const [showVerification, setShowVerification] = useState(false);
  const [timer, setTimer] = useState(30);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [otp, setOtp] = useState("");
  const [verificationType, setVerificationType] = useState(""); // State for verification type
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { successMessage, actionType, status_code } = useSelector(
    (state) => state.forgotPasswordReducer
  );

  useEffect(() => {
    let interval;
    if (isTimerRunning) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 0) {
            clearInterval(interval);
            setIsTimerRunning(false);
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isTimerRunning]);
  useEffect(() => {
    if (
      actionType === "FORGOT_PASSWORD_OTP_VERIFY_SUCCESS" &&
      status_code === 1
    ) {
      notification.success({
        message: successMessage,
      });
      dispatch(actions.resetActionType());
      navigate("/updatepassword");
    } else if (
      actionType === "FORGOT_PASSWORD_OTP_VERIFY_SUCCESS" &&
      status_code === 0
    ) {
      notification.error({
        message: successMessage,
      });
      dispatch(actions.resetActionType());
    }
  }, [actionType, successMessage, status_code, dispatch, navigate]);

  useEffect(() => {
    if (
      actionType === "FORGOT_PASSWORD_OTP_RESEND_SUCCESS" &&
      status_code === 1
    ) {
      notification.success({
        message: successMessage,
      });
      dispatch(actions.resetActionType());
      setShowVerification(true);
    }
    //  else if (actionType === "FORGOT_PASSWORD_OTP_RESEND_SUCCESS" && status_code===0) {
    //  notification.success({
    //    message:successMessage
    //  })
    //    dispatch(actions.resetActionType());
    //  }
  }, [actionType, successMessage, status_code, dispatch, navigate]);

  useEffect(() => {
    if (actionType === "FORGOT_PASSWORD_OTP_SUCCESS" && status_code === 1) {
      notification.success({
        message: successMessage,
      });
      setShowVerification(true);
      dispatch(actions.resetActionType());
    } else if (
      actionType === "FORGOT_PASSWORD_OTP_ERROR" &&
      status_code === 0
    ) {
      setShowVerification(false);
      setTimeout(() => {
        notification.error({
          message: successMessage,
        });
      }, 200);

      dispatch(actions.resetActionType());
    }
  }, [actionType, successMessage, dispatch, navigate, status_code]);

  const startTimer = () => {
    setTimer(30);
    setIsTimerRunning(true);
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    let type;
    if (values.user_name.includes("@")) {
      if (!validateEmail(values.user_name)) {
        form.setFields([
          {
            name: "user_name",
            errors: ["Please input a valid email address!"],
          },
        ]);
        setIsLoading(false);
        return;
      }
      setVerificationTarget(values.user_name);

      type = "email";
    } else {
      if (!validateMobileNumber(values.user_name)) {
        form.setFields([
          {
            name: "user_name",
            errors: ["Please input a valid 10-digit mobile number!"],
          },
        ]);
        setIsLoading(false);
        return;
      }
      setVerificationTarget(values.user_name);
      localStorage.setItem(
        "ForgotPassword input",
        JSON.stringify(verificationTarget)
      );

      type = "mobile";
    }

    startTimer();
    setVerificationType(type); // Update verificationType state
    localStorage.setItem("ForgotPassword input", values.user_name);

    const payload = {
      user_name: values.user_name,
      verify_type: "forgot",
      type: type,
    };

    console.log("Payload:", payload);
    dispatch(actions.forgotPasswordOTP(config.forgotpasswordotp, payload));

    setIsLoading(false);
  };

  const onFinishOTP = async (values) => {
    setIsLoading(true);
    console.log("Received OTP value: ", values.otp);
    // const payload = {
    //   type: verificationType,
    //   otp: otp,
    //   verify_type: "forgot",
    //   user_name: verificationTarget, // Assuming you want to use the verified username
    // };
    dispatch(
      actions.forgotPassword_OTP_Verify(
        `${config.forgotpasswordotpveify}?type=${verificationType}&user_name=${verificationTarget}&otp=${otp}&verify_type=forgot`
      )
    );

    setIsLoading(false);
  };

  const handleResendOTP = () => {
    const payload = {
      user_name: localStorage.getItem("ForgotPassword input"),
      type: verificationType,
      verify_type: "forgot",
    };
    dispatch(actions.forgotPassword_OTP_Resend(config.resendotp, payload));
    startTimer();
  };

  const handleOtpChange = (e) => {
    const otpValue = e.target.value;
    if (/^\d*$/.test(otpValue)) {
      setError(false); // Clear error state
    } else {
      setError(true); // Set error state
    }
    setOtp(otpValue);
    setTimer(30);
  };

  const validateEmail = (email) => {
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()\[\]\\.,;:\s@"]+\.)+[^<>()\[\]\\.,;:\s@"]{2,})$/i;

    return emailRegex.test(email);
  };

  const validateMobileNumber = (mobileNumber) => {
    const mobileRegex = /^[6789]\d{9}$/;
    return mobileRegex.test(mobileNumber) && mobileNumber.length === 10;
  };

  return (
    <div className="password-assistance-container">
      <div className="password-assistance-content">
        <img
          src="https://cdn-icons-png.freepik.com/256/1745/1745423.png?ga=GA1.1.284748473.1715412480&semt=ais_hybrid"
          alt="Forget Password"
          className="forget-password-image"
        />
        {!showVerification && (
          <div>
            <h2>Password Assistance</h2>
            <div className="instructions">
              <h6>
                <b>
                  Enter the email address or mobile number associated with your
                  account.
                </b>
              </h6>
            </div>
          </div>
        )}
        {!showVerification ? (
          <Form form={form} onFinish={onFinish} layout="vertical">
            <Form.Item
              label={
                <span>
                  <b>Email or Mobile number</b>
                </span>
              }
              name="user_name"
              rules={[
                {
                  required: true,
                  message: "Please enter your email or mobile number!",
                },
                {
                  validator: (_, value) => {
                    if (
                      !value ||
                      validateEmail(value) ||
                      validateMobileNumber(value)
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "Please input a valid email address or 10-digit mobile number!"
                      )
                    );
                  },
                },
              ]}
              validateTrigger="onSubmit"
            >
              <Input
                prefix={<MobileOutlined />}
                placeholder="Email or Mobile number"
                className="input-field"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="submit-button"
                loading={isLoading}
              >
                Continue
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <div>
            <h2>Verification required</h2>
            <p style={{ fontFamily: "sans-serif" }}>
              To continue, complete this verification step. We've sent a{" "}
              {verificationTarget.includes("@")
                ? "verification code to your email"
                : "verification code to your mobile number"}{" "}
              {verificationTarget}. Please enter it below to complete the
              process.
            </p>
            <Form layout="vertical" onFinish={onFinishOTP}>
              <Form.Item
                label={
                  <span>
                    <b>Enter Verification Code</b>
                  </span>
                }
                name="otp"
                validateStatus={error ? "error" : ""}
                help={error ? "Enter only numbers" : ""}
                rules={[
                  {
                    required: true,
                    message: "Please enter the verification code!",
                    len: 6,
                  },
                ]}
              >
                <Input
                  prefix={<MailOutlined />}
                  placeholder="Verification Code"
                  className="input-field"
                  onChange={handleOtpChange}
                  maxLength={6}
                  inputMode="numeric" // Ensures numeric keyboard on mobile devices
                  pattern="[0-9]*"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-button"
                >
                  Continue
                </Button>
              </Form.Item>
              <Form.Item>
                <center>
                  <Button
                    onClick={handleResendOTP}
                    disabled={isTimerRunning || timer > 0}
                  >
                    Resend Verification Code {timer > 0 && `(${timer}s)`}
                  </Button>
                </center>
              </Form.Item>
            </Form>
          </div>
        )}
        <Link to="/login">
          <h4 style={{ textAlign: "center" }}>Sign-In</h4>
        </Link>
      </div>
    </div>
  );
};

export default PasswordAssistanceScreen;
