import actions from "./actions";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  isFetching: false,
  errorMessage: "",
  data:{},
  actionType_review:"",
  successMessage2:""
});

export default function productreview(state = initialState, action) {
  switch (action.type) {
    case actions.CREATE_REVIEW:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.CREATE_REVIEW_SUCCESS:
      return Immutable.merge(state, {
        actionType_review: "CREATE_REVIEW_SUCCESS",
        successMessage2: action.message,
        isFetching: false,
      });
    case actions.CREATE_REVIEW_ERROR:
      return Immutable.merge(state, {
        actionType_review: "CREATE_REVIEW_ERROR",
        errorMessage: action.errorMessage,
      });

    case actions.GET_PRODUCT_REVIEWS:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.GET_PRODUCT_REVIEW_SUCCESS:
      return Immutable.merge(state, {
        data:action.data,
        isFetching: false,
      });
    case actions.GET_PRODUCT_REVIEW_ERROR:
      return Immutable.merge(state, {
        errorMessage: action.errorMessage,
      });

    case actions.EDIT_PRODUCT_REVIEW:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.EDIT_PRODUCT_REVIEW_SUCCESS:
      return Immutable.merge(state, {
        actionType_review: "EDIT_PRODUCT_REVIEW_SUCCESS",
        successMessage: action.message,
      });
    case actions.EDIT_PRODUCT_REVIEW_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType_review: "EDIT_PRODUCT_REVIEW_ERROR",
        errorMessage: action.errorMessage,
      });

    case actions.DELETE_PRODUCT_REVIEW:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.DELETE_PRODUCT_REVIEW_SUCCESS:
      return Immutable.merge(state, {
        actionType_review: "DELETE_PRODUCT_REVIEW_SUCCESS",
        successMessage2: action.message,
      });
    case actions.DELETE_PRODUCT_REVIEW_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType_review: "DELETE_PRODUCT_REVIEW_ERROR",
        errorMessage: action.errorMessage,
      });
      case actions.RESET_ACTIONTYPE:
        return Immutable.merge(state, {
          successMessage2: "",
          errorMessage:"",          
          actionType_review:"",
          data:{},
        });
    default:
      return state;
  }
}