import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import actions from "../../Redux/search/action";
import AxiosInterceptor from "../../Services/axiosInterceptor";

const searchProducts = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.get(url);
  return result;
};

export function* searchProductsRequest() {
  yield takeEvery(actions.SEARCH_PRODUCTS_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(searchProducts, payload);
      if (res) {
        yield put({
          type: actions.SEARCH_PRODUCTS_SUCCESS,
          message: res.message,
          data: res.data,
          statuscode:res.status_code
        });
      } else {
        yield put({
          type: actions.SEARCH_PRODUCTS_ERROR,
          message: res.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.SEARCH_PRODUCTS_ERROR,
        errorMessage: "Something went wrong. Please try after some time.",
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(searchProductsRequest)]);
}
