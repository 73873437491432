import React, { useCallback, useEffect, useState } from "react";
import {
  Input,
  Row,
  Col,
  Dropdown,
  Menu,
  Modal,
  notification,
  Badge,
  Avatar,
  message,
} from "antd";
import {
  HeartOutlined,
  UserOutlined,
  ShoppingCartOutlined,
  LogoutOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../Redux/Logout/actions";
import action from "../../Redux/search/action";
import config from "../../Services/siteConfig";
import "../../Styles/header.css";
import cartactions from "./../../Redux/addtocart/actions";

const { confirm } = Modal;

const Header1 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { successMessage, actionType, status_code } = useSelector(
    (state) => state.LogoutRequestReducer
  );
  const { successMessages, actionTypes, statuscodes } = useSelector(
    (state) => state.searchReducer
  );

  const { menudata } = useSelector((state) => {
    return state.PRODUCT_CATEGORIESReducer;
  });

  const [activeLink, setActiveLink] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (actionType === "LOGOUT_SUCCESS" && status_code === 1) {
      sessionStorage.clear();
      notification.success({
        message: successMessage,
      });
      navigate("/login");
      dispatch(actions.resetActionType());
    } else if (actionType === "LOGOUT_SUCCESS" && status_code === 0) {
      notification.success({
        message: successMessage,
      });
      dispatch(actions.resetActionType());
    }
  }, [actionType, successMessage, status_code, dispatch, navigate]);

  const handleLogout = () => {
    confirm({
      title: "Are you sure you want to logout?",
      onOk() {
        const user_id = sessionStorage.getItem("id");
        dispatch(actions.logout(`${config.logout}?user_id=${user_id}`));
      },
      onCancel() {},
      okText: "Yes",
      cancelText: "No",
    });
  };

  const handleLinkHover = (link) => {
    setActiveLink(link);
  };

  const handleLinkLeave = () => {
    setActiveLink(null);
  };

  const menu = (
    <Menu style={{ width: "200px", padding: "10px" }}>
      <Menu.Item key="1" icon={<UserOutlined />}>
        <Link to="/layout">Edit Profile</Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<LogoutOutlined />} onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );
  const { data } = useSelector((state) => state.userCart);
  const getCartItems = useCallback(() => {
    const user_id = sessionStorage.getItem("id");
    dispatch(cartactions.getCartItem(`${config.getcart}?user_id=${user_id}`));
  }, [dispatch]);

  useEffect(() => {
    getCartItems();
  }, [getCartItems]);

  const handleSearch = () => {
    if (searchTerm.trim() !== "") {
      console.log(searchTerm);
      localStorage.setItem("searchTerm", searchTerm);
      try {
        dispatch(
          action.searchProducts(`${config.search}?search=${searchTerm}`)
        );
      } catch (error) {
        console.error("Error dispatching searchProducts:", error);
      }
    }
  };
  useEffect(() => {
    if (actionTypes === "SEARCH_PRODUCTS_SUCCESS" && statuscodes === 1) {
      navigate(`/searchresults`);
      dispatch(action.resetSearchState());
    } else if (actionTypes === "SEARCH_PRODUCTS_SUCCESS" && statuscodes === 0) {
      message.error(successMessages);
    }
  }, [actionTypes, successMessages, statuscodes]);

  const handleLink = () => {
    localStorage.clear("selectedWishlist");
    setSearchTerm("");
    navigate("/wishlist");
    window.scroll(0, 0);
  };
  const navigateToElectronicsProducts = (id) => {
    navigate(`/subcategory/${id}`);
  };
  const clearsearch = () => {
    setSearchTerm("");
    navigate("/");
  };
  const handleAddToCart = () => {
    setSearchTerm("");
    navigate("/addtocart");
  };

  return (
    <div className="header" style={{ position: "sticky", top: 0, zIndex: 9 }}>
      <Row justify="center" align="middle">
        <Col span={4}>
          <img
            src="https://demo.samyutha.com/wp-content/uploads/2024/01/logo.svg"
            style={{
              width: "100%",
              height: "42px",
              padding: "3px",
              marginTop: "6px",
              marginLeft: "3px",
            }}
            alt="Logo"
            onClick={() => clearsearch()}
          />
        </Col>
        <Col span={13}>
          <Input
            suffix={<SearchOutlined onClick={handleSearch} />}
            allowClear
            className="search-textbox"
            placeholder="Search for products, brand and more"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onPressEnter={handleSearch}
          />
        </Col>
        <Col span={7}>
          <div className="head-container">
            <div
              className="head1mr wishlist"
              onClick={() => handleLink()}
              style={{ cursor: "pointer" }}
            >
              <HeartOutlined style={{ fontSize: "22px" }} />
              <span style={{ fontSize: "16px", marginLeft: "3px" }}>
                Wishlist
              </span>
            </div>
            <div className="head1mr">
              <Dropdown overlay={menu}>
                <span
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <UserOutlined style={{ fontSize: "22px" }} />
                  <span style={{ fontSize: "16px", marginLeft: "3px" }}>
                    Profile
                  </span>
                </span>
              </Dropdown>
            </div>{" "}
            <div className="head1mr cart" onClick={() => handleAddToCart()}>
              <Badge
                count={data?.length}
                overflowCount={10}
                showZero
                style={{ backgroundColor: "gray" }}
              >
                <Avatar shape="square" icon={<ShoppingCartOutlined />} />
              </Badge>
              <span style={{ fontSize: "16px", marginLeft: "10px" }}>Cart</span>
            </div>
          </div>
        </Col>
      </Row>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          marginTop: "12px",
          backgroundColor: "#272727",
          fontSize: "16px",
          height: "45px",
        }}
      >
        {menudata
          .filter((menu) => menu.name !== "Latest")
          .map((menu, index) => (
            <div
              key={index}
              className="head1mr"
              onMouseEnter={() => handleLinkHover(menu.name)}
              onMouseLeave={handleLinkLeave}
            >
              <Link style={{ color: "white" }}>{menu.name}</Link>
              {activeLink === menu.name && (
                <div className="centered-card" style={{margin:"0px",padding:"0px"}}>
                  <Row gutter={16} style={{ padding: "20px" }}>
                    {menu.subcategories?.map((subcategory, subIdx) => (
                      <Col span={6} key={subIdx}>
                        <h3>{subcategory?.name}</h3>
                        <ul>
                          {subcategory?.subcategories?.map(
                            (subitem, subitemIdx) => (
                              <li
                                key={subitem.id}
                                onClick={() =>
                                  navigateToElectronicsProducts(subitem.id)
                                }
                                style={{ cursor: "pointer", color: "blue" }}
                              >
                                {subitem?.name}
                              </li>
                            )
                          )}
                        </ul>
                      </Col>
                    ))}
                  </Row>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Header1;
