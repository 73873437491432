import React, { useState, useRef ,useEffect} from "react";
import { Card, Button, Video, Image ,} from "antd";
import {
  LeftOutlined,
  RightOutlined,
  HeartFilled,
  ShoppingCartOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";

const ImageGallery = ({
  singleProduct,
  id,
  addTocart,
  handleWishlist,
  isWishlisted,
}) => {
  const [mainImage, setMainImage] = useState("");
  const [video, setVideo] = useState(false);
  const [filteredImages, setFilteredImages] = useState([]);
  const containerRef = useRef(null);

  useEffect(() => {
    if (singleProduct?.images) {
      setFilteredImages(singleProduct.images);
      setMainImage(singleProduct.images[0].url);
    }
  }, [singleProduct]);

  const scrollContainer = (amount) => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += amount;
    }
  };

  const handleImageClick = (image) => {
    if (image.type === "video") {
      setMainImage(image.url);
      setVideo(true);
    } else {
      setMainImage(image.url);
      setVideo(false);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        border: "1px solid #ccc",
        borderRadius: "4px",
        position: "relative",
      }}
    >
      <button
        style={{
          position: "absolute",
          top: "50%",
          left: "8px",
          transform: "translateY(-50%)",
          padding: "6px",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          border: "none",
          borderRadius: "50%",
          cursor: "pointer",
        }}
        onClick={() => scrollContainer(-100)}
      >
        <LeftOutlined />
      </button>

      <div
        style={{
          display: "flex",
          flexWrap: "nowrap",
          overflowX: "hidden",
          padding: "8px",
        }}
        ref={containerRef}
      >
        {(filteredImages.length > 0 ? filteredImages : singleProduct?.images) &&
          (filteredImages.length > 0
            ? filteredImages
            : singleProduct?.images
          ).map((image, index) => (
            <div
              key={index}
              style={{
                flex: "0 0 auto",
                marginRight: "8px",
                cursor: "pointer",
              }}
              onMouseEnter={() => handleImageClick(image)}
            >
              {image.type === "video" ? (
                <video
                  src={image.url}
                  style={{
                    width: "80px",
                    height: "64px",
                    borderRadius: "2px",
                    objectFit: "contain",
                    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                  }}
                  autoPlay
                  muted
                  loop
                  controlsList="nodownload"
                />
              ) : (
                <img
                  src={image.url}
                  alt={`Image ${index}`}
                  style={{
                    width: "80px",
                    height: "64px",
                    borderRadius: "2px",
                    objectFit: "contain",
                    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                  }}
                />
              )}
            </div>
          ))}
      </div>

      <button
        style={{
          position: "absolute",
          top: "50%",
          right: "8px",
          transform: "translateY(-50%)",
          padding: "6px",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          border: "none",
          borderRadius: "50%",
          cursor: "pointer",
        }}
        onClick={() => scrollContainer(100)}
      >
        <RightOutlined style={{ color: "black" }} />
      </button>

      <Card
        cover={
          <div style={{ position: "relative" }}>
            {video ? (
              <video
                src={mainImage}
                style={{
                  width: "100%",
                  height: "240px",
                  borderRadius: "4px",
                  objectFit: "contain",
                  padding: "16px",
                }}
                controls
              />
            ) : (
              <img
                src={mainImage}
                alt="Main"
                style={{
                  width: "100%",
                  height: "240px",
                  borderRadius: "4px",
                  objectFit: "contain",
                  padding: "16px",
                }}
              />
            )}
            <div
              style={{
                position: "absolute",
                top: "16px",
                left: "16px",
                cursor: "pointer",
              }}
              onClick={() => handleWishlist(id)}
            >
              {isWishlisted ? (
                <HeartFilled style={{ color: "grey", fontSize: "20px" }} />
              ) : (
                <HeartFilled style={{ color: "grey", fontSize: "20px" }} />
              )}
            </div>
          </div>
        }
      >
        <div
          style={{
            marginTop: 16,
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <Button
            icon={<ShoppingCartOutlined />}
            style={{
              width: 180,
              height: 48,
              backgroundColor: "#FFC300",
              marginRight: 16,
              fontSize: "14px",
            }}
            onClick={addTocart}
          >
            {singleProduct.is_in_cart ? "Go to Cart" : "Add to Cart"}
          </Button>
          <Button
            type="primary"
            icon={<ShoppingOutlined />}
            style={{
              width: 180,
              height: 48,
              fontSize: "14px",
            }}
          >
            Buy Now
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default ImageGallery;
