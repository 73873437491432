// actions.js
const actions = {
  ADD_ADDRESS_REQUEST: "ADD_ADDRESS_REQUEST",
  ADD_ADDRESS_SUCCESS: "ADD_ADDRESS_SUCCESS",
  ADD_ADDRESS_ERROR: "ADD_ADDRESS_ERROR",
  GET_ADDRESS_REQUEST: "GET_ADDRESS_REQUEST",
  GET_ADDRESS_SUCCESS: "GET_ADDRESS_SUCCESS",
  GET_ADDRESS_ERROR: "GET_ADDRESS_ERROR",
  EDIT_ADDRESS_REQUEST: "EDIT_ADDRESS_REQUEST",
  EDIT_ADDRESS_SUCCESS: "EDIT_ADDRESS_SUCCESS",
  EDIT_ADDRESS_ERROR: "EDIT_ADDRESS_ERROR",
  DELETE_ADDRESS_REQUEST: "DELETE_ADDRESS_REQUEST",
  DELETE_ADDRESS_SUCCESS: "DELETE_ADDRESS_SUCCESS",
  DELETE_ADDRESS_ERROR: "DELETE_ADDRESS_ERROR",
  GET_STATE_REQUEST: "GET_STATE_REQUEST",
  GET_STATE_SUCCESS: "GET_STATE_SUCCESS",
  GET_STATE_ERROR: "GET_STATE_ERROR",
  SEND_PINCODE_REQUEST:"SEND_PINCODE_REQUEST",
  SEND_PINCODE_SUCESS:"SEND_PINCODE_SUCESS",
  SEND_PINCODE_ERROR:"SEND_PINCODE_ERROR",
  GET_CITY_REQUEST: "GET_CITY_REQUEST",
  GET_CITY_SUCCESS: "GET_CITY_SUCCESS",
  GET_CITY_ERROR: "GET_CITY_ERROR",
  RESET_ACTIONTYPE: "RESET_ACTIONTYPE",


  adduseraddress: (url, body) => ({
    type: actions.ADD_ADDRESS_REQUEST,
    payload: { url, body },
  }),
  getuseraddress: (url) => ({
    type: actions.GET_ADDRESS_REQUEST,
    payload: { url}
  }),
  deleteuseradress: (url) => ({
    type: actions.DELETE_ADDRESS_REQUEST,
    payload: { url}
  }),
  edituseraddress:(url,body)=>({
    type: actions.EDIT_ADDRESS_REQUEST,
    payload: { url, body },
  }),
  getstates:(url)=>({
    type:actions.GET_STATE_REQUEST,
    payload: { url}
  }),
  sendpincode:(url)=>({
    type:actions.SEND_PINCODE_REQUEST,
    payload: {url}
  }),

  resetActionType: () => {
    return {
      type: actions.RESET_ACTIONTYPE,
    };
  },
};
export default actions;
