import React, { useState, useCallback, useEffect, useMemo } from "react";
import {
  Layout,
  Menu,
  message,
  Spin,
  Popconfirm,
  Button,
  Modal,
  Select,
  Input,
} from "antd";
import { DeleteFilled, MoreOutlined, EditOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../Redux/wishlist/actions";
import cartactions from "../../Redux/addtocart/actions";
import config from "../../Services/siteConfig";
import { Link, useNavigate } from "react-router-dom";

const { Sider, Content } = Layout;
const { Option } = Select;

const Wishlist = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { wishlist, successMessage, status_code, actionType, loading } =
    useSelector((state) => state.wishlistReducer);
  const { actionType1, successMessage1 } = useSelector(
    (state) => state.userCart
  );

  const getUserId = sessionStorage.getItem("id");

  const getwishlist = useCallback(() => {
    try {
      dispatch(
        actions.getWishlist(`${config.addtowishlist}?user_id=${getUserId}`)
      );
    } catch (error) {
      console.log(error);
    }
  }, [getUserId, dispatch]);

  useEffect(() => {
    getwishlist();
  }, [getUserId, getwishlist]);
  useEffect(() => {
    if (actionType === "UPDATE_WISHLIST_SUCCESS" && status_code === 1) {
      message.success(successMessage);
      getwishlist();
    } else if (actionType === "UPDATE_WISHLIST_SUCCESS" && status_code === 0) {
      message.error(successMessage);
    }
  }, [actionType, status_code, successMessage]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedWishlistEdit, setSelectedWishlistEdit] = useState(null);
  const [newWishlistName, setNewWishlistName] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null); // State to hold selected product for editing
  const [selectedWishlistGroupId, setSelectedWishlistGroupId] = useState(null);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedWishlistEdit(null);
    setNewWishlistName("");
    setSelectedProduct(null);
    setSelectedWishlistGroupId(null);
  };

  const handleOk = () => {
    const existingWishlist = wishlistNames.find(
      (name) => name === newWishlistName
    );
    if (existingWishlist) {
      const existingWishlistItem = wishlist.data.find(
        (item) => item.wishlist_name === existingWishlist
      );
      if (existingWishlistItem) {
        const payload = {
          product_id: selectedProduct.product_id,
          group_id: existingWishlistItem.group_id,
          user_id: getUserId,
          wishlist_name: newWishlistName,
        };
        console.log(payload, "wishlist present in dropdown");
        dispatch(actions.updateWishlist(config.addtowishlist, payload));
      }
    }

    setIsModalVisible(false);
    setSelectedWishlistEdit(null);
    setNewWishlistName("");
    setSelectedProduct(null);
    setSelectedWishlistGroupId(null);
  };

  const handleRemoveItem = (product_id) => {
    try {
      dispatch(
        actions.deleteFromWishlist(`${config.addtowishlist}?user_id=${getUserId}&product_id=${product_id}`));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (actionType === "DELETE_FROM_WISHLIST_SUCCESS" && status_code === 1) {
      message.success(successMessage);
      getwishlist();
    }
    dispatch(actions.resetActionType());
  }, [actionType, status_code, successMessage, dispatch, getwishlist]);

  const handleAddToCart = (item) => {
    const payload = {
      product_id: item.product_id,
      user_id: getUserId,
      quantity: 1,
    };
    dispatch(cartactions.addToCart(config.addtocart, payload));
  };

  useEffect(() => {
    if (actionType1 === "ADD_TO_CART_SUCCESS") {
      message.success(successMessage1);
      getCartItems();
    }
    dispatch(cartactions.resetActionType());
  }, [actionType1, successMessage1]);

  const navigateToProductPage = (item) => {
    navigate(`/product/${item.product_id}`);
    window.scroll(0, 0);
  };

  const [selectedWishlist, setSelectedWishlist] = useState(null);
  // const [wishlistNames, setWishlistNames] = useState([]);

  const wishlistNames = useMemo(() => {
    if (wishlist && wishlist.data) {
      const sortedWishlistData = [...wishlist.data].sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      const uniqueWishlistNames = [
        ...new Set(sortedWishlistData.map((item) => item.wishlist_name)),
      ];
      return uniqueWishlistNames;
    }
    return [];
  }, [wishlist]);

  useEffect(() => {
    if (wishlistNames.length === 0) {
      setSelectedWishlist(null);
    } else if (!wishlistNames.includes(selectedWishlist)) {
      setSelectedWishlist(wishlistNames[0]);
    }
  }, [wishlistNames, selectedWishlist]);

  const handleMenuClick = (wishlistName) => {
    setSelectedWishlist(wishlistName);
    localStorage.setItem("selectedWishlist", wishlistName);
  };

  const getCartItems = useCallback(() => {
    dispatch(cartactions.getCartItem(`${config.getcart}?user_id=${getUserId}`));
  }, [dispatch, getUserId]);

  useEffect(() => {
    getCartItems();
  }, [getCartItems]);
  useEffect(() => {
    if (actionType === "ADD_TO_WISHLIST_SUCCESS" && status_code === 1) {
      message.success(successMessage);
      dispatch(actions.resetActionType());
    } else if (actionType === "ADD_TO_WISHLIST_SUCCESS" && status_code === 0) {
      message.error(successMessage);
      dispatch(actions.resetActionType());
    }
  }, [actionType, status_code, successMessage]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editWishlistName, setEditWishlistName] = useState("");
  const [originalWishlistName, setOriginalWishlistName] = useState("");

  useEffect(() => {
    console.log(editWishlistName);
  }, [editWishlistName]);
  const handleEditOk = () => {
    localStorage.setItem("edit", editWishlistName);
    const selectedWishlistItem = wishlist.data.find(
      (item) => item.wishlist_name === originalWishlistName
    );
    const isDuplicateName = wishlist.data.some(
      (item) =>
        item.wishlist_name === editWishlistName &&
        item.wishlist_name !== originalWishlistName
    );

   if (isDuplicateName) {
    message.error("Wishlist name Alredy exists.");
    } else {
      if (selectedWishlistItem) {
        const payload = {
          wishlist_name: editWishlistName,
          user_id: getUserId,
          group_id: selectedWishlistItem.group_id,
        };
        console.log(payload);
        dispatch(actions.updateWishlist(config.addtowishlist, payload));
      } else {
        console.error(`
          Wishlist with name '${originalWishlistName}' not found.`
        );
      }
    }
    setShowEditModal(false);
    setEditWishlistName("");
    setOriginalWishlistName("");
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider width={230} style={{ background: "#fff", padding: "0px" }}>
        <Menu
          mode="inline"
          selectedKeys={[selectedWishlist]}
          style={{
            height: "100%",
            borderRight: 0,
            backgroundColor: "#f9f9f9",
            padding: "0px",
          }}
        >
          {wishlistNames
            .sort((a, b) => a.localeCompare(b))
            .map((wishlistName, index) => (
              <Menu.Item
                key={wishlist.id}
                onClick={() => handleMenuClick(wishlistName)}
                style={{
                  backgroundColor:
                    selectedWishlist === wishlistName ? "#bcbef7" : "",
                }}
              >
                {wishlistName}
                <EditOutlined
                  style={{ marginLeft: 8, cursor: "pointer" }}
                  onClick={() => {
                    setShowEditModal(true);
                    setEditWishlistName(wishlistName);
                    setOriginalWishlistName(wishlistName);
                  }}
                />
              </Menu.Item>
            ))}
        </Menu>
      </Sider>
      <Layout>
        <Content
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
            backgroundColor: "#f9f9f9",
            border: "1px solid #ccc",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          {loading ? (
            <div style={{ textAlign: "center" }}>
              <Spin size="large" />
            </div>
          ) : (
            <>
              {selectedWishlist && (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    {[...wishlist.data]
                      .filter((item) => item.wishlist_name === selectedWishlist)
                      .sort(
                        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
                      )
                      .map((item) => (
                        <div
                          key={item.product_id}
                          style={{
                            width: "250px",
                            backgroundColor: "#fff",
                            border: "1px solid #ccc",
                            padding: "8px",
                            borderRadius: "5px",
                            margin: "8px",
                            boxShadow: "0 0 3px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          <div
                            style={{
                              marginBottom: "12px",
                              height: "200px",
                              overflow: "hidden",
                              position: "relative",
                            }}
                          >
                            <img
                              src={item.images[0].url}
                              alt={item.name}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                                borderRadius: "8px",
                                cursor: "pointer",
                              }}
                              onClick={() => navigateToProductPage(item)}
                            />
                            <span
                              style={{
                                position: "absolute",
                                top: "8px",
                                left: "8px", // Adjusted to top-left corner
                                backgroundColor: "#fff",
                                padding: "5px",
                                borderRadius: "50%",
                                cursor: "pointer",
                                boxShadow: "0 0 3px rgba(0, 0, 0, 0.3)",
                                zIndex: "1", // Ensure it's above the image
                              }}
                            >
                              <MoreOutlined
                                style={{ color: "gray", fontSize: "20px" }}
                                onClick={() => {
                                  showModal();
                                  setSelectedProduct(item); // Set selected product for editing
                                  setSelectedWishlistEdit(item.wishlist_name);
                                  setNewWishlistName(item.wishlist_name);
                                  setSelectedWishlistGroupId(item.group_id);
                                }}
                              />
                            </span>
                            <span
                              style={{
                                position: "absolute",
                                top: "8px",
                                right: "8px",
                                backgroundColor: "#fff",
                                padding: "5px",
                                borderRadius: "50%",
                                cursor: "pointer",
                                boxShadow: "0 0 3px rgba(0, 0, 0, 0.3)",
                              }}
                            >
                              <Popconfirm
                                title="Are you sure you want to delete this product?"
                                onConfirm={() =>
                                  handleRemoveItem(item.product_id)
                                }
                                okText="Yes"
                                cancelText="No"
                                placement="bottomRight" // Adjusted to bottomRight
                              >
                                <div>
                                  <DeleteFilled
                                    style={{ color: "gray", fontSize: "20px" }}
                                  />
                                </div>
                              </Popconfirm>
                            </span>
                          </div>

                          <div style={{ textAlign: "left" }}>
                            <h3
                              style={{
                                fontSize: "12px",
                                display: "inline",
                              }}
                            >
                              {item.name}
                            </h3>
                            <p
                              style={{
                                fontSize: "12px",
                                marginBottom: "3px",
                              }}
                            >
                              ₹{item.price}
                              {item.discountPercentage && (
                                <span
                                  style={{
                                    marginLeft: "8px",
                                    fontSize: "10px",
                                    color: "#888",
                                  }}
                                >
                                  {item.discountPercentage}% off
                                </span>
                              )}
                            </p>
                            {item.rating && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  fontSize: "10px",
                                  color: "#888",
                                }}
                              >
                                <span style={{ color: "#f39c12" }}>
                                  {[...Array(Math.floor(item.rating))].map(
                                    (_, i) => (
                                      <i key={i} className="fa fa-star" />
                                    )
                                  )}
                                </span>
                              </div>
                            )}
                            <button
                              style={{
                                backgroundColor: "#1890ff",
                                color: "#fff",
                                padding: "6px 12px",
                                border: "none",
                                borderRadius: "3px",
                                cursor: "pointer",
                                fontSize: "12px",
                                marginTop: "8px",
                                width: "100%",
                                boxSizing: "border-box",
                              }}
                              onClick={() => {
                                handleAddToCart(item);
                              }}
                            >
                              MOVE TO CART
                            </button>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}
              {!selectedWishlist && (
                <div
                  style={{
                    textAlign: "center",
                    padding: "20px",
                    height: "400px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h3 style={{ marginBottom: "20px", color: "#333" }}>
                    YOUR WISHLIST IS EMPTY
                  </h3>
                  <img
                    src="https://media.licdn.com/dms/image/C5612AQEWZ-yzAbS9ew/article-cover_image-shrink_600_2000/0/1520184677389?e=2147483647&v=beta&t=FMqojravE6Nfz6rR37lbyhDmKOHdodOY6P41pEoApEI"
                    alt="Empty Wishlist"
                    style={{
                      width: "200px",
                      height: "200px",
                      borderRadius: "50%",
                      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  />
                  <p>
                    Add items that you like to your wishlist. Review them
                    anytime and easily move them to the bag.
                  </p>
                  <Link to="/">
                    {" "}
                    <button
                      style={{
                        backgroundColor: "#1890ff",
                        color: "#fff",
                        padding: "10px 20px",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        fontSize: "10px",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      CONTINUE SHOPPING
                    </button>
                  </Link>
                </div>
              )}
            </>
          )}
        </Content>
      </Layout>
      {/* Modal for editing wishlist */}
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={300}
      >
        <p>Select wishlist to move:</p>
        <Select
          style={{ width: "100%", marginBottom: "12px" }}
          value={selectedWishlistEdit}
          onChange={(value) => {
            setSelectedWishlistEdit(value);
            setNewWishlistName(value); // Set the selected value to newWishlistName
          }}
        >
          {wishlistNames.map((wishlistName, index) => (
            <Option key={index} value={wishlistName}>
              {wishlistName}
            </Option>
          ))}
        </Select>
      </Modal>
      <Modal
        visible={showEditModal}
        onOk={handleEditOk}
        onCancel={() => setShowEditModal(false)}
        width={300}
      >
        <p>Edit Wishlist Name:</p>
        <Input
          value={editWishlistName}
          onChange={(e) => setEditWishlistName(e.target.value)}
          style={{ width: "100%", marginBottom: 12 }}
          maxLength={20}
        />
      </Modal>
    </Layout>
  );
};

export default Wishlist;