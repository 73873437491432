const actions = {
  LOGIN_OTP_VERIFY_REQUEST: "LOGIN_OTP_VERIFY_REQUEST",
  LOGIN_OTP_VERIFY_SUCCESS: "LOGIN_OTP_VERIFY_SUCCESS",
  LOGIN_OTP_VERIFY_ERROR: "LOGIN_OTP_VERIFY_ERROR",


  RESEND_LOGIN_OTP_REQUEST: "RESEND_LOGIN_OTP_REQUEST",
  RESEND_LOGIN_OTP_SUCCESS: "RESEND_LOGIN_OTP_SUCCESS",
  RESEND_LOGIN_OTP_ERROR: "RESEND_LOGIN_OTP_ERROR",

  RESET_ACTIONTYPE: "RESET_ACTIONTYPE",

  loginOTPVerify: (url) => ({
    type: actions.LOGIN_OTP_VERIFY_REQUEST,
    payload: { url },
  }),

  Resend_LoginOTP: (url,body) => ({
    type: actions.RESEND_LOGIN_OTP_REQUEST,
    payload: { url ,body},
  }),


  resetActionType: () => {
    return {
      type: actions.RESET_ACTIONTYPE,
    };
  },
};

export default actions;
