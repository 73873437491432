import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Card,
  Divider,
  Checkbox,
  Row,
  Col,
  notification,
} from "antd";
import {
  UserOutlined,
  LockOutlined,
  MailOutlined,
  GoogleOutlined,
  FacebookOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";
import "../../Styles/registration.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../Redux/register/actions";
import config from "../../Services/siteConfig";

const Registration = () => {
  const navigate = useNavigate();
  const [autoGeneratePassword, setAutoGeneratePassword] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const handleEmailChange = () => {
    form.setFields([
      { name: 'email', errors: [] },
      { name: 'mobilenumber', errors: [] } // Clear mobile number error too
    ]);
  };
  
  const handleMobileChange = () => {
    form.setFields([
      { name: 'mobilenumber', errors: [] },
      { name: 'email', errors: [] } // Clear email error too
    ]);
  };

  const { registrationdata, actionType, successMessage } = useSelector(
    (state) => {
      return state.registerRequest;
    }
  );

  const onFinish = (values) => {
    console.log(values);
    try {
      dispatch(actions.register(config.registration, values));
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (
      actionType === "REGISTER_SUCCESS" &&
      registrationdata.is_verified === false
    ) {
      localStorage.setItem("id", JSON.stringify(registrationdata.userData.id));
      notification.success({
        message: successMessage,
      });
      dispatch(actions.resetActionType());
      navigate("/otpverify");
    } else if (
      actionType === "REGISTER_SUCCESS" &&
      registrationdata.is_verified === true
    ) {
      notification.success({
        message: "user already exists",
      });
      dispatch(actions.resetActionType());
      navigate("/login");
    }
  }, [actionType, navigate, registrationdata, dispatch, successMessage]);
  const generatePassword = () => {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";
    let password = "";

    // Generate a random password
    for (let i = 0; i < 4; i++) {
      // const location = useLocation();
      // const email = location.state?.inputValue || "";
      // Generate 6 characters initially
      password += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    const uppercaseChar = chars.charAt(Math.floor(Math.random() * 26));
    password += uppercaseChar;

    const lowercaseChar = chars.charAt(Math.floor(Math.random() * 26) + 26);
    password += lowercaseChar;
    const digitChar = chars.charAt(Math.floor(Math.random() * 10) + 52);
    password += digitChar;
    const specialChar = chars.charAt(Math.floor(Math.random() * 9) + 62);
    password += specialChar;
    password = password
      .split("")
      .sort(() => Math.random() - 0.5)
      .join("");

    return password;
  };

  const handleAutoGeneratePassword = (e) => {
    setAutoGeneratePassword(e.target.checked);
    if (e.target.checked) {
      const generatedPwd = generatePassword(); // Generate password
      form.setFieldsValue({ password: generatedPwd }); // Update password field value
    } else {
      form.setFieldsValue({ password: "" }); // Clear password field
    }
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      className="img"
    >
      <Card title="Create Account" style={{ width: "500px" }}>
        <Form
          form={form}
          name="registration_form"
          onFinish={onFinish}
          layout="vertical"
        >
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="First Name"
                name="firstname"
                rules={[
                  { required: true, message: "First name required!" },
                  {
                    pattern: /^(?!\s)[a-zA-Z\s]{1,30}$/,
                    message: "Please enter a valid name!",
                  },
                ]}
                validateTrigger={[ "onsubmit"]}
              >
                <Input prefix={<UserOutlined />} placeholder="First Name" onChange={() => {
                form.setFields([{ name: 'firstname', errors: [] }]);
              }}/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Last Name"
                name="lastname"
                rules={[
                  {
                    pattern: /^(?!\s)[a-zA-Z\s]{1,30}$/,
                    message: "Please enter a valid name!",
                  },
                ]}
                validateTrigger={[ "onsubmit"]}
              >
                <Input prefix={<UserOutlined />} placeholder="Last Name " onChange={() => {
                form.setFields([{ name: 'lastname', errors: [] }]);
              }}/>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const mobileFieldValue = getFieldValue("mobilenumber");
                  if (!value && !mobileFieldValue) {
                    return Promise.reject(new Error("Email required!"));
                  }
                  if (
                    value &&
                    !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
                      value
                    )
                  ) {
                    return Promise.reject(
                      new Error("Please enter a valid email address!")
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            validateTrigger={[ "onsubmit"]}
          >
            <Input prefix={<MailOutlined />} placeholder="Email" onChange={handleEmailChange}/>
          </Form.Item>

          <Form.Item
            label="Mobile Number"
            name="mobilenumber"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const emailFieldValue = getFieldValue("email");
                  if (!value && !emailFieldValue) {
                    return Promise.reject(new Error("Mobile number required!"));
                  }
                  if (
                    value &&
                    (!/^[6-9][0-9]*$/.test(value) || value.length !== 10)
                  ) {
                    return Promise.reject(
                      new Error("Please enter a valid mobile number!")
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            validateTrigger={[ "onsubmit"]}
          >
            <Input prefix="+91" maxLength={10} placeholder="Mobile Number"  onChange={handleMobileChange} />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Password required!",
              },

              ({ getFieldValue }) => ({
                validator(_, value) {
                  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!/@#$%^&*()_\-+={}[\]:;"'<>,.?|\\])[A-Za-z\d~`!/@#$%^&*()_\-+={}[\]:;"'<>,.?|\\]+$/;
                  if (value && !passwordRegex.test(value)) {
                    return Promise.reject(
                      new Error(
                        "Password must include uppercase, lowercase, digit, and special character!"
                      )
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            validateTrigger={[ "onsubmit"]}
          >
            <Input.Password
              minLength={8}
              maxLength={8}
              prefix={<LockOutlined />}
              placeholder="Password"
              onChange={() => {
                setAutoGeneratePassword(false)
                form.setFields([{ name: 'password', errors: [] }]);
              }}
            />
          </Form.Item>
          <Form.Item>
            <Checkbox
              checked={autoGeneratePassword}
              onChange={handleAutoGeneratePassword}
            >
              Auto Generate Password
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
              Create Account
            </Button>
          </Form.Item>
        </Form>
        <Divider>Or continue with</Divider>
        <div className="social-buttons">
          <Button
            icon={<GoogleOutlined style={{ fontSize: "1.5em" }} />}
            className="social-button"
            style={{
              background: "#db4437",
              color: "white",
              marginRight: "5px",
            }}
          />
          <Button
            icon={<FacebookOutlined style={{ fontSize: "1.5em" }} />}
            className="social-button"
            style={{
              background: "#4267B2",
              color: "white",
              margin: "0 5px",
            }}
          />
          <Button
            icon={<LinkedinOutlined style={{ fontSize: "1.5em" }} />}
            className="social-button"
            style={{
              background: "#0a66c2",
              color: "white",
              marginLeft: "5px",
            }}
          />
        </div>
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <span>Already have an account? </span>
          <a href="/login">Sign in</a>
        </div>
      </Card>
    </div>
  );
};

export default Registration;
