import { takeEvery, put, call, all, fork } from "redux-saga/effects";
import actions from "../userDetailsupdate/actions";
import AxiosInterceptor from "../../Services/axiosInterceptor"; // Import AxiosInterceptor

const updateUserDetails = async (payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.put(url, body);
  return result;
};



export function* userDetailsUpdateRequest() {
  yield takeEvery(actions.USER_DETAILS_UPDATE_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(updateUserDetails, payload);

      if (res) {
        yield put({
          type: actions.USER_DETAILS_UPDATE_SUCCESS,
          successMessage: res.message,
          status_code: res.status_code,
        });
      } else {
        yield put({
          type: actions.USER_DETAILS_UPDATE_FAILURE,
          errorMessage: "Failed to update user details",
        });
      }
    } catch (error) {
      yield put({
        type: actions.USER_DETAILS_UPDATE_FAILURE,
        errorMessage: "Failed to update user details",
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(userDetailsUpdateRequest)]);
 
}
