// reducer.js
import actions from "./actions";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  isFetching: false,
  errorMessage: "",
  actionType: "",
  data: [],
  menudata:[],
  menucatdata:{}
});

export default function PRODUCT_CATEGORIESReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case actions.GET_PRODUCT_CATEGORIES:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.GET_PRODUCT_CATEGORIES_SUCCESS:
      return Immutable.merge(state, {
        actionType: "GET_PRODUCT_CATEGORIES_SUCCESS",
        data: action.data,
      });
    case actions.GET_PRODUCT_CATEGORIES_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: "GET_PRODUCT_CATEGORIES_ERROR",
        errorMessage: action.errorMessage,
      });

    case actions.GET_MENU_NAMES:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.GET_MENU_NAMES_SUCCESS:
      return Immutable.merge(state, {
        actionType: "GET_MENU_NAMES_SUCCESS",
        menudata: action.data,
      });
    case actions.GET_MENU_NAMES_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: "GET_MENU_NAMES_ERROR",
        errorMessage: action.errorMessage,
      });






      case actions.GET_MENU_subcategory:
        return Immutable.merge(state, {
          isFetching: true,
        });
      case actions.GET_MENU_subcategory_SUCCESS:
        return Immutable.merge(state, {
          actionType: "GET_MENU_subcategory_SUCCESS",
          menucatdata:action.data
        });
      case actions.GET_MENU_subcategory_ERROR:
        return Immutable.merge(state, {
          isFetching: false,
          actionType: "GET_MENU_subcategory_ERROR",
          errorMessage: action.errorMessage,
        });















































    case actions.RESET_ACTIONTYPE:
      return Immutable.merge(state, {
        actionType: "",
        errorMessage: "",
      });
    default:
      return state;
  }
}
