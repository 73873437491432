const actions = {
    RESEND_OTP: "RESEND_OTP",
    RESEND_OTP_SUCCESS: "RESEND_OTP_SUCCESS",
    RESEND_OTP_ERROR: "RESEND_OTP_ERROR",
    RESET_ACTIONTYPE:"RESET_ACTIONTYPE",
    resend: (url, body) => ({
        type: actions.RESEND_OTP,
        payload: { url, body }
    }),
    resetActionType: () => {
        return {
          type: actions.RESET_ACTIONTYPE,
        };
    }
}
export default actions;