import {
  Button,
  Modal,
  Checkbox,
  Card,
  Space,
  message,
  Popconfirm,
  Badge
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import config from "../../Services/siteConfig";
import { useDispatch, useSelector } from "react-redux";
import cartactions from "./../../Redux/addtocart/actions";
import { DeleteOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";

const AddToCartScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, actionType, successMessage } = useSelector(
    (state) => state.userCart
  );

  useEffect(() => {
    if (actionType === "DELETE_CART_SUCCESS") {
      message.success(successMessage);

      getCartItems();
      dispatch(cartactions.resetActionType());
    } else if (actionType === "EDIT_CART_SUCCESS") {
      getCartItems();
      dispatch(cartactions.resetActionType());
    }
  }, [actionType, successMessage]);

  const calculateTotal = () => {

    let total = 0;
    data?.map((product) => {
      total += product?.price * product?.quantity;
    });
    
    return total;
  };

  const calculateDiscount = () => {
    // Placeholder for discount logic
    return 0;
  };

  const calculateTax = () => {
    // Placeholder for tax calculation
    return 0;
  };

  const calculateShipping = () => {
    // Placeholder for shipping calculation
    return 0;
  };



  const getCartItems = useCallback(() => {
    const user_id = sessionStorage.getItem("id");
    dispatch(cartactions.getCartItem(`${config.getcart}?user_id=${user_id}`));
  }, [dispatch]);

  useEffect(() => {
    getCartItems();
  }, [getCartItems]);

  const handleDeleteCartItem = (productId) => {
    dispatch(
      cartactions.deleteCartItem(
        `${config.deleteCartItem}?user_id=${sessionStorage.getItem(
          "id"
        )}&product_id=${productId}`
      )
    );
    getCartItems();
  };
  const updateCartItem = (product, newQuantity) => {
    const payload = {
      product_id: product?.product_id,
      quantity: newQuantity,
      user_id: sessionStorage.getItem("id"),
    };
    dispatch(cartactions.editCartItem(config.updateCartItem, payload));
  };
  const confirm = (product_id) => {
    handleDeleteCartItem(product_id);
  };
  const cancel = (e) => {
    console.log(e);
  };
  const navigateToProductPage = (productId) => {
    navigate(`/product/${productId}`);
    window.scroll(0, 0);
  };

  return (
    <div>
      {data?.length > 0 ? (
        <div
          style={{
            width: "90%",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
          }}
        >
          <main style={{ padding: "20px", display: "flex" }}>
            <div style={{ flex: 2 }}>
              <Card style={{ padding: "2px" }} title="Shopping Cart">
                
            
              <div style={{ display: "flex", flexDirection: "column" }}>
                {[...data]
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                  .map((product) => (
                    <div
                      key={product?.product_id}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "15px",
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      <img
                        src={product?.attributes[0]?.url?.url}// Assuming images is an array of objects with a 'url' property
                        alt={product?.name}
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "contain",
                          marginRight: "20px",
                        }}
                        onClick={() =>
                          navigateToProductPage(product?.product_id)
                        }
                      />
                     

                      <div style={{ flex: 1 }}>
                      <h3>{product?.name} ({product?.attributes[0]?.Ram+"GB Ram"}), ({product?.attributes[0]?.Storage+"GB"})</h3>
                        
                        <p>{product?.description}</p>
                        <div style={{ display: "flex" }}>
                          <span>{" "}
                    {new Intl.NumberFormat("en-IN", {
                      maximumFractionDigits: 0,
                      style: "currency",
                      currency: "INR",
                    }).format(product?.price)}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "13px",
                          }}
                        >
                          <button
                            style={{
                              border: "none",
                              padding: "5px 10px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (product.quantity > 1) {
                                updateCartItem(product, product.quantity - 1);
                              } 
                            }}
                          >
                            -
                          </button>
                          <span style={{ margin: "0 10px" }}>
                            {product?.quantity}
                          </span>
                          <button
                            style={{
                              border: "none",
                              padding: "5px 10px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              updateCartItem(product, product.quantity + 1)
                            }
                          >
                            +
                          </button>
                        </div>
                        <p style={{ marginTop: "20px" }}>
                          Estimated Delivery by 1 September, 2024
                        </p>
                      </div>

                      <Space>
                        <Popconfirm
                          title="Are you sure you want to delete this item?"
                          onConfirm={() => confirm(product.product_id)}
                          onCancel={cancel}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link" icon={<DeleteOutlined />} />
                        </Popconfirm>
                      </Space>
                    </div>
                  ))}
              </div>
              </Card>
            </div>

            {/* Order Summary */}
            <div style={{ flex: 1 }}>
              <div
                style={{
                  padding: "20px",
                  border: "1px solid #ddd",
                  marginBottom: "20px",
                }}
              >
                <h3>Product Details</h3>
                <div style={{ marginBottom: "10px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px 0",
                    }}
                  >
                    <span>Sub Total</span>
                    <span>{new Intl.NumberFormat("en-IN", {
            maximumFractionDigits: 0,
            style: "currency",
            currency: "INR",
          }).format(calculateTotal())}</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px 0",
                    }}
                  >
                    <span>Discount</span>
                    <span>₹{calculateDiscount().toFixed(2)}</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px 0",
                    }}
                  >
                    <span>Tax</span>
                    <span>₹{calculateTax().toFixed(2)}</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px 0",
                    }}
                  >
                    <span>Secured Packaging Fee</span>
                    <span>₹{calculateShipping().toFixed(2)}</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px 0",
                      borderTop: "1px solid #ddd",
                      marginTop: "10px",
                    }}
                  >
                    <span>Total</span>
                    <span>
                    {new Intl.NumberFormat("en-IN", {
            maximumFractionDigits: 0,
            style: "currency",
            currency: "INR",
          }).format(calculateTotal() + calculateTax() - calculateDiscount())}
                    </span>
                  </div>
                </div>
                <button
                  style={{
                    backgroundColor: "#007bff",
                    color: "#fff",
                    padding: "10px 20px",
                    border: "none",
                    cursor: "pointer",
                    marginTop: "20px",
                    width: "100%",
                  }}
                >
                  Proceed to Buy
                </button>
              </div>
            </div>
          </main>
        </div>
      ) : (
        <div
          style={{
            textAlign: "center",
            padding: "20px",
            height: "200px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <h3 style={{ marginBottom: "20px", color: "#333" }}>
            YOUR CART IS EMPTY
          </h3>
          <img
            src="https://media.licdn.com/dms/image/C5612AQEWZ-yzAbS9ew/article-cover_image-shrink_600_2000/0/1520184677389?e=2147483647&v=beta&t=FMqojravE6Nfz6rR37lbyhDmKOHdodOY6P41pEoApEI"
            alt="Empty Wishlist"
            style={{
              width: "180px",
              height: "180px",
              borderRadius: "50%",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            }}
          />
          <p>Add items that you like to your cart.</p>
          <Link to="/">
            {" "}
            <button
              style={{
                backgroundColor: "#1890ff",
                color: "#fff",
                padding: "10px 20px",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                fontSize: "10px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              CONTINUE SHOPPING
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default AddToCartScreen;
