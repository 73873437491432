import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Button,
  Card,
  Rate,
  Table,
  Row,
  Col,
  Flex,
  Progress,
  Select,
  message,
  Modal,
  Input,
  Form,
  Divider,
  Popconfirm,
  Space,
  Avatar,
  List,
  Pagination,
  Tooltip,
  Badge,
  Tag,
  Upload,
  notification,
  Spin
} from "antd";
import "./product.css";
import VirtualList from "rc-virtual-list";
import {
  UserOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  HeartOutlined,
  RightOutlined,
  LeftOutlined,
  HeartFilled,
  LikeOutlined,
  DislikeOutlined,
  DeleteOutlined,
  CodepenOutlined,
  UploadOutlined, 
  StarFilled 
} from "@ant-design/icons";
import actions from "../Redux/SingleProduct/actions";
import action from "../Redux/wishlist/actions";
import config from "../Services/siteConfig";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import cartactions from "../Redux/addtocart/actions";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import reviewactions from "../Redux/reviews/actions";
import questionactions from "../Redux/questionanswer/actions";
import AxiosInterceptor from "../Services/axiosInterceptor";
const { TextArea } = Input;
const { Column } = Table;
const { Search } = Input;

const ProductDetails = ({}) => {
  const [showTable, setShowTable] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [mainImage, setMainImage] = useState("");
  const [isWishlisted, setIsWishlisted] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(""); // State to hold selected product ID
  const [customWishlistName, setCustomWishlistName] = useState("");
  const [video, setVideo] = useState();
  const [selectedWishlist, setSelectedWishlist] = useState("");
  const [modal2Open, setModal2Open] = useState(false);
  const [answerpopup, setAnswerpopup] = useState(false);
  const [reviewpopup, setReviewpopup] = useState(false);
  const [question_id, setQuestion_id] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  const [filteredImages, setFilteredImages] = useState([]);
  const [selecteddata, setselecteddata] = useState();
  const [SelectedRAM, setSelectedRAM] = useState();
  const [SelectedROM, setSelectedROM] = useState();
  const [filteredPrice, setFilteredPrice] = useState(null);
  const [productquantity, setProductquantity] = useState(null);
  const containerRef = useRef(null);
  const { Option } = Select;
  const { id } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [questionform] = Form.useForm();
  const [answerform] = Form.useForm();
  const [reviewform] = Form.useForm();
  const { singleProduct } = useSelector((state) => state.SingleproductReducer);
  const { wishlist, actionType, status_code, successMessage } = useSelector(
    (state) => state.wishlistReducer
  );
  const { actionType1, successMessage1, callapi } = useSelector(
    (state) => state.userCart
  );
  const { data, actionType_review, successMessage2,isFetching } = useSelector(
    (state) => state.productreview
  );

  const getUserId = sessionStorage.getItem("id");
  const getwishlist = useCallback(() => {
    try {
      dispatch(
        action.getWishlist(`${config.addtowishlist}?user_id=${getUserId}`)
      );
    } catch (error) {
      console.log(error);
    }
  }, [getUserId, dispatch]);

  useEffect(() => {
    getwishlist();
  }, [getUserId, getwishlist]);

  useEffect(() => {
    dispatch(
      actions.get_SingleProduct(
        `${config.productcategories}?product_id=${id}&user_id=${getUserId}`
      )
    );
  }, [dispatch, id]);

  const handleSelectChange = (option) => {
    const selectedWishlist = {
      wishlist_name: option.children,
      group_id: option.key,
    };
    setSelectedWishlist(selectedWishlist);
    setCustomWishlistName(option.children);
  };

  const scrollContainer = (amount) => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += amount;
    }
  };
  const handleWishlist = (productId) => {
    setIsWishlisted(!isWishlisted);
    setIsModalVisible(true);
    resetFormFields();
    setSelectedProductId(productId);
  };

  const resetFormFields = () => {
    setSelectedWishlist(null);
    setCustomWishlistName("");
    form.resetFields();
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setCustomWishlistName("");
  };

  const handleCustomWishlistNameChange = (e) => {
    setCustomWishlistName(e.target.value);
  };
  const addToWishlist = () => {
    closeModal();

    let payload = {
      user_id: sessionStorage.getItem("id"),
      product_id: String(selectedProductId),
      wishlist_name: selectedWishlist
        ? selectedWishlist.wishlist_name
        : customWishlistName.trim(),
      group_id: selectedWishlist ? selectedWishlist.group_id : null,
    };

    if (
      selectedWishlist &&
      selectedWishlist.wishlist_name.trim() !== customWishlistName.trim()
    ) {
      payload.wishlist_name = customWishlistName.trim();
      payload.group_id = null;
    }
    console.log(payload);
    try {
      dispatch(action.addToWishlist(config.addtowishlist, payload));
    } catch (error) {
      console.log(error);
    }
  };

  const addTocartpayload = {
    product_id: id,
    user_id: sessionStorage.getItem("id"),
    quantity: quantity,
    "attributes":[{
      url:selecteddata?.images[0],
      Ram:SelectedRAM,
      Storage:SelectedROM,
      price:filteredPrice,
       }]
  };
  const addTocart = () => {
    if (singleProduct.is_in_cart) {
      navigate("/addtocart");
    } else if (callapi) {
      dispatch(cartactions.addToCart(config.addtocart, addTocartpayload));
    }
  };

  const renderCategories = (singleProduct) => {
    return singleProduct?.map((category) => (
      <React.Fragment key={`category_${category.id}`}>
        {category.related_products?.map((product) => (
          <div key={`product_${product.id}`} style={styles.productContainer}>
            <img
              src={product?.images[0]?.url}
              alt={product.name}
              style={styles.image}
            />
            <div style={styles.productInfo}>
              <h3>{product.name}</h3>
              <p>
                price -{" "}
                {new Intl.NumberFormat("en-IN", {
                  maximumFractionDigits: 0,
                  style: "currency",
                  currency: "INR",
                }).format(product?.price)}
              </p>
            </div>
          </div>
        ))}
        {category.subcategories?.length > 0 &&
          renderCategories(category.subcategories)}
      </React.Fragment>
    ));
  };
  const navigateToProductPage = (productId) => {
    navigate(`/product/${productId}`);
    window.scroll(0, 0);
  };
  useEffect(() => {
    if (actionType === "ADD_TO_WISHLIST_SUCCESS" && status_code === 1) {
      message.success(successMessage);
      dispatch(action.resetActionType());
    } else if (actionType === "ADD_TO_WISHLIST_SUCCESS" && status_code === 0) {
      message.error(successMessage);
      dispatch(action.resetActionType());
    }
  }, [actionType, status_code, successMessage]);

  const getCartItems = useCallback(() => {
    const user_id = sessionStorage.getItem("id");
    dispatch(cartactions.getCartItem(`${config.getcart}?user_id=${user_id}`));
  }, [dispatch]);

  useEffect(() => {
    getCartItems();
  }, [getCartItems]);

  useEffect(() => {
    if (actionType1 === "ADD_TO_CART_SUCCESS") {
      message.success(successMessage1);
      getCartItems();
      dispatch(
        actions.get_SingleProduct(
          `${config.productcategories}?product_id=${id}&user_id=${getUserId}`
        )
      );
      dispatch(cartactions.resetActionType());
    }
  }, [actionType1, successMessage1, dispatch, getCartItems]);
  const incrementQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const decrementQuantity = () => {
    setQuantity((prevQuantity) => Math.max(1, prevQuantity - 1));
  };

  const getproductReviews = useCallback(() => {
    dispatch(
      reviewactions.getProductReview(`${config.getreview}?product_id=${id}`)
    );
  }, [dispatch]);
  useEffect(() => {
    setCurrentReviews([])
    setFilteredReviews([])
    setAllReviews([])
    getproductReviews();
  }, [getproductReviews]);

 


  const confirm = (product_id) => {
    dispatch(
      reviewactions.deleteProductReview(
        `${config.delete_Review}?review_id=${product_id}`
      )
    );
  };
  const Questionpopup = () => {
    questionform.resetFields();
    setModal2Open(true);
  };
  const Answermodel = (question_id) => {
    answerform.resetFields();
    setAnswerpopup(true);
    setQuestion_id(question_id);
  };
  const cancel = (e) => {
    console.log(e);
  };

  const onSearch = (value) => {
    if (!value) {
      message.warning("Please enter a search term");
      return;
    }
    dispatch(
      questionactions.getQuestionsAndAnswers(
        `${config.getQues_And_Ans}?product_id=${id}&search_text=${value}`
      )
    );
  };

  const addReview = () => {
    reviewform.resetFields();
    setReviewpopup(true)
  };


  useEffect(() => {
    if (singleProduct?.attributes?.[0]?.variants) {
      const defaultVariant = singleProduct.attributes[0].variants[0];
      const defaultRAM = defaultVariant.sizes[0].ram;
      const defaultROM = defaultVariant.sizes[0].rom[0].size;
      const defaultPrice = defaultVariant.sizes[0].rom[0].size.price;

      setselecteddata(defaultVariant);
      setSelectedColor(defaultVariant.color);
      setSelectedRAM(defaultRAM);
      setSelectedROM(defaultROM);
      setFilteredPrice(defaultPrice);
      filterPrice(defaultVariant.color, defaultRAM, defaultROM);
    }
  }, [singleProduct]);

  const handleImageClick = (color) => {
    setSelectedColor(color);
    setQuantity(1);
    filterPrice(color, SelectedRAM, SelectedROM);
    const variant = singleProduct?.attributes?.[0]?.variants.find(
      (v) => v.color === color
    );

    if (variant) {
      setselecteddata(variant);
      setFilteredImages(variant.images);
      console.log(selecteddata);
    } else {
      setselecteddata(null); // Reset selected data if no variant found
      setFilteredImages([]); // Reset filtered images if no variant found
    }
    filterPrice(color, SelectedRAM, SelectedROM);
  };

  const handleRAMClick = (ram) => {
    setSelectedRAM(ram);
    filterPrice(selectedColor, ram, SelectedROM);
    setQuantity(1);
  };

  const handleROMClick = (rom) => {
    setSelectedROM(rom);
    filterPrice(selectedColor, SelectedRAM, rom);
    setQuantity(1);
  };

  const filterPrice = (color, ram, rom) => {
    const variant = singleProduct?.attributes?.[0]?.variants.find(
      (v) => v.color === color
    );

    if (variant) {
      const size = variant.sizes.find((size) => size.ram === ram);
      if (size) {
        const romOption = size.rom.find((romObj) => romObj.size === rom);
        if (romOption) {
          setFilteredPrice(romOption.price);
          setProductquantity(romOption.qty);
        } else {
          setFilteredPrice(null);
          setProductquantity(null); // Reset if no matching ROM found
        }
      } else {
        setFilteredPrice(null);
        setProductquantity(null); // Reset if no matching RAM found
      }
    } else {
      setFilteredPrice(null);
      setProductquantity(null); // Reset if no matching color found
    }
  };

  useEffect(() => {
    if (filteredImages.length > 0) {
      setMainImage(filteredImages[0].url);
    } else if (
      singleProduct &&
      singleProduct?.images &&
      singleProduct?.images.length > 0
    ) {
      setMainImage(singleProduct?.images[0].url);
    }
  }, [singleProduct, filteredImages]);
  useEffect(() => {
    console.log(selectedColor);
    console.log(SelectedRAM);
    console.log(SelectedROM);
    console.log(filteredPrice);
    console.log(selecteddata);
  }, [selectedColor, SelectedRAM, SelectedROM, filteredPrice, selecteddata]);
  const postQuestion = (values) => {
    const payload = {
      user_id: sessionStorage.getItem("id"),
      product_id: id,
      question_text: values.question_text,
      question_status: "Published",
      tags: [],
      verified_purchase: "yes",
      question_type: "general",
    };
    try {
      dispatch(questionactions.createQuestion(config.create_question, payload));
    } catch (error) {
      console.log(error);
    }
  };
  const getquesAndAns = useCallback(() => {
    dispatch(
      questionactions.getQuestionsAndAnswers(
        `${config.getQues_And_Ans}?product_id=${id}`
      )
    );
  }, [dispatch]);
  useEffect(() => {
    dispatch(questionactions.resetActionType());
    getquesAndAns();
  }, [getquesAndAns]);
  const { data1, questionactionType, quessuccessMessage } = useSelector(
    (state) => state.questionsAnswers
  );
  const { currentUser } = useSelector((state) => state.auth);
  const address = currentUser?.shippingAddress;
  const questions = data1?.questions;

  const postAnswer = (values) => {
    const payload = {
      user_id: sessionStorage.getItem("id"),
      product_id: id,
      question_id: question_id,
      answer_text: values.answer_text,
      answer_status: "Published",
      tags: [],
      verified_purchase: "yes",
      answer_type: "general",
      respond_id: "null",
    };
    try {
      dispatch(questionactions.createAnswers(config.create_answer, payload));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      questionactionType === "CREATE_QUESTION_SUCCESS" ||
      questionactionType === "CREATE_ANSWER_SUCCESS"
    ) {
      message.success(quessuccessMessage);
      dispatch(questionactions.resetActionType());
      getquesAndAns();
      setModal2Open(false);
      setAnswerpopup(false);
    } else if (questionactionType === "CREATE_VOTE_SUCCESS") {
      message.success(quessuccessMessage);
      dispatch(questionactions.resetActionType());
      getproductReviews();
      getquesAndAns();
    }
  }, [questionactionType]);

  const create_helpful_vote = (review_id) => {
    const payload = {
      user_id: sessionStorage.getItem("id"),
      product_id: id,
      review_id: review_id,
      type: "review",
      vote_type: "helpful",
    };
    try {
      dispatch(questionactions.createVote(config.create_vote, payload));
    } catch (error) {
      console.log(error);
    }
  };
  const create_unhelpful_vote = (review_id) => {
    const payload = {
      user_id: sessionStorage.getItem("id"),
      product_id: id,
      review_id: review_id,
      type: "review",
      vote_type: "unhelpful",
    };
    try {
      dispatch(questionactions.createVote(config.create_vote, payload));
    } catch (error) {
      console.log(error);
    }
  };



  const ratingsArray = [
    { label: "5 star", data: data?.starRatings?.fiveStar },
    { label: "4 star", data: data?.starRatings?.fourStar },
    {  label: "3 star", data: data?.starRatings?.threeStar },
    { label: "2 star", data: data?.starRatings?.twoStar },
    { label: "1 star", data: data?.starRatings?.oneStar },
  ];

  const changeaddress = () => {
    navigate("/layout/address");
  };

  const reviewsPerPage = 5;
  const [allReviews, setAllReviews] = useState([]);
  const [filteredReviews, setFilteredReviews] = useState([]);
  const [currentReviews, setCurrentReviews] = useState([]);
  let [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (data?.reviews) {
      const sortedReviews = [...data?.reviews].sort((a, b) => new Date(b.review_date) - new Date(a.review_date));
      setAllReviews(sortedReviews);
    }
  }, [data]);

  useEffect(() => {
    const filtered = allReviews.filter((review) =>
      review.reviewer_name.toLowerCase().includes(searchText.toLowerCase()) ||
      review.rating.toString().includes(searchText) ||
      review.review_text.toLowerCase().includes(searchText)
    );
    setFilteredReviews(filtered);
  }, [searchText, allReviews]);

  useEffect(() => {
    const indexOfLastReview = currentPage * reviewsPerPage;
    const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
    setCurrentReviews(filteredReviews.slice(indexOfFirstReview, indexOfLastReview));
  }, [currentPage, filteredReviews]);

  const filterReview = (value) => {
    setSearchText(value);
    setCurrentPage(1); // 
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };



  const [uploadedImages, setUploadedImages] = useState([]);
  const [uploadedVideos, setUploadedVideos] = useState([]);

  useEffect(() => {
    if (actionType_review === "CREATE_REVIEW_SUCCESS" ) {
      message.success(successMessage2);
      setReviewpopup(false)
      dispatch(reviewactions.resetActionType());
      setAllReviews([])
      getproductReviews()
       
    }
  }, [actionType_review, dispatch, successMessage2]);
 
  const onFinish = async (values) => {
    
    try {
     
      const imageUrls = await Promise.all(
        uploadedImages?.map((file) => uploadFile(file))
      );
      const videoUrls = await Promise.all(
        uploadedVideos?.map((file) => uploadFile(file))
      );

      const formData = {
        user_id: sessionStorage.getItem("id"),
        product_id: id,
        rating: values.rating,
        review_title: values.review_title,
        review_text: values.review_text,
        tags: ["quality", "value"],
        locale: "en-US", 
        reviewer_name : JSON.parse(sessionStorage.getItem("Name")),
        images: [
          ...imageUrls?.map((url) => ({
           url: url,
            type: "image",
          })),
          ...videoUrls?.map((url) => ({
            url:url,
            type: "video",
          })),
        ]        
      };

      console.log(formData);
      try {
          dispatch(reviewactions.createReview(config.addreview, formData));
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.error("Error submitting review:", error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };



  const uploadFile = async (file) => {
    try {
      const formData = new FormData();
      formData.append("image", file.originFileObj);
      const resultData = await AxiosInterceptor.post(
        `${config.upload}`,
        formData
      );

      if (resultData?.status_code === 1) {
        console.log(resultData?.data.Location);
        return resultData?.data?.Location;
      } else {
        console.error("Failed to upload file:", resultData?.data);
        throw new Error("Failed to upload file to S3");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  const helpfulvotequestion=(quesid,answerid)=>{
    const payload = {
      user_id: sessionStorage.getItem("id"),
      product_id: id,
      question_id: quesid,
      answer_id:answerid,
      type:"answer",
      vote_type: "helpful",
    };
    try {
      dispatch(questionactions.createVote(config.create_vote, payload));
    } catch (error) {
      console.log(error);
    }
  }
  const unhelpfulvotequestion=(quesid,answerid)=>{
    const payload = {
      user_id: sessionStorage.getItem("id"),
      product_id: id,
      question_id: quesid,
      answer_id:answerid,
      type:"answer",
      vote_type: "unhelpful",
    };
    try {
      dispatch(questionactions.createVote(config.create_vote, payload));
    } catch (error) {
      console.log(error);
    }
  }

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const handleImage = (imageUrl) => {
    setPreviewImage(imageUrl);
    setPreviewVisible(true);
  };

  const handleCancel = () => {
    setPreviewVisible(false);
    setPreviewImage('');
  };
  return (
    <>
      <div style={{ backgroundColor: "white", padding: "20px" }}>
        <Row>
          <Col span={11}>
            <div
              style={{
                width: "100%",
                border: "1px solid #ccc",
                borderRadius: "4px",
                position: "relative",
              }}
            >
              <button
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "8px",
                  transform: "translateY(-50%)",
                  padding: "6px",
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                  border: "none",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                onClick={() => scrollContainer(-100)}
              >
                <LeftOutlined />
              </button>

              <div
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  overflowX: "hidden",
                  padding: "8px",
                }}
                ref={containerRef}
              >
                {(filteredImages.length > 0
                  ? filteredImages
                  : singleProduct?.images) &&
                  (filteredImages.length > 0
                    ? filteredImages
                    : singleProduct?.images
                  ).map((image, index) => (
                    <div
                      key={index}
                      style={{
                        flex: "0 0 auto",
                        marginRight: "8px",
                        cursor: "pointer",
                      }}
                      onMouseEnter={() => {
                        if (image.type === "video") {
                          setMainImage(image.url);
                          setVideo(true);
                        } else {
                          setMainImage(image.url);
                          setVideo(false);
                        }
                      }}
                    >
                      {image.type === "video" ? (
                        <video
                          src={image.url}
                          style={{
                            width: "80px",
                            height: "64px",
                            borderRadius: "2px",
                            objectFit: "contain",
                            boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                          }}
                          autoPlay
                          muted
                          loop
                          controlsList="nodownload"
                        />
                      ) : (
                        <img
                          src={image.url}
                          alt={`Image ${index}`}
                          style={{
                            width: "80px",
                            height: "64px",
                            borderRadius: "2px",
                            objectFit: "contain",
                            boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                          }}
                        />
                      )}
                    </div>
                  ))}
              </div>

              <button
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "8px",
                  transform: "translateY(-50%)",
                  padding: "6px",
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                  border: "none",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                onClick={() => scrollContainer(100)}
              >
                <RightOutlined style={{ color: "black" }} />
              </button>
            </div>
            <Card
              cover={
                <div style={{ position: "relative" }}>
                  {video ? (
                    <video
                      src={mainImage}
                      style={{
                        width: "100%",
                        height: "240px",
                        borderRadius: "4px",
                        objectFit: "contain",
                        padding: "16px",
                      }}
                      controls
                    />
                  ) : (
                    <img
                      src={mainImage}
                      alt="Main"
                      style={{
                        width: "100%",
                        height: "240px",
                        borderRadius: "4px",
                        objectFit: "contain",
                        padding: "16px",
                      }}
                    />
                  )}
                  <div
                    style={{
                      position: "absolute",
                      top: "16px",
                      left: "16px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleWishlist(id)}
                  >
                    {singleProduct?.is_in_wishlist ? (
                      <HeartFilled style={{ color: "red", fontSize: "20px" }} />
                    ) : (
                      <HeartFilled
                        style={{ color: "grey", fontSize: "20px" }}
                      />
                    )}
                  </div>
                </div>
              }
            >
              <div
                style={{
                  marginTop: 16,
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  icon={<ShoppingCartOutlined />}
                  style={{
                    width: 180,
                    height: 48,
                    backgroundColor: "#FFC300",
                    marginRight: 16,
                    fontSize: "14px",
                  }}
                  onClick={addTocart}
                >
                  {singleProduct.is_in_cart ? "Go to Cart" : "Add to Cart"}
                </Button>
                <Button
                  type="primary"
                  icon={<ShoppingOutlined />}
                  style={{
                    width: 180,
                    height: 48,
                    fontSize: "14px",
                  }}
                >
                  Buy Now
                </Button>
              </div>
            </Card>
          </Col>
          {singleProduct.category_id === "1" && (
            <Col span={13}>
              <div style={{ padding: "10px" }}>
                <h2>{singleProduct?.name ?? "null"}</h2>

                <p style={{ fontSize: "5px" }}>
                  <Rate
                    allowHalf
                    defaultValue={singleProduct?.rating ?? 0}
                    style={{ fontSize: "5px", color: "orange" }}
                  />
                  <span
                    style={{
                      fontSize: "16px",
                      color: "orange",
                      marginRight: "10px",
                    }}
                  >
                    {singleProduct?.rating ?? 0}
                  </span>
                  <span style={{ fontSize: "16px", color: "green" }}>
                    In Stock
                  </span>
                </p>
                <p style={{ fontSize: "16px" }}>
                  <b>₹ {filteredPrice}</b>
                </p>

                <p style={{ fontSize: "12px" }}>
                  Brand: <b>{singleProduct?.brand ?? "null"}</b>
                </p>

                <p style={{ fontSize: "12px", marginBottom: "10px" }}>
                  <b style={{ marginRight: "10px" }}>Description:</b>
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#666",
                      lineHeight: "1.5",
                    }}
                  >
                    {singleProduct?.description
                      ?.split("\n")
                      .map((line, index) => (
                        <span key={index}>
                          {line}
                          <br />
                        </span>
                      )) ?? "No description available"}
                  </span>
                </p>

                <div
                  style={{
                    marginRight: 20,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <p style={{ marginRight: "10px", fontSize: "12px" }}>
                    Quantity
                  </p>
                  <Button
                    onClick={decrementQuantity}
                    icon={<MinusOutlined />}
                    size="small"
                  />
                  <span style={{ margin: "0 10px", fontSize: "18px" }}>
                    {quantity}
                  </span>
                  <Button
                    onClick={incrementQuantity}
                    icon={<PlusOutlined />}
                    size="small"
                    disabled={quantity >= productquantity}
                  />
                </div>

                {singleProduct.attributes.map((attribute, index) => (
                  <div key={index}>
                    <h3 style={{ fontSize: "12px" }}>Colors:</h3>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {attribute.variants.map((variant, variantIndex) => (
                        <div
                          key={variantIndex}
                          style={{
                            marginRight: "10px",
                            marginBottom: "10px",
                            border:
                              selectedColor === variant.color
                                ? "2px solid blue"
                                : "none", // Highlight selected color
                          }}
                        >
                          <img
                            src={variant.images[0].url}
                            style={{
                              width: "50px",
                              height: "50px",
                              objectFit: "contain",
                              cursor: "pointer",
                            }}
                            onClick={() => handleImageClick(variant.color)}
                            alt={variant.color}
                          />
                          <p
                            style={{
                              fontSize: "12px",
                              textAlign: "center",
                              margin: "5px 0 0",
                            }}
                          >
                            <b>{variant.color ?? "null"}</b>
                          </p>
                        </div>
                      ))}
                    </div>

                    <div>
                      <p>RAM:</p>
                      <Button.Group size="small">
                        {attribute.variants
                          .find((variant) => variant.color === selectedColor)
                          ?.sizes.map((size, sizeIndex) => (
                            <Button
                              key={sizeIndex}
                              onClick={() => handleRAMClick(size.ram)}
                              style={{
                                border:
                                  SelectedRAM === size.ram
                                    ? "2px solid blue"
                                    : "none", // Highlight selected RAM
                              }}
                            >
                              {size.ram} GB
                            </Button>
                          ))}
                      </Button.Group>

                      <p>Storage:</p>
                      <Button.Group size="small">
                        {attribute.variants
                          .find((variant) => variant.color === selectedColor)
                          ?.sizes.find((size) => size.ram === SelectedRAM)
                          ?.rom.map((rom, romIndex) => (
                            <Button
                              key={romIndex}
                              onClick={() => handleROMClick(rom.size)}
                              style={{
                                border:
                                  SelectedROM === rom.size
                                    ? "2px solid blue"
                                    : "none", // Highlight selected ROM
                              }}
                            >
                              {rom.size} GB
                            </Button>
                          ))}
                      </Button.Group>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "45%" }}>
                        <div className="card">
                          <div className="card-body">
                            {/* Increase font size for card body */}
                            <p style={{ fontSize: "14px" }}>
                              Model: <b>{attribute?.model ?? "null"}</b>
                            </p>
                            <p style={{ fontSize: "14px" }}>
                              Weight: <b>{attribute?.weight ?? "null"}</b>
                            </p>
                            <p style={{ fontSize: "14px" }}>
                              Graphics: <b>{attribute?.graphics ?? "null"}</b>
                            </p>
                            <p style={{ fontSize: "14px" }}>
                              Warranty: <b>{attribute?.Warranty ?? "null"}</b>
                            </p>
                            <p style={{ fontSize: "14px" }}>
                              Batteries: <b>{attribute?.batteries ?? "null"}</b>
                            </p>
                            <p style={{ fontSize: "14px" }}>
                              Processor: <b>{attribute?.processor ?? "null"}</b>
                            </p>
                            <p style={{ fontSize: "14px" }}>
                              Model Year:{" "}
                              <b>{attribute?.model_year ?? "null"}</b>
                            </p>
                            <p style={{ fontSize: "14px" }}>
                              Resolution:{" "}
                              <b>{attribute?.resolution ?? "null"}</b>
                            </p>
                            <p style={{ fontSize: "14px" }}>
                              Screen Size:{" "}
                              <b>{attribute?.screen_size ?? "null"}</b>
                            </p>
                            <p style={{ fontSize: "14px" }}>
                              Display Type:{" "}
                              <b>{attribute?.display_type ?? "null"}</b>
                            </p>
                            <p style={{ fontSize: "14px" }}>
                              Manufacturer:{" "}
                              <b>{attribute?.manufacture ?? "null"}</b>
                            </p>
                            <p style={{ fontSize: "14px" }}>
                              Refresh Rate:{" "}
                              <b>{attribute?.refresh_rate ?? "null"}</b>
                            </p>
                            <p style={{ fontSize: "14px" }}>
                              Processor Type:{" "}
                              <b>{attribute?.processor_type ?? "null"}</b>
                            </p>
                            <p style={{ fontSize: "14px" }}>
                              Number Of Items:{" "}
                              <b>{attribute?.number_of_items ?? "null"}</b>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div style={{ width: "45%" }}>
                        <div className="card">
                          <div className="card-body">
                            {/* Increase font size for card body */}
                            <p style={{ fontSize: "14px" }}>
                              Operating System:{" "}
                              <b>{attribute?.operating_system ?? "null"}</b>
                            </p>
                            <p style={{ fontSize: "14px" }}>
                              Special Features:{" "}
                              <b>{attribute?.special_features ?? "null"}</b>
                            </p>
                            <p style={{ fontSize: "14px" }}>
                              Country Of Origin:{" "}
                              <b>{attribute?.country_of_origin ?? "null"}</b>
                            </p>
                            <p style={{ fontSize: "14px" }}>
                              Batteries Included:{" "}
                              <b>{attribute?.batteries_included ?? "null"}</b>
                            </p>
                            <p style={{ fontSize: "14px" }}>
                              Batteries Required:{" "}
                              <b>{attribute?.batteries_required ?? "null"}</b>
                            </p>
                            <p style={{ fontSize: "14px" }}>
                              Product Dimensions:{" "}
                              <b>{attribute?.product_dimensions ?? "null"}</b>
                            </p>
                            <p style={{ fontSize: "14px" }}>
                              Product Highlights:{" "}
                              <b>{attribute?.product_highlights ?? "null"}</b>
                            </p>
                            <p style={{ fontSize: "14px" }}>
                              Cellular Technology:{" "}
                              <b>{attribute?.cellular_technology ?? "null"}</b>
                            </p>
                            <p style={{ fontSize: "14px" }}>
                              Optic Sensor Resolution:{" "}
                              <b>
                                {attribute?.optic_sensor_resolution ?? "null"}
                              </b>
                            </p>
                            <p style={{ fontSize: "14px" }}>
                              Digital Storage Capacity:{" "}
                              <b>
                                {attribute?.digital_storage_capacity ?? "null"}
                              </b>
                            </p>
                            <p style={{ fontSize: "14px" }}>
                              Video Capture Resolution:{" "}
                              <b>
                                {attribute?.video_capture_resolution ?? "null"}
                              </b>
                            </p>
                            <p style={{ fontSize: "14px" }}>
                              Processor Brand:{" "}
                              <b>{attribute?.processor_brand ?? "null"}</b>
                            </p>
                            <p style={{ fontSize: "14px" }}>
                              Battery Capacity:{" "}
                              <b>{attribute?.battery_capacity ?? "null"}</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          )}
          {singleProduct.category_id === "12" && (
            <Col span={13}>
              <div style={{ padding: "20px" }}>
                <h2 style={{ fontSize: "20px", marginBottom: "10px" }}>
                  {singleProduct.name ?? "null"}
                </h2>

                <div style={{ marginBottom: "20px" }}>
                  <p style={{ fontSize: "14px", marginBottom: "5px" }}>
                    Price: <b>{singleProduct.price ?? "null"}</b>
                  </p>
                  <p style={{ fontSize: "14px", marginBottom: "5px" }}>
                    Description: <b>{singleProduct.description ?? "null"}</b>
                  </p>
                  <p style={{ fontSize: "14px", marginBottom: "5px" }}>
                    Stock Quantity:{" "}
                    <b>{singleProduct.stock_quantity ?? "null"}</b>
                  </p>
                  <p style={{ fontSize: "14px", marginBottom: "5px" }}>
                    Rating: <b>{singleProduct.rating ?? "null"}</b>
                  </p>
                  <p style={{ fontSize: "14px", marginBottom: "5px" }}>
                    Brand: <b>{singleProduct.brand ?? "null"}</b>
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <p style={{ fontSize: "14px", marginRight: "10px" }}>
                    Quantity
                  </p>
                  <Button
                    onClick={decrementQuantity}
                    icon={<MinusOutlined />}
                    size="small"
                    style={{ marginRight: "10px" }}
                  />
                  <span style={{ fontSize: "18px", marginRight: "10px" }}>
                    {quantity}
                  </span>
                  <Button
                    onClick={incrementQuantity}
                    icon={<PlusOutlined />}
                    size="small"
                  />
                </div>

                {singleProduct.attributes &&
                  singleProduct.attributes.length > 0 && (
                    <div style={{ marginBottom: "20px" }}>
                      <h3 style={{ fontSize: "14px", marginBottom: "10px" }}>
                        Colors:
                      </h3>
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {singleProduct.attributes.map(
                          (attribute, attributeIndex) => (
                            <div
                              key={attributeIndex}
                              style={{
                                marginRight: "20px",
                                marginBottom: "10px",
                              }}
                            >
                              <div
                                style={{ display: "flex", flexWrap: "wrap" }}
                              >
                                {attribute.variants.map(
                                  (variant, variantIndex) => (
                                    <div
                                      key={variantIndex}
                                      style={{
                                        marginRight: "20px",
                                        marginBottom: "10px",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img
                                        src={variant.images[0].url}
                                        alt={variant.color}
                                        width="50"
                                        height="50"
                                        style={{
                                          border: "1px solid #ddd",
                                          padding: "5px",
                                          marginRight: "10px",
                                        }}
                                        onClick={() =>
                                          handleImageClick(variant.color)
                                        }
                                      />
                                      <p style={{ fontSize: "12px" }}>
                                        {variant.color}
                                      </p>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )}

                <div>
                  <p style={{ fontSize: "14px", marginBottom: "5px" }}>
                    Style: {singleProduct.style}
                  </p>
                  <p style={{ fontSize: "14px", marginBottom: "5px" }}>
                    Item Weight: {singleProduct.item_weight}
                  </p>
                  <p style={{ fontSize: "14px", marginBottom: "5px" }}>
                    Material: {singleProduct.material}
                  </p>
                  <p style={{ fontSize: "14px", marginBottom: "5px" }}>
                    Offer: {singleProduct.attributes[0].offer}{" "}
                    {/* Assuming only one set of attributes */}
                  </p>
                  <p style={{ fontSize: "14px", marginBottom: "5px" }}>
                    Collar: {singleProduct.attributes[0].collar}
                  </p>
                  <p style={{ fontSize: "14px", marginBottom: "5px" }}>
                    Fabric: {singleProduct.attributes[0].fabric}
                  </p>
                  <p style={{ fontSize: "14px", marginBottom: "5px" }}>
                    Sleeve: {singleProduct.attributes[0].sleeve}
                  </p>
                  <p style={{ fontSize: "14px", marginBottom: "5px" }}>
                    Pattern: {singleProduct.attributes[0].pattern}
                  </p>
                  <p style={{ fontSize: "14px", marginBottom: "5px" }}>
                    Fabric Care: {singleProduct.attributes[0].fabric_care}
                  </p>
                  <p style={{ fontSize: "14px", marginBottom: "5px" }}>
                    Manufacturer: {singleProduct.attributes[0].manufacturer}
                  </p>
                  <p style={{ fontSize: "14px", marginBottom: "5px" }}>
                    Net Quantity: {singleProduct.attributes[0].net_quantity}
                  </p>
                  <p style={{ fontSize: "14px", marginBottom: "5px" }}>
                    Special Features:{" "}
                    {singleProduct.attributes[0].special_features}
                  </p>
                  <p style={{ fontSize: "14px", marginBottom: "5px" }}>
                    Country of Origin:{" "}
                    {singleProduct.attributes[0].country_of_origin}
                  </p>
                  <p style={{ fontSize: "14px", marginBottom: "5px" }}>
                    Product Dimensions:{" "}
                    {singleProduct.attributes[0].product_dimensions}
                  </p>
                  <p style={{ fontSize: "14px", marginBottom: "5px" }}>
                    Product Highlights:{" "}
                    {singleProduct.attributes[0].product_highlights}
                  </p>
                </div>
              </div>
            </Col>
          )}
          {singleProduct.category_id === "59" && (
            <Col span={13}>
              <div style={{ padding: "20px" }}>
                <h2 style={{ fontSize: "24px", marginBottom: "20px" }}>
                  {singleProduct.name}
                </h2>
                <div style={{ display: "flex", marginBottom: "20px" }}>
                  <div style={{ flex: "1 1 50%" }}>
                    <h3>Description</h3>
                    <p>{singleProduct.description}</p>

                    <p>
                      <b>Price:</b> ${singleProduct.price}
                    </p>
                    <p>
                      <b>Stock Quantity:</b> {singleProduct.stock_quantity}
                    </p>
                    <p>
                      <b>Rating:</b> {singleProduct.rating}/5
                    </p>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <p style={{ fontSize: "14px", marginRight: "10px" }}>
                        Quantity
                      </p>
                      <Button
                        onClick={decrementQuantity}
                        icon={<MinusOutlined />}
                        size="small"
                        style={{ marginRight: "10px" }}
                      />
                      <span style={{ fontSize: "18px", marginRight: "10px" }}>
                        {quantity}
                      </span>
                      <Button
                        onClick={incrementQuantity}
                        icon={<PlusOutlined />}
                        size="small"
                      />
                    </div>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {singleProduct.attributes.map((attribute, aIndex) => (
                        <div key={aIndex} style={{ marginBottom: "20px" }}>
                          {attribute.Variants.map((variant, vIndex) => (
                            <div
                              key={vIndex}
                              style={{
                                marginRight: "20px",
                                marginBottom: "10px",
                              }}
                            >
                              <h4>{variant.Type}</h4>
                              <div
                                style={{ display: "flex", flexWrap: "wrap" }}
                              >
                                {variant.images.map((image, iIndex) => (
                                  <img
                                    key={iIndex}
                                    src={image.url}
                                    alt={singleProduct.name}
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      objectFit: "cover",
                                      border: "1px solid #ddd",
                                      marginRight: "10px",
                                      marginBottom: "10px",
                                      cursor: "pointer",
                                    }}
                                  />
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>

                    {singleProduct.attributes &&
                      singleProduct.attributes.length > 0 && (
                        <div>
                          {singleProduct.attributes.map((attribute, index) => (
                            <div key={index}>
                              <p>
                                <b>Brand:</b> {attribute.Brand}
                              </p>
                              <p>
                                <b>Offer:</b> {attribute.Offer}
                              </p>
                              <p>
                                <b>Weight:</b> {attribute.Weight}
                              </p>
                              <p>
                                <b>Discount:</b> {attribute.Discount}
                              </p>
                              <p>
                                <b>Warranty:</b> {attribute.Warranty}
                              </p>
                              <p>
                                <b>Shelf Life:</b> {attribute.Shelf_Life}
                              </p>
                              <p>
                                <b>Manufacture:</b> {attribute.Manufacture}
                              </p>
                              <p>
                                <b>Allergens:</b> {attribute.Allergens}
                              </p>
                              <p>
                                <b>Model Year:</b> {attribute.Model_Year}
                              </p>
                              <p>
                                <b>Special Features:</b>{" "}
                                {attribute.Special_Features}
                              </p>
                              <p>
                                <b>Country of Origin:</b>{" "}
                                {attribute.Country_Of_Origin}
                              </p>
                              <p>
                                <b>Product Dimensions:</b>{" "}
                                {attribute.Product_Dimensions}
                              </p>
                              <p>
                                <b>Storage Information:</b>{" "}
                                {attribute.Storage_Information}
                              </p>
                              <p>
                                <b>Nutrition Facts:</b>
                              </p>
                              <ul>
                                <li>
                                  <b>Fat:</b> {attribute.Nutrition_Facts.Fat}
                                </li>
                                <li>
                                  <b>Protein:</b>{" "}
                                  {attribute.Nutrition_Facts.Protein}
                                </li>
                                <li>
                                  <b>Calories:</b>{" "}
                                  {attribute.Nutrition_Facts.Calories}
                                </li>
                                <li>
                                  <b>Carbohydrates:</b>{" "}
                                  {attribute.Nutrition_Facts.Carbohydrates}
                                </li>
                              </ul>
                              <h4>Sizes and Prices:</h4>
                              <div>
                                {attribute.Variants.map((variant, vIndex) => (
                                  <div
                                    key={vIndex}
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <p>
                                      <b>Type:</b> {variant.Type}
                                    </p>
                                    <ul>
                                      {variant.Sizes.map((size, sIndex) => (
                                        <li key={sIndex}>
                                          <b>{size.Weight}:</b> ${size.Price}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </Col>
          )}
          {singleProduct.category_id === "53" && <div>Beauty && skincare</div>}
        </Row>
        <div style={{ padding: "20px", backgroundColor: "#f9f9f9" }}>
          {/* <Card
            style={{
              marginBottom: "20px",
              borderRadius: "10px",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Row>
              <Col span={11}>
                <List
                  itemLayout="horizontal"
                  dataSource={[address]} // Wrap the single address in an array
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        title={<h3 style={{ margin: 0 }}>Delivery Address</h3>}
                        description={`${item?.Name}, ${item?.Address}, ${item?.Locality_Town}, ${item?.City_District}, ${item?.Pin_Code}`} // Combine name and address
                        style={{ fontSize: "16px", color: "#555" }}
                      />
                      <Button
                        size={"small"}
                        type="dashed"
                        onClick={() => changeaddress()}
                      >
                        change address
                      </Button>
                    </List.Item>
                  )}
                />
              </Col>
              <hr />
              <Col span={11}>
                <List
                  itemLayout="horizontal"
                  dataSource={[address]} // Wrap the single address in an array
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        title={<h3 style={{ margin: 0 }}>Billing Address</h3>}
                        description={`${item?.Name}, ${item?.Address}, ${item?.Locality_Town}, ${item?.City_District}, ${item?.Pin_Code}`} // Combine name and address
                        style={{ fontSize: "16px", color: "#555" }}
                      />
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          </Card> */}
{isFetching ? <Spin />:
          <Row gutter={16} style={{ marginBottom: "10px" }}>
          
            <Col span={11}>
              <div
                style={{
                  padding: "10px",
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <h3 style={{ margin: "0 0 10px 0", fontSize: "24px" }}>
                  Customer Reviews
                </h3>
                <Rate
                  disabled
                  allowHalf
                  defaultValue={data?.averageRating}
                  style={{ fontSize: "20px", marginBottom: "10px" }}
                />

                <div style={{ marginBottom: "10px" }}>
                  {ratingsArray?.map((item) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "5px 0",
                      }}
                    >
                      <p style={{ margin: "0", fontSize: "16px" }}>
                        {item.label}
                      </p>
                      <span style={{ flexGrow: 1, marginLeft: "10px" }}>
                        <Progress
                          percent={Math.round(
                            parseFloat(item?.data?.percentage)
                          )}
                        />
                      </span>
                    </div>
                  ))}
                </div>

                <div style={{ marginTop: "20px" }}>
                  <h2 style={{ margin: "0 0 10px 0", fontSize: "20px" }}>
                    Product Review
                  </h2>
                  <Button
                    type="primary"
                    onClick={() => addReview(id)}
                    style={{ borderRadius: "5px" }}
                  >
                    Add a product review
                  </Button>
                </div>
              </div>
            </Col>

            <Col span={13}>
              <div>
              <Card
          title={
            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <h3 style={{ margin: 0 }}>Reviews</h3>
              <Search
                onSearch={filterReview}
                placeholder="Search for reviews..."
                style={{ width: 300, marginRight: 10 }}
              />
            </div>
          }
          style={{
            borderRadius: '10px',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
            height: 'auto',
          }}
        >
          {currentReviews?.map((item) => (
            <div key={item.review_id} style={{ marginBottom: '15px' }}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <UserOutlined style={{ fontSize: '20px', marginRight: '8px' }} />
                <span style={{ fontWeight: 'bold' }}>{item.reviewer_name}</span>
                <Rate
                  disabled
                  defaultValue={item?.rating}
                  className="custom-rate"
                  style={{ marginLeft: '10px' }}
                />
              </span>
              <p style={{ margin: '5px 0',fontWeight:"bold" }}>{item.review_title}</p>
              <p style={{ margin: '5px 0'}}>{item.review_text}</p>
              <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
              {item?.images?.map((image) => (
        <div key={image?.id}>
          
          {image?.type === 'image' && (
                                   <img
                                   src={image.url}
                                   alt="img"
                                   style={{
                                     width: "110px",
                                     height: "74px",
                                     borderRadius: "2px",
                                     objectFit: "contain",
                                     boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                                     
                                   }}
                                   onClick={() => handleImage(image.url)}
                                 />
          )}
          
          {image.type === 'video' && (
            <video
            src={image.url}
            style={{
              width: "110px",
              height: "74px",
              borderRadius: "2px",
              objectFit: "contain",
              boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
            }}
            controls
            muted
            loop
            controlsList="nodownload"
          />

          )}
        </div>
      ))}
</div>
              <Space style={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip title="Helpful">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      icon={<LikeOutlined />}
                      onClick={() => create_helpful_vote(item.id)}
                    />
                    <div style={{ fontSize: '12px', marginTop: '4px' }}>
                      {item?.helpful_votes}
                    </div>
                  </div>
                </Tooltip>
                <Tooltip title="Unhelpful">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      icon={<DislikeOutlined />}
                      onClick={() => create_unhelpful_vote(item.id)}
                    />
                    <div style={{ fontSize: '12px', marginTop: '4px' }}>
                      {item?.unhelpful_votes}
                    </div>
                  </div>
                </Tooltip>
                {item.verified_purchase === 'yes' && (
                  <Tag color="green" style={{ marginLeft: '16px' }}>
                    Verified Purchase
                  </Tag>
                )}



              </Space>

              <Divider />
            </div>
          ))}
          <Pagination
            current={currentPage}
            pageSize={reviewsPerPage}
            total={filteredReviews.length}
            onChange={handlePageChange}
            style={{ float: 'right' }}
          />
        </Card>
              </div>
            </Col>

          </Row>
}
          <Card
            title={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "2px",
                }}
              >
                <h3 style={{ margin: 0, color: "#a4e0e8" }}>
                  Questions and Answers
                </h3>
                <div>
                  <Search
                    placeholder="Type your question here..."
                    onSearch={onSearch}
                    style={{ width: 300, marginRight: 10 }}
                  />
                  <Button type="primary" onClick={() => Questionpopup()}>
                    Post Your Question
                  </Button>
                </div>
              </div>
            }
            size="small"
            style={{
              marginTop: "20px",
              borderRadius: "10px",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            {/* {data1?.questions?.map((item) => (
          <div key={item.id} style={{ marginBottom: "15px" }}>
            <p style={{ fontWeight: 'bold' }}>Q: {item?.question_text}</p>
            {item?.answers?.map((answer) => (
              <p key={answer.id} style={{ marginLeft: "20px" }}>A: {answer?.answer_text}</p>
            ))}
            <Divider />
          </div>
        ))} */}

            <VirtualList
              data={questions}
              height={200}
              itemHeight={47}
              itemKey={(item) => item.id}
              onScroll={() => {}}
            >
              {(item) => (
                <div key={item?.id} >
                  <span
                    style={{
                      fontWeight: "bold",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      margin: 0,
                      fontSize: "12px",
                      color: "#333",
                    height:"30px"
                    }}
                  >
                    <span>Q: {item?.question_text}</span>

                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "auto", // Pushes this span to the right
                      }}
                    >
                     {(singleProduct.is_answered && getUserId !==item.user_id) && (
                        <Button
                          style={{
                            marginRight: "10px",
                            backgroundColor: "#4CAF50",
                            color: "white",
                            border: "none",
                            borderRadius: "5px",
                            padding: "5px 10px",
                            cursor: "pointer",
                            transition: "background-color 0.3s",
                          }}
                          onClick={() => Answermodel(item?.id)}
                        >
                          Post Answer
                        </Button>
                      )}
                    </span>
                  </span>

                  {item?.answers && item?.answers.length > 0 ? (
                    item?.answers.map((answer, index) => (
   <Row> 
      <Col span={1}>  
  <Space >
    <Tooltip title="Helpful">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginLeft: "3px", 
        }}
      >
        <Button
          icon={<LikeOutlined />}
          onClick={() => helpfulvotequestion(item?.id, answer?.id)}
          size="small"
          style={{
            padding: "2px 3px", 
            fontSize: "7px", 
          }}
        />
        <div
          style={{
            fontSize: "8px",
            marginTop: "2px",
            color: "#666",
          }}
        >
          {answer?.helpful_votes}
        </div>
      </div>
    </Tooltip>
    <Tooltip title="Unhelpful">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginLeft: "3px", // Reduced margin
        }}
      >
        <Button
          icon={<DislikeOutlined />}
          onClick={() => unhelpfulvotequestion(item?.id, answer?.id)}
          size="small"
          style={{
            padding: "2px 3px", // Reduced padding
            fontSize: "7px", 
          }}
        />
        <div
          style={{
            fontSize: "8px", // Smaller font size for vote count
            marginTop: "2px", // Reduced margin
            color: "#666",
          }}
        >
        {answer?.unhelpful_votes}
        </div>
      </div>
    </Tooltip>
  </Space>
  </Col>  
    <Col span={22}>             
<span key={index} style={{ marginLeft: "20px"}}>
  A: {answer?.answer_text}
  </span>
  </Col> 



</Row>   
        ))
                  ) : (
                    <p style={{ marginLeft: "20px", color: "#888" }}>
                      No answers yet.
                    </p>
                  )}
                  <Divider />
                </div>
              )}
            </VirtualList>
          </Card>
        </div>
        <Card title="Similar Products">
          <div style={styles.container}>
            {singleProduct?.related_product_details?.map((product) => (
              <div
                key={`product_${product.id}`}
                style={styles.productContainer}
              >
                <div style={{ position: "relative" }}>
                  <span
                    style={{
                      position: "absolute",
                      top: "8px",
                      left: "8px",
                      fontSize: "20px",
                      color: "black",
                      cursor: "pointer", // Add cursor pointer for visual indication
                    }}
                    onClick={() => handleWishlist(product.id)} // Attach onClick handler here
                  >
                    <HeartOutlined />
                  </span>
                  <img
                    src={product.images[0]?.url}
                    alt={product.name}
                    style={styles.image}
                    onClick={() => navigateToProductPage(product.id)}
                  />
                </div>

                <div style={styles.productInfo}>
                  <h3>{product.name}</h3>
                  <p>Price - {product.price}</p>
                </div>
              </div>
            ))}
          </div>
        </Card>

        <Modal
          visible={isModalVisible}
          onOk={addToWishlist}
          onCancel={closeModal}
          width={380}
        >
          <Form form={form} layout="vertical">
            <Form.Item label="Select wishlist">
              <Select
                placeholder="Select wishlist"
                style={{ width: "100%" }}
                value={selectedWishlist?.wishlist_name}
                onChange={(value, option) => handleSelectChange(option)}
              >
                {wishlist?.data
                  ?.reduce((uniqueItems, item) => {
                    if (
                      !uniqueItems?.some(
                        (uniqueItems) =>
                          uniqueItems.wishlist_name === item.wishlist_name
                      )
                    ) {
                      uniqueItems?.push(item);
                    }
                    return uniqueItems;
                  }, [])
                  .map((item) => (
                    <Option key={item.group_id} value={item.wishlist_name}>
                      {item.wishlist_name}
                    </Option>
                  ))}
                {wishlist?.data?.some(
                  (item) => item.wishlist_name === "My Wishlist"
                ) ? null : (
                  <Option value="My Wishlist">My Wishlist</Option>
                )}
              </Select>
            </Form.Item>

            <Form.Item label="Or create a new wishlist">
              <Input
                placeholder="Enter wishlist name"
                value={customWishlistName}
                onChange={handleCustomWishlistNameChange}
              />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Post your question"
          centered
          open={modal2Open}
          onOk={() => questionform.submit()}
          onCancel={() => setModal2Open(false)}
          okText="Post"
          style={{
            top: 20,
            borderRadius: "10px",
            width: "90%", // Responsive width
            maxWidth: "600px",
          }} // Positioning the modal
        >
          <Form
            form={questionform}
            layout="vertical"
            onFinish={postQuestion} // Handle form submission
          >
            <Form.Item
              name="question_text"
              rules={[{ required: true, message: "Question Required!" }]} // Validation rule
            >
              <TextArea
                rows={4}
                style={{
                  borderRadius: "8px",
                  border: "1px solid #e8e8e8",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  transition: "border-color 0.3s ease, box-shadow 0.3s ease",
                  padding: "10px",
                  fontSize: "16px",
                  color: "#333",
                  resize: "none",
                  width: "100%", // Text color
                  marginTop: "6px",
                }}
                placeholder="Type your question here..."
                onFocus={(e) => {
                  e.target.style.borderColor = "#1890ff"; // Change border color on focus
                  e.target.style.boxShadow =
                    "0 0 0 2px rgba(24, 144, 255, 0.2)"; // Shadow on focus
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#e8e8e8"; // Reset border color on blur
                  e.target.style.boxShadow = "none"; // Reset shadow on blur
                }}
              />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Post your Answer"
          centered
          open={answerpopup}
          onOk={() => answerform.submit()}
          onCancel={() => setAnswerpopup(false)}
          okText="Post"
          style={{
            top: 20,
            borderRadius: "10px",
            width: "90%", // Responsive width
            maxWidth: "600px",
          }} // Positioning the modal
        >
          <Form
            form={answerform}
            layout="vertical"
            onFinish={postAnswer} // Handle form submission
          >
            <Form.Item
              name="answer_text"
              rules={[{ required: true, message: "Answer Required!" }]} // Validation rule
            >
              <TextArea
                rows={4}
                style={{
                  borderRadius: "8px",
                  border: "1px solid #e8e8e8",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  transition: "border-color 0.3s ease, box-shadow 0.3s ease",
                  padding: "10px",
                  fontSize: "16px",
                  color: "#333",
                  resize: "none",
                  width: "100%", // Text color
                  marginTop: "6px",
                }}
                placeholder="Type your answer here..."
                onFocus={(e) => {
                  e.target.style.borderColor = "#1890ff"; // Change border color on focus
                  e.target.style.boxShadow =
                    "0 0 0 2px rgba(24, 144, 255, 0.2)"; // Shadow on focus
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#e8e8e8"; // Reset border color on blur
                  e.target.style.boxShadow = "none"; // Reset shadow on blur
                }}
              />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Post your Review"
          centered
          open={reviewpopup}
          onOk={() =>reviewform.submit()}
          onCancel={() => setReviewpopup(false)}
          okText="Post Review"
          style={{
            top: 20,
            borderRadius: "10px",
            width: "90%", // Responsive width
            maxWidth: "600px",
          }} // Positioning the modal
        >
           <Form
          name="basic"
          layout="vertical"
          form={reviewform}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="rating"
            label={
              <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                Rate this product
              </span>
            }
            rules={[{ required: true, message: "Rate the product required!" }]}
          >
            <Rate
              style={{ fontSize: "2rem", color: "#faad14" }}
              character={<StarFilled />}
            />
          </Form.Item>

          <Form.Item
            label={<span style={labelStyle}>Review Title</span>}
            name="review_title"
            rules={[{ required: true, message: "Review title required!" }]}
          >
            <Input style={inputStyle} placeholder="Enter your review title" />
          </Form.Item>

          <Form.Item
            label={<span style={labelStyle}>Review Text</span>}
            name="review_text"
            rules={[{ required: true, message: "Please input your review text!" }]}
          >
            <Input.TextArea
              style={textareaStyle}
              placeholder="Enter your review text"
            />
          </Form.Item>

          <Form.Item
          name="upload"
            label={<span style={labelStyle}>Add a photo or video</span>}
          >
            <Upload
              name="images"
              accept="image/*, video/mp4, video/x-m4v, video/*"
              multiple
              listType="picture-card"
              onRemove={(file) => {
                if (file.type.startsWith("image/")) {
                  setUploadedImages((prevImages) =>
                    prevImages.filter((img) => img.uid !== file.uid)
                  );
                } else {
                  setUploadedVideos((prevVideos) =>
                    prevVideos.filter((vid) => vid.uid !== file.uid)
                  );
                }
              }}
              onChange={(info) => {
                const { fileList } = info;
                const images = fileList.filter((file) => file.type.startsWith("image"));
                const videos = fileList.filter((file) => file.type.startsWith("video"));
                setUploadedImages(images);
                setUploadedVideos(videos);
              }}
            >
              <Button icon={<UploadOutlined />}></Button>
            </Upload>
          </Form.Item>

          <Form.Item>

          </Form.Item>
        </Form>

        </Modal>
        <Modal open={previewVisible} footer={null} onCancel={handleCancel}>
        <img alt="preview" style={{ width: '100%' }} src={previewImage} />
      </Modal>
      </div>
    </>
  );
};
const styles = {
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
  },
  productContainer: {
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
  image: {
    width: "200px",
    height: "200px",
    objectFit: "contain",
    marginBottom: "10px",
  },
  productInfo: {
    textAlign: "center",
  },
};
const labelStyle = {
  fontSize: "1.2rem",
  fontWeight: "bold",
  color: "#333",
  textShadow: "0 1px 2px rgba(0,0,0,0.05)",
};
const inputStyle = {
  borderRadius: "8px",
  height: "45px",
  padding: "12px",
  fontSize: "1.1rem",
  border: "1px solid #e8e8e8",
  boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
  transition: "all 0.3s ease",
};

const textareaStyle = {
  ...inputStyle,
  minHeight: "120px",
};

export default ProductDetails;
