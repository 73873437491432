import actions from "../Logout/actions";
import Immutable from "seamless-immutable";

const initialState = Immutable({

  isFetching: false,
  successMessage: "",
  errorMessage: "",
  actionType: "",
  status_code: 0,
});

export default function LogoutRequestReducer(state = initialState, action) {
  switch (action.type) {
    case actions.LOGOUT_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionType: "LOGOUT_REQUEST",
      });
    case actions.LOGOUT_SUCCESS:
      return Immutable.merge(state, {
        actionType: "LOGOUT_SUCCESS",
        status_code:action.status_code,
        successMessage:action.successMessage
      });
    case actions.LOGOUT_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: "LOGOUT_ERROR",
        errorMessage: action.errorMessage,
      });
    case actions.RESET_ACTIONTYPE:
      return Immutable.merge(state, {
        actionType: "",
        status_code: 0,
        successMessage: "",
      });
    default:
      return state;
  }
}
