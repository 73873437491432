// reducer.js
import actions from "./actions";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  isFetching: false,
  successMessage: "",
  errorMessage: "",
  actionType: "",
  status_code: 0,
  data: [],
  singledata:[],
});

export default function orderDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_ORDER_DETAILS:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.GET_ORDER_DETAILS_SUCCESS:
      return Immutable.merge(state, {
        actionType: "GET_ORDER_DETAILS_SUCCESS",
        isFetching: false,
        data: action.data.user,
      });
    case actions.GET_ORDER_DETAILS_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: "GET_ORDER_DETAILS_ERROR",
        errorMessage: action.errorMessage,
      });
    case actions.GET_DETAILED_0RDERINFOMRATION:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.GET_DETAILED_0RDERINFOMRATION_SUCCESS:
      return Immutable.merge(state, {
        actionType: "GET_DETAILED_0RDERINFOMRATION_SUCCESS",
        isFetching: false,
        singledata: action.data.user,
      });
    case actions.GET_DETAILED_0RDERINFOMRATION_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: "GET_DETAILED_0RDERINFOMRATION_ERROR",
        errorMessage: action.errorMessage,
      });
    case actions.UPDATE_ORDER_DETAILS:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.UPDATE_ORDER_DETAILS_SUCCESS:
      return Immutable.merge(state, {
        actionType: "UPDATE_ORDER_DETAILS_SUCCESS",
        isFetching: false,
        data: action.data,
      });
    case actions.UPDATE_ORDER_DETAILS_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: "UPDATE_ORDER_DETAILS_ERROR",
        errorMessage: action.errorMessage,
      });
    case actions.CANCEL_ORDER:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.CANCEL_ORDER_SUCCESS:
      return Immutable.merge(state, {
        actionType: "CANCEL_ORDER_SUCCESS",
        isFetching: false,
        data: action.data,
      });
    case actions.CANCEL_ORDER_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: "CANCEL_ORDER_ERROR",
        errorMessage: action.errorMessage,
      });
    case actions.RESET_ACTIONTYPE:
      return Immutable.merge(state, {
        actionType: "",
        status_code: 0,
        successMessage: "",
        data: {},
      });
    default:
      return state;
  }
}
