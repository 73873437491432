import React, { useEffect ,useState} from "react";
import { Row, Col, Typography, Divider } from "antd";
import ImageGallery from "../productpage/Imagecard";
import WishlistModal from "../productpage/wishlistmodal"; // Import the WishlistModal component
import { useDispatch, useSelector } from "react-redux";
import actions from "../../Redux/SingleProduct/actions";

import config from "../../Services/siteConfig";
import { useParams } from "react-router-dom";

const Product = ({ addTocart, handleWishlist, isWishlisted }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const getUserId = sessionStorage.getItem("id");
  const [isModalVisible, setIsModalVisible] = useState(false); // Add state for modal visibility
  const { singleProduct } = useSelector((state) => state.SingleproductReducer);

  useEffect(() => {
    dispatch(
      actions.get_SingleProduct(
        `${config.productcategories}?product_id=${id}&user_id=${getUserId}`
      )
    );
  }, [dispatch, id]);

  const handleWishlistClick = () => {
    setIsModalVisible(true);
  };

  return (
    <div style={{ padding: "24px" }}>
      <Row gutter={24}>
        <Col span={12}>
          <ImageGallery
            singleProduct={singleProduct}
            id={singleProduct.id}
            addTocart={addTocart}
            handleWishlist={handleWishlistClick} // Update handleWishlist prop
            isWishlisted={isWishlisted}
          />
        </Col>
      </Row>
      <WishlistModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        selectedProductId={id}
      /> 
    </div>
  );
};

export default Product;