import actions from "../Singleuserdetails/actions"; // Assuming this is the correct path
import Immutable from "seamless-immutable";

const initialState = Immutable({
  isUpdating: false,
  successMessage: "",
  errorMessage: "",
  actionType: "",
  Singleuserdata: {},
  status_code: 0,
});

export default function SingleuserDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SINGLE_USER_DETAILS_REQUEST:
      return Immutable.merge(state, {
        isUpdating: true,
        actionType: "SINGLE_USER_DETAILS_REQUEST",
      });
    case actions.SINGLE_USER_DETAILS_SUCCESS:
      return Immutable.merge(state, {
        Singleuserdata: action.data,
        isUpdating: false,
        actionType: "SINGLE_USER_DETAILS_SUCCESS",
      });
    case actions.SINGLE_USER_DETAILS_FAILURE:
      return Immutable.merge(state, {
        isUpdating: false,
        actionType: "SINGLE_USER_DETAILS_FAILURE",
        errorMessage: action.errorMessage,
      });

    case actions.RESET_ACTIONTYPE:
      return Immutable.merge(state, {
        actionType: "",
      });
    default:
      return state;
  }
}
