import React, { useState ,useEffect} from "react";
import { Modal, Button, Input, Select ,message,Form} from "antd";
import action from "../../Redux/wishlist/actions";
import config from "../../Services/siteConfig";
import { useDispatch ,useSelector} from "react-redux";

const { Option } = Select;

const WishlistModal = ({ isModalVisible, setIsModalVisible, selectedProductId }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { wishlist, actionType, status_code, successMessage } = useSelector(
    (state) => state.wishlistReducer
  );
  const getUserId = sessionStorage.getItem("id");
  const [selectedWishlist, setSelectedWishlist] = useState("");
  const [customWishlistName, setCustomWishlistName] = useState("");

  const handleSelectChange = (option) => {
    setSelectedWishlist(option);
  };

  const handleCustomWishlistNameChange = (e) => {
    setCustomWishlistName(e.target.value);
  };

  const addToWishlist = () => {
    closeModal();

    let payload = {
      user_id: sessionStorage.getItem("id"),
      product_id: String(selectedProductId),
      wishlist_name: selectedWishlist
        ? selectedWishlist.wishlist_name
        : customWishlistName.trim(),
      group_id: selectedWishlist ? selectedWishlist.group_id : null,
    };

    if (
      selectedWishlist &&
      selectedWishlist.wishlist_name.trim() !== customWishlistName.trim()
    ) {
      payload.wishlist_name = customWishlistName.trim();
      payload.group_id = null;
    }
    console.log(payload);
    try {
      dispatch(action.addToWishlist(config.addtowishlist, payload));
    } catch (error) {
      console.log(error);
    }
  };

  const handleOk = () => {
    if (selectedWishlist || customWishlistName.trim() !== "") {
      let payload = {
        user_id: getUserId,
        product_id: selectedProductId,
        wishlist_name: selectedWishlist
          ? selectedWishlist.wishlist_name
          : customWishlistName.trim(),
        group_id: null,
      };

      // Check if the selected wishlist is "My Wishlist" and if it exists in the wishlist data
      const myWishlistExists = wishlist?.data?.some(
        (item) => item.wishlist_name === "My Wishlist"
      );

      // If "My Wishlist" is selected and exists in the backend data, assign its group_id
      if (
        (selectedWishlist &&
          selectedWishlist.wishlist_name === "My Wishlist" &&
          myWishlistExists) ||
        (!selectedWishlist &&
          customWishlistName.trim() === "My Wishlist" &&
          myWishlistExists)
      ) {
        payload.group_id = wishlist.data.find(
          (item) => item.wishlist_name === "My Wishlist"
        ).group_id;
      }

      dispatch(action.addToWishlist(config.addtowishlist, payload));
      setIsModalVisible(false);
      setCustomWishlistName("");
    } else {
      message.error("Please select a wishlist or enter a custom wishlist name.");
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCustomWishlistName("");
  };

  useEffect(() => {
    if (actionType === "ADD_TO_WISHLIST_SUCCESS" && status_code === 1) {
      message.success(successMessage);
      dispatch(action.resetActionType());
    } else if (actionType === "ADD_TO_WISHLIST_SUCCESS" && status_code === 0) {
      message.error(successMessage);
      dispatch(action.resetActionType());
    }
  }, [actionType, status_code, successMessage, dispatch]);

  const closeModal = () => {
    setIsModalVisible(false);
    setCustomWishlistName("");
  };

  return (
    <Modal
          visible={isModalVisible}
          onOk={addToWishlist}
          onCancel={closeModal}
          width={380}
        >
          <Form form={form} layout="vertical">
            <Form.Item label="Select wishlist">
              <Select
                placeholder="Select wishlist"
                style={{ width: "100%" }}
                value={selectedWishlist?.wishlist_name}
                onChange={(value, option) => handleSelectChange(option)}
              >
                {wishlist?.data
                  ?.reduce((uniqueItems, item) => {
                    if (
                      !uniqueItems.some(
                        (uniqueItems) =>
                          uniqueItems.wishlist_name === item.wishlist_name
                      )
                    ) {
                      uniqueItems.push(item);
                    }
                    return uniqueItems;
                  }, [])
                  .map((item) => (
                    <Option key={item.group_id} value={item.wishlist_name}>
                      {item.wishlist_name}
                    </Option>
                  ))}
                {wishlist?.data.some(
                  (item) => item.wishlist_name === "My Wishlist"
                ) ? null : (
                  <Option value="My Wishlist">My Wishlist</Option>
                )}
              </Select>
            </Form.Item>

            <Form.Item label="Or create a new wishlist">
              <Input
                placeholder="Enter wishlist name"
                value={customWishlistName}
                onChange={handleCustomWishlistNameChange}
              />
            </Form.Item>
          </Form>
        </Modal>
  );
};

export default WishlistModal;