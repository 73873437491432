import actions from "../MobileEmailverify/actions";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  currentUser: {},
  verifyotp: "",
  isFetching: false,
  successMessages: "",
  errorMessage: "",
  actionTypes: "",
  status_codes: 0,
});

export default function authemailmobileReducer(state = initialState, action) {
  switch (action.type) {
    case actions.MOBILE_OTP_SEND_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.MOBILE_OTP_SEND_SUCCESS:
      return Immutable.merge(state, {
      
        actionTypes: "MOBILE_OTP_SEND_SUCCESS",
        successMessages: action.message,
        status_codes:action.status_code
      });
    case actions.MOBILE_OTP_SEND_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionTypes: "MOBILE_OTP_SEND_ERROR",
        errorMessages: action.errorMessage,
      });

    case actions.MOBILE_OTP_RESEND_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionTypes: "MOBILE_OTP_RESEND_REQUEST",
      });
    case actions.MOBILE_OTP_RESEND_SUCCESS:
      return Immutable.merge(state, {
        actionTypes: "MOBILE_OTP_RESEND_SUCCESS",
        successMessages: action.message,
        status_codes:action.status_code
      });
    case actions.MOBILE_OTP_RESEND_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionTypes: "LOGIN_OTP_RESEND_ERROR",
        errorMessages: action.errorMessage,
      });
    case actions.MOBILE_OTP_VERIFY_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.MOBILE_OTP_VERIFY_SUCCESS:
      return Immutable.merge(state, {
        currentUser: action.data,
        actionTypes: "MOBILE_OTP_VERIFY_SUCCESS",
        successMessages: action.message,
        status_codes:action.status_code
      });
    case actions.MOBILE_OTP_VERIFY_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionTypes: "MOBILE_OTP_VERIFY_ERROR",
        errorMessages: action.errorMessage,
      });

    case actions.EMAIL_OTP_SEND_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.EMAIL_OTP_SEND_SUCCESS:
      return Immutable.merge(state, {
        currentUser: action.data,
        actionTypes: "EMAIL_OTP_SEND_SUCCESS",
        successMessages: action.message,
        status_codes:action.status_code
      });
    case actions.EMAIL_OTP_SEND_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionTypes: "EMAIL_OTP_SEND_ERROR",
        errorMessages: action.errorMessage,
      });

    case actions.EMAIL_OTP_RESEND_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionTypes: "EMAIL_OTP_RESEND_REQUEST",
      });
    case actions.EMAIL_OTP_RESEND_SUCCESS:
      return Immutable.merge(state, {
        actionTypes: "EMAIL_OTP_RESEND_SUCCESS",
        successMessages: action.message,
        status_codes:action.status_code
      });
    case actions.EMAIL_OTP_RESEND_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionTypes: "EMAIL_OTP_RESEND_ERROR",
        errorMessages: action.errorMessage,
      });

    case actions.EMAIL_OTP_VERIFY_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.EMAIL_OTP_VERIFY_SUCCESS:
      return Immutable.merge(state, {
       
        actionTypes: "EMAIL_OTP_VERIFY_SUCCESS",
        successMessages: action.message,
        status_codes:action.status_code
      });
    case actions.EMAIL_OTP_VERIFY_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionTypes: "EMAIL_OTP_VERIFY_ERROR",
        errorMessages: action.errorMessage,
      });

    case actions.RESET_ACTIONTYPE:
      return Immutable.merge(state, {
        actionTypes: "",
      });
    default:
      return state;
  }
}
