const actions = {
  ADD_TO_WISHLIST: "ADD_TO_WISHLIST",
  ADD_TO_WISHLIST_SUCCESS: "ADD_TO_WISHLIST_SUCCESS",
  ADD_TO_WISHLIST_ERROR: "ADD_TO_WISHLIST_ERROR",

  DELETE_FROM_WISHLIST: "DELETE_FROM_WISHLIST",
  DELETE_FROM_WISHLIST_SUCCESS: "DELETE_FROM_WISHLIST_SUCCESS",
  DELETE_FROM_WISHLIST_ERROR: "DELETE_FROM_WISHLIST_ERROR",

  UPDATE_WISHLIST: "UPDATE_WISHLIST",
  UPDATE_WISHLIST_SUCCESS: "UPDATE_WISHLIST_SUCCESS",
  UPDATE_WISHLIST_ERROR: "UPDATE_WISHLIST_ERROR",

  GET_WISHLIST: "GET_WISHLIST",
  GET_WISHLIST_SUCCESS: "GET_WISHLIST_SUCCESS",
  GET_WISHLIST_ERROR: "GET_WISHLIST_ERROR",

  RESET_ACTIONTYPE: "RESET_ACTIONTYPE",

  addToWishlist: (url,body) => ({
    type: actions.ADD_TO_WISHLIST,
    payload: { url,body },
  }),

  deleteFromWishlist: (url) => ({
    type: actions.DELETE_FROM_WISHLIST,
    payload: { url },
  }),

  updateWishlist: (url,body) => ({
    type: actions.UPDATE_WISHLIST,
    payload: { url,body},
  }),

  getWishlist: (url) => ({
    type: actions.GET_WISHLIST,
    payload:{url}
  }),


  resetActionType: () => ({
    type: actions.RESET_ACTIONTYPE,
  }),
};
export default actions;
