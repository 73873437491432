import React from "react";
import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";

const Footer = () => {
  return (
    <footer style={{ backgroundColor: "#f5f5f5", padding: "20px 0" }}>
      <div style={{ maxWidth: "1200px", margin: "0 auto", padding: "0 20px" }}>
        <div style={{ display: "flex", flexWrap: "wrap", margin: "0 -15px" }}>
          <div style={{ width: "25%", padding: "0 15px" }}>
            <h4
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              ONLINE SHOPPING
            </h4>
            <ul style={{ listStyle: "none", padding: "0", margin: "0" }}>
              <li style={{ marginBottom: "10px" }}>
                <a
                  href="#"
                  style={{ textDecoration: "none", color: "#337ab7" }}
                >
                  Men
                </a>
              </li>
              <li style={{ marginBottom: "10px" }}>
                <a
                  href="#"
                  style={{ textDecoration: "none", color: "#337ab7" }}
                >
                  Women
                </a>
              </li>
              <li style={{ marginBottom: "10px" }}>
                <a
                  href="#"
                  style={{ textDecoration: "none", color: "#337ab7" }}
                >
                  Kids
                </a>
              </li>
              <li style={{ marginBottom: "10px" }}>
                <a
                  href="#"
                  style={{ textDecoration: "none", color: "#337ab7" }}
                >
                  Home & Living
                </a>
              </li>
              <li style={{ marginBottom: "10px" }}>
                <a
                  href="#"
                  style={{ textDecoration: "none", color: "#337ab7" }}
                >
                  Beauty
                </a>
              </li>
            </ul>
          </div>
          <div style={{ width: "25%", padding: "0 15px" }}>
            <h4
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              CUSTOMER POLICIES
            </h4>
            <ul style={{ listStyle: "none", padding: "0", margin: "0" }}>
              <li style={{ marginBottom: "10px" }}>
                <a
                  href="#"
                  style={{ textDecoration: "none", color: "#337ab7" }}
                >
                  FAQ
                </a>
              </li>
              <li style={{ marginBottom: "10px" }}>
                <a
                  href="#"
                  style={{ textDecoration: "none", color: "#337ab7" }}
                >
                  Terms & Conditions
                </a>
              </li>
              <li style={{ marginBottom: "10px" }}>
                <a
                  href="#"
                  style={{ textDecoration: "none", color: "#337ab7" }}
                >
                  Privacy Policy
                </a>
              </li>
              <li style={{ marginBottom: "10px" }}>
                <a
                  href="#"
                  style={{ textDecoration: "none", color: "#337ab7" }}
                >
                  Grievance Officer
                </a>
              </li>
            </ul>
          </div>
          <div style={{ width: "25%", padding: "0 15px" }}>
            <h4
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              EXPERIENCE MYNTRA APP ON MOBILE
            </h4>
            <ul style={{ listStyle: "none", padding: "0", margin: "0" }}>
              <li style={{ marginBottom: "10px" }}>
                <a
                  href="#"
                  style={{ textDecoration: "none", color: "#337ab7" }}
                >
                  Google Play
                </a>
              </li>
              <li style={{ marginBottom: "10px" }}>
                <a
                  href="#"
                  style={{ textDecoration: "none", color: "#337ab7" }}
                >
                  App Store
                </a>
              </li>
            </ul>
          </div>
          <div style={{ width: "25%", padding: "0 15px" }}>
            <h4
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              USEFUL LINKS
            </h4>
            <ul style={{ listStyle: "none", padding: "0", margin: "0" }}>
              <li style={{ marginBottom: "10px" }}>
                <a
                  href="#"
                  style={{ textDecoration: "none", color: "#337ab7" }}
                >
                  Track Orders
                </a>
              </li>
              <li style={{ marginBottom: "10px" }}>
                <a
                  href="#"
                  style={{ textDecoration: "none", color: "#337ab7" }}
                >
                  Shipping
                </a>
              </li>
              <li style={{ marginBottom: "10px" }}>
                <a
                  href="#"
                  style={{ textDecoration: "none", color: "#337ab7" }}
                >
                  Cancellation
                </a>
              </li>
              <li style={{ marginBottom: "10px" }}>
                <a
                  href="#"
                  style={{ textDecoration: "none", color: "#337ab7" }}
                >
                  Returns
                </a>
              </li>
            </ul>
          </div>
        </div>
   
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px 0",
          }}
        >
          <a href="#" style={{ marginRight: "10px" }}>
            <FacebookOutlined style={{ fontSize: "24px", color: "#1890ff" }} />
          </a>
          <a href="#" style={{ marginRight: "10px" }}>
            <TwitterOutlined style={{ fontSize: "24px", color: "#1890ff" }} />
          </a>
          <a href="#" style={{ marginRight: "10px" }}>
            <InstagramOutlined style={{ fontSize: "24px", color: "#1890ff" }} />
          </a>
          <a href="#" style={{ marginRight: "10px" }}>
            <YoutubeOutlined style={{ fontSize: "24px", color: "#1890ff" }} />
          </a>
        </div>
        <p style={{ fontSize: "12px", color: "#777", marginBottom: "10px" }}>
          Have issues?{" "}
          <a href="#" style={{ textDecoration: "none", color: "#337ab7" }}>
            Contact Us
          </a>
        </p>
        <p style={{ fontSize: "12px", color: "#777" }}>
          © 2024 www.samyutha.com. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
