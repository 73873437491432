// actions.js
const actions = {
  OTP_REQUEST: "OTP_REQUEST",
  OTP_SUCCESS: "OTP_SUCCESS",
  OTP_ERROR: "OTP_ERROR",
  RESET_ACTIONTYPE: "RESET_ACTIONTYPE",

  otpverification: (url) => ({
    type: actions.OTP_REQUEST,
    payload: { url }
}),
resetActionType: () => {
  return {
    type: actions.RESET_ACTIONTYPE,
  };
},

}
export default actions;
