import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import actions from "../LoginOtpverify/actions";
import AxiosInterceptor from "../../Services/axiosInterceptor";

const verifyOTP = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.get(url);
  return result;
};

const LoginresendOTP = async (payload) => {
  const { url,body } = payload;
  const result = await AxiosInterceptor.post(url,body);
  return result;
};

export function* verifyOTPRequest() {
  yield takeEvery("LOGIN_OTP_VERIFY_REQUEST", function* ({ payload }) {
    try {
      const res = yield call(verifyOTP, payload);
      if (res) {
        yield put({
          type: actions.LOGIN_OTP_VERIFY_SUCCESS,
          status_code: res.status_code,
          successMessage: res.message,
        });
        console.log(res);
      } else {
        yield put({
          type: actions.LOGIN_OTP_VERIFY_ERROR,
          errorMessage: "OTP verification failed. Please try again.",
        });
      }
    } catch (error) {
      yield put({
        type: actions.LOGIN_OTP_VERIFY_ERROR,
        errorMessage:
          "Something went wrong during OTP verification. Please try again.",
      });
    }
  });
}

export function* Login_verify_Resend() {
  yield takeEvery("RESEND_LOGIN_OTP_REQUEST", function* ({ payload }) {
    try {
      const res = yield call(LoginresendOTP, payload);
      if (res) {
        yield put({
          type: actions.RESEND_LOGIN_OTP_SUCCESS,
          status_code: res.status_code,
          successMessage: res.message,
        });
        console.log(res);
      } else {
        yield put({
          type: actions.RESEND_LOGIN_OTP_ERROR,
          errorMessage: "Failed to resend OTP. Please try again.",
        });
      }
    } catch (error) {
      yield put({
        type: actions.RESEND_LOGIN_OTP_ERROR,
        errorMessage:
          "Something went wrong while resending OTP. Please try again.",
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(verifyOTPRequest)]);
  yield all([fork(Login_verify_Resend)]);
}
