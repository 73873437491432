import React, { useState, useCallback, useEffect } from 'react';
import { Button, Space, Modal, Steps, List, Descriptions, Divider, Input, Row, Col, Checkbox, Tag } from 'antd';
import { EyeOutlined, FilterOutlined } from '@ant-design/icons';
import Search from 'antd/es/transfer/search';
import actions from "./../../Redux/orderhistory/actions";
import config from "../../Services/siteConfig";
import { useDispatch, useSelector } from "react-redux";

const { Step } = Steps;

const OrderHistory = () => {
  const dispatch = useDispatch();

  const [selectedOrder, setSelectedOrder] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [returnModalVisible, setReturnModalVisible] = useState(false);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [returnDetails, setReturnDetails] = useState({
    reason: '',
    comments: '',
  });
  const [filterStatus, setFilterStatus] = useState([]);
  const [filterTime, setFilterTime] = useState([]);

  const handleViewDetails = (order) => {
    setSelectedOrder(order);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setSelectedOrder(null);
    setModalVisible(false);
    setReturnDetails({
      reason: '',
      comments: '',
    });
  };

  const handleFilterClick = () => {
    setFilterModalVisible(true);
  };

  const handleFilterApply = () => {
    console.log('Applying filters:', { status: filterStatus, time: filterTime });
    setFilterModalVisible(false);
  };

  const getOrderdetails = useCallback(() => {
    const user_id = sessionStorage.getItem("id");
    dispatch(actions.getAllOrder(`${config.getallorderdetails}?user_id=${user_id}`));
  }, [dispatch]);

  useEffect(() => {
    getOrderdetails();
  }, [getOrderdetails]);

  const { data } = useSelector((state) => state.orderDetailsReducer);

  function formatDate(dateString) {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
  
  function getStatusStepsWithDates(order) {
    const steps = [
      { title: "Ordered", date: formatDate(order.order_date) },
      { title: "Confirmed", date: formatDate(order?.confirmed_date) },
      { title: "Packed", date: formatDate(order?.packed_date) },
      { title: "Shipped", date: formatDate(order?.shipping_date) },
      { title: "Out of Delivery", date: formatDate(order?.out_of_delivery_date) },
      { title: "Delivered", date: formatDate(order?.delivery_date) }
    ];
    return steps;
  }
  function getreturnStepsWithDates(order) {
    const steps = [
      { title: "returned", date: formatDate(order?.return_requested_date) },
      { title: "return approved", date: formatDate(order?.return_approved_date) },
      { title: "return picked", date: formatDate(order?.return_pickup_date) },
      { title: "return completed", date: formatDate(order?.return_completed_date) },
      { title: "refunded", date: formatDate(order?.out_of_delivery_date) },
    ];
    return steps;
  }
  function getStatusTagColor(status) {
    switch (status) {
      case "ordered": return "blue";
      case "confirmed": return "cyan";
      case "cancelled": return "red";
      case "packed": return "purple";
      case "shipped": return "green";
      case "out for delivered": return "orange";
      case "delivered": return "gold";
      case "returned": return "magenta";
      case "return approved": return "volcano";
      case "return picked": return "lime";
      case "return completed": return "geekblue";
      case "replaced": return "cyan";
      case "pickuped": return "blue";
      case "exchanged": return "purple";
      case "refunded": return "red";
      default: return "default";
    }
  }

  const isReturnEligible = (order) => {
    if (!order || !order?.delivery_date || !order?.return_days) return false;

    const deliveryDate = new Date(order?.delivery_date);
    const today = new Date();
    const returnDays = parseInt(order.return_days, 10);

    const differenceInDays = (today - deliveryDate) / (1000 * 3600 * 24);

    return differenceInDays <= returnDays;
  };

  return (
    <div style={{ minHeight: '100vh', padding: '20px' }}>
      <Space style={{display:"flex",justifyContent:"center"}}>
        <Search placeholder="Input search text" enterButton />
        <Button icon={<FilterOutlined />} onClick={handleFilterClick}>Filter</Button>
      </Space>

      <List
        itemLayout="vertical"
        size="large"
        pagination={{
          onChange: page => {
            console.log(page);
          },
          pageSize: 5,
        }}
        dataSource={data}
        renderItem={item => (
          <List.Item key={item.id}>
            <div style={{ display: 'flex', width: '100%', padding: '10px' }}>
              <img
                width={100}
                height={100}
                alt="Product"
                src={item.product?.images[0]?.url || 'https://via.placeholder.com/100'}
                style={{ objectFit: 'contain', marginRight: '20px' }}
              />
              <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <List.Item.Meta style={{ marginBottom: '10px' }} />
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                  <h4 style={{ width: '200px', marginRight: '10px', marginBottom: '5px' }}>{item?.product?.name}</h4>
                  <p style={{ width: '100px', marginRight: '10px', marginBottom: '5px' }}>Price: {item.total_price}</p>
                  <p style={{ width: '200px', marginRight: '10px', marginBottom: '5px' }}>
                    Ordered Date: {formatDate(item.order_date)}
                  </p>
                  <p style={{ width: '200px', marginRight: '10px', marginBottom: '5px' }}>
                    Status: <Tag color={getStatusTagColor(item?.order_status)}>{item?.order_status}</Tag>
                  </p>
                  <Button icon={<EyeOutlined />} onClick={() => handleViewDetails(item)}>
                    View Details
                  </Button>
                </div>
              </div>
            </div>
          </List.Item>
        )}
      />

      <Modal
        visible={modalVisible}
        onCancel={handleCloseModal}
        width={900}
        footer={[
          isReturnEligible(selectedOrder) && (
            <Button key="return" type="primary" onClick={() => setReturnModalVisible(true)}>
              Return
            </Button>
          ),
          <Button key="close" onClick={handleCloseModal}>
            Close
          </Button>
        ]}
      >
        {selectedOrder && (
          <div>
            <Row>
              <Col span={6}>
                <img
                  width={100}
                  height={100}
                  alt="Product"
                  src={selectedOrder?.product?.images[0]?.url || 'https://via.placeholder.com/100'}
                  style={{ objectFit: 'contain', marginRight: '20px' }}
                />
              </Col>
              <Col span={6}>
                <h4 style={{ width: '200px', marginRight: '10px', marginBottom: '5px' }}>
                  {selectedOrder?.product?.name}<br />
                  <span>Price: {selectedOrder?.product?.price}</span>
                </h4>
              </Col>

              <Col span={6}>
                <p>Expected Date: {selectedOrder?.expected_date}</p>
              </Col>
            </Row>

            <Divider />
            <h4>Order Status</h4>
            <Steps current={getStatusStep(selectedOrder.return_requested_date ? "delivered" : selectedOrder.tracking?.status)} progressDot>

              {getStatusStepsWithDates(selectedOrder).map(step => (
                <Step key={step.title} title={step.title} description={`Date: ${step.date}`} />
              ))}
            </Steps>
            <Divider />

            {selectedOrder.return_requested_date && (
              <>
                <h4>Return Order Status</h4>
                <Steps current={returnStep(selectedOrder.tracking?.status)} progressDot>
                  {getreturnStepsWithDates(selectedOrder).map(step => (
                    <Step key={step.title} title={step.title} description={`Date: ${step.date}`} />
                  ))}
                </Steps>
                <Divider />
              </>
            )}

            <Descriptions title="Order Information" layout="vertical" bordered>
              <Descriptions.Item label="Payment Method">{selectedOrder.payment_method || 'N/A'}</Descriptions.Item>
              <Descriptions.Item label="Total Price">{selectedOrder?.total_price || 'N/A'}</Descriptions.Item>
              <Descriptions.Item label="Shipping Address">{selectedOrder.shipping_address || 'N/A'}</Descriptions.Item>
              <Descriptions.Item label="Is Gift">{selectedOrder.is_gift || 'N/A'}</Descriptions.Item>
              <Descriptions.Item label="Gift Message">{selectedOrder.gift_message || 'N/A'}</Descriptions.Item>
              <Descriptions.Item label="Discount Code">{selectedOrder.discount_code || 'N/A'}</Descriptions.Item>
              <Descriptions.Item label="Tax Amount">{selectedOrder.tax_amount || 'N/A'}</Descriptions.Item>
              <Descriptions.Item label="Delivery Notes">{selectedOrder.delivery_notes || 'N/A'}</Descriptions.Item>
              <Descriptions.Item label="Return Eligibility">
                {isReturnEligible(selectedOrder) 
                  ? `Eligible for return until ${formatDate(new Date(new Date(selectedOrder?.delivery_date).getTime() + selectedOrder.return_days * 24 * 60 * 60 * 1000))}`
                  : 'Not eligible for return'}
              </Descriptions.Item>
            </Descriptions>
          </div>
        )}
      </Modal>

      <Modal
        title="Return Order"
        visible={returnModalVisible}
        onCancel={() => setReturnModalVisible(false)}
        width={500}
      >
        {selectedOrder && (
          <>
            <Input.TextArea
              placeholder="Reason for return"
              value={returnDetails.reason}
              onChange={e => setReturnDetails({ ...returnDetails, reason: e.target.value })}
            />
            <br /><br />
            <Input.TextArea
              placeholder="Additional comments"
              value={returnDetails.comments}
              onChange={e => setReturnDetails({ ...returnDetails, comments: e.target.value })}
            />
          </>
        )}
      </Modal>

      <Modal
        title="Filter Orders"
        visible={filterModalVisible}
        onOk={handleFilterApply}
        onCancel={() => setFilterModalVisible(false)}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Checkbox.Group
              value={filterStatus}
              onChange={checkedValues => setFilterStatus(checkedValues)}
            >
              <Space direction="vertical">
                <Checkbox value="ordered">Ordered</Checkbox>
                <Checkbox value="confirmed">Confirmed</Checkbox>
                <Checkbox value="packed">Packed</Checkbox>
                <Checkbox value="shipped">Shipped</Checkbox>
                <Checkbox value="out_of_delivery">Out of Delivery</Checkbox>
                <Checkbox value="delivered">Delivered</Checkbox>
              </Space>
            </Checkbox.Group>
          </Col>
          <Col span={12}>
            <Checkbox.Group
              value={filterTime}
              onChange={checkedValues => setFilterTime(checkedValues)}
            >
              <Space direction="vertical">
                <Checkbox value="anytime">Anytime</Checkbox>
                <Checkbox value="last_30_days">Last 30 days</Checkbox>
                <Checkbox value="last_6_months">Last 6 months</Checkbox>
                <Checkbox value="last_year">Last year</Checkbox>
              </Space>
            </Checkbox.Group>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

function getStatusStep(status) {
  switch (status) {
    case "ordered": return 0;
    case "confirmed": return 1;
    case "packed": return 2;
    case "Shipped": return 3;
    case "out_of_delivery": return 4;
    case "delivered": return 5;
    default: return 0;
  }
}
function returnStep(status) {
  switch (status) {
    case "returned": return 0;
    case "return approved": return 1;
    case "pickuped": return 2;
    case "Shipped": return 3;
    case "return completed": return 4;
    case "refunded": return 5;
    default: return 0;
  }
}
export default OrderHistory;
