const actions = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",

  LOGIN_OTP_VERIFY_REQUEST: "LOGIN_OTP_VERIFY_REQUEST",
  LOGIN_OTP_VERIFY_SUCCESS: "LOGIN_OTP_VERIFY_SUCCESS",
  LOGIN_OTP_VERIFY_ERROR: "LOGIN_OTP_VERIFY_ERROR",

  LOGIN_OTP_RESEND_REQUEST: "LOGIN_OTP_RESEND_REQUEST",
  LOGIN_OTP_RESEND_SUCCESS: "LOGIN_OTP_RESEND_SUCCESS",
  LOGIN_OTP_RESEND_ERROR: "LOGIN_OTP_RESEND_ERROR",

  RESET_ACTIONTYPE: "RESET_ACTIONTYPE",

  login: (url) => ({
    type: actions.LOGIN_REQUEST,
    payload: { url },
  }),

  loginOTPVerify: (url) => ({
    type: actions.LOGIN_OTP_VERIFY_REQUEST,
    payload: { url },
  }),

  loginOTPResend: (url, body) => ({
    type: actions.LOGIN_OTP_RESEND_REQUEST,
    payload: { url, body },
  }),

  resetActionType: () => {
    return {
      type: actions.RESET_ACTIONTYPE,
    };
  },
};

export default actions;
