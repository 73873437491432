const actions = {
  SEARCH_PRODUCTS_REQUEST: "SEARCH_PRODUCTS_REQUEST",
  SEARCH_PRODUCTS_SUCCESS: "SEARCH_PRODUCTS_SUCCESS",
  SEARCH_PRODUCTS_ERROR: "SEARCH_PRODUCTS_ERROR",
  RESET_SEARCH_STATE: "RESET_SEARCH_STATE",

  searchProducts: (url) => ({
    type: actions.SEARCH_PRODUCTS_REQUEST,
    payload: { url},
  }),

  resetSearchState: () => ({
    type: actions.RESET_SEARCH_STATE,
  }),
};

export default actions;
