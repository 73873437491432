import actions from "./actions";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  isFetching: true,
  successMessage1: "",
  errorMessage: "",
  actiontype1: "",
});

export default function resendReducer(state = initialState, action) {
  switch (action.type) {
    case actions.RESEND_OTP:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.RESEND_OTP_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        actionType1: "RESEND_OTP_SUCCESS",
        successMessage1:action.successMessage
      });
      case actions.RESET_ACTIONTYPE:
        return Immutable.merge(state, {
          actionType1: "",
        });
    case actions.RESEND_OTP_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType1: "RESEND_OTP_ERROR",
        errorMessage: action.errorMessage,
      });
    default:
      return state;
  }
}
