const actions = {
  USER_DETAILS_UPDATE_REQUEST: "USER_DETAILS_UPDATE_REQUEST",
  USER_DETAILS_UPDATE_SUCCESS: "USER_DETAILS_UPDATE_SUCCESS",
  USER_DETAILS_UPDATE_FAILURE: "USER_DETAILS_UPDATE_FAILURE",

  RESET_ACTIONTYPE: "RESET_ACTIONTYPE",

  updatedetails: (url, body) => ({
    type: actions.USER_DETAILS_UPDATE_REQUEST,
    payload: { url, body },
  }),

  resetActionType: () => {
    return {
      type: actions.RESET_ACTIONTYPE,
    };
  },
};

export default actions;
