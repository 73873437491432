import actions from "../LoginOtpverify/actions";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  verifyotp: "",
  isFetching: false,
  successMessage: "",
  errorMessage: "",
  actionType: "",
  status_code: 0,
});

export default function verifyOTPRequest(state = initialState, action) {
  switch (action.type) {
    case actions.LOGIN_OTP_VERIFY_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionType: "LOGIN_OTP_VERIFY_REQUEST",
      });
    case actions.LOGIN_OTP_VERIFY_SUCCESS:
      return Immutable.merge(state, {
        actionType: "LOGIN_OTP_VERIFY_SUCCESS",
        verifyotp: action.data,
        status_code: action.status_code,
        successMessage: action.successMessage,
      });
    case actions.LOGIN_OTP_VERIFY_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: "LOGIN_OTP_VERIFY_ERROR",
        errorMessage: action.errorMessage,
      });

    case actions.RESEND_LOGIN_OTP_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionType: "RESEND_LOGIN_OTP_REQUEST",
      });
    case actions.RESEND_LOGIN_OTP_SUCCESS:
      return Immutable.merge(state, {
        status_code: action.status_code,
        successMessage: action.message,
        actionType: "RESEND_LOGIN_OTP_SUCCESS",
      });
    case actions.RESEND_LOGIN_OTP_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: "RESEND_LOGIN_OTP_ERROR",
        errorMessage: action.errorMessage,
      });
    case actions.RESET_ACTIONTYPE:
      return Immutable.merge(state, {
        actionType: "",
        status_code: 0,
        successMessage: "",
      });
    default:
      return state;
  }
}
