// sagas.js
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import actions from "./actions";
import AxiosInterceptor from "../../Services/axiosInterceptor";

const changepassword = async (payload) => {
  const { url,body } = payload;
  const result = await AxiosInterceptor.put(url,body);
  return result;
};

export function* changepasswordRequestSaga() {
  yield takeEvery("CHANGE_PASSWORD_REQUEST", function* ({ payload }) {
    try {
      const res = yield call(changepassword, payload);
      if (res) {
        yield put({
          type: actions.CHANGE_PASSWORD_SUCCESS,
          message: res.message,
          status_code:res.status_code
        });}

    } catch (error) {
      yield put({
        type: actions.CHANGE_PASSWORD_ERROR,
        errorMessage: "Something went wrong. Please try after some time.",
      });
    }
  });
}


export default function* rootSaga() {
  yield all([fork(changepasswordRequestSaga)]);
}
