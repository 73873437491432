import actions from "../updateemailverify/action"; // Importing emailActions alongside actions
import Immutable from "seamless-immutable";

const initialState = Immutable({
  isFetching: false,
  successMessagesss: "",
  errorMessagesss: "",
  actionTypesss: "",
  status_codesss: 0,
});

export default function editprofileupdateemailReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case actions.EDIT_PROFILE_EMAIL_SEND_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.EDIT_PROFILE_EMAIL_SEND_SUCCESS:
      return Immutable.merge(state, {
        actionTypesss: "EDIT_PROFILE_EMAIL_SEND_SUCCESS",
        successMessagesss: action.message,
        status_codesss: action.status_code,
      });
    case actions.EDIT_PROFILE_EMAIL_SEND_FAILURE:
      return Immutable.merge(state, {
        isFetching: false,
        actionTypesss: "EDIT_PROFILE_EMAIL_SEND_FAILURE",
        errorMessagesss: action.errorMessage,
      });
    case actions.EDIT_PROFILE_EMAIL_RESEND_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionTypesss: "EDIT_PROFILE_EMAIL_RESEND_REQUEST",
      });
    case actions.EDIT_PROFILE_EMAIL_RESEND_SUCCESS:
      return Immutable.merge(state, {
        actionTypesss: "EDIT_PROFILE_EMAIL_RESEND_SUCCESS",
        successMessagesss: action.message,
        status_codesss: action.status_code,
      });
    case actions.EDIT_PROFILE_EMAIL_RESEND_FAILURE:
      return Immutable.merge(state, {
        isFetching: false,
        actionTypesss: "EDIT_PROFILE_EMAIL_RESEND_FAILURE",
        errorMessagesss: action.errorMessage,
      });
    case actions.EDIT_PROFILE_EMAIL_VERIFY_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.EDIT_PROFILE_EMAIL_VERIFY_SUCCESS:
      return Immutable.merge(state, {
        actionTypesss: "EDIT_PROFILE_EMAIL_VERIFY_SUCCESS",
        successMessagesss: action.message,
        status_codesss: action.status_code,
      });
    case actions.EDIT_PROFILE_EMAIL_VERIFY_FAILURE:
      return Immutable.merge(state, {
        isFetching: false,
        actionTypesss: "EDIT_PROFILE_EMAIL_VERIFY_FAILURE",
        errorMessagesss: action.errorMessage,
      });

    case actions.RESET_ACTIONTYPE:
      return Immutable.merge(state, {
        actionTypesss: "",
      });
    default:
      return state;
  }
}
