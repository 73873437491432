const actions = {
    REGISTER_REQUEST: "REGISTER_REQUEST",
    REGISTER_SUCCESS: "REGISTER_SUCCESS",
    REGISTER_ERROR: "REGISTER_ERROR",
    REGISTER_EXIST:'REGISTER_EXIST',
    RESET_ACTIONTYPE: "RESET_ACTIONTYPE",
    register: (url, body) => ({
        type: actions.REGISTER_REQUEST,
        payload: { url, body }
    }),
    resetActionType: () => {
        return {
          type: actions.RESET_ACTIONTYPE,
        };
      },
}
export default actions;