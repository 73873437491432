import actions from "./actions";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  isFetching: false,
  successMessage: "",
  errorMessage: "",
  actionType: "",
  actionType1:"",
  successMessage1:"",
  status_code: 0,
  data:[],
  callapi:true
});

export default function userCart(state = initialState, action) {
  switch (action.type) {
    case actions.ADD_TO_CART:
      return Immutable.merge(state, {
        isFetching: true,
        callapi:false,
      });
    case actions.ADD_TO_CART_SUCCESS:
      return Immutable.merge(state, {
        actionType1: "ADD_TO_CART_SUCCESS",
        successMessage1: action.message,
        callapi:true,
      });
    case actions.ADD_TO_CART_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType1: "ADD_TO_CART_ERROR",
        errorMessage: action.errorMessage,
      });

    case actions.GET_CART_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.GET_CART_SUCCESS:
      return Immutable.merge(state, {
        actionType: "GET_CART_SUCCESS",
        data:action.data,
      });
    case actions.GET_CART_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: "GET_CART_ERROR",
        errorMessage: action.errorMessage,
      });

    case actions.EDIT_CART_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.EDIT_CART_SUCCESS:
      return Immutable.merge(state, {
        actionType: "EDIT_CART_SUCCESS",
        successMessage: action.message,
      });
    case actions.EDIT_CART_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: "EDIT_CART_ERROR",
        errorMessage: action.errorMessage,
      });

    case actions.DELETE_CART_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.DELETE_CART_SUCCESS:
      return Immutable.merge(state, {
        actionType: "DELETE_CART_SUCCESS",
        successMessage: action.message,
      });
    case actions.DELETE_CART_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: "DELETE_CART_ERROR",
        errorMessage: action.errorMessage,
      });
      case actions.RESET_ACTIONTYPE:
        return Immutable.merge(state, {
          actionType: "",
          successMessage: "",
          errorMessage:"",
          actionType1:"",
          successMessage1:"",
         
        });
    default:
      return state;
  }
}