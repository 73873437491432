import React, { useCallback, useEffect, useState } from "react";
import {
  AuditOutlined,
  HeartOutlined,
  RedoOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Layout, Menu, theme } from "antd";
import { Link, Outlet, useLocation } from "react-router-dom";
const { Sider, Content } = Layout;
const Layout1 = () => {
  const selectedKey = useLocation().pathname;
  console.log(selectedKey);
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const highlight = useCallback(() => {
    if (selectedKey === "/layout") {
      return ["1"];
    } else if (selectedKey === "/layout/address") {
      return ["2"];
    } else if (selectedKey === "/layout/changepassword") {
      return ["3"];
    }
  }, [selectedKey]);

  useEffect(() => {
    highlight();
  }, [highlight]);

  return (
    <Layout style={{height:"100%",display:"flex"}}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="demo-logo-vertical" />
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["1"]}
          selectedKeys={highlight()}
          items={[
            {
              key: "1",
              icon: <UploadOutlined />,
              label: <Link to="/layout">Edit Profile</Link>,
            },
            {
              key: "2",
              icon: <AuditOutlined />,
              label: <Link to="/layout/address">Addresses</Link>,
            },
            {
              key: "3",
              icon: <RedoOutlined />,
              label: <Link to="/layout/changepassword">Change Password</Link>,
            },
          
          ]}
        />
      </Sider>

      <Content
        style={{
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
        <Outlet />
      </Content>
    </Layout>
  );
};
export default Layout1;
