// saga.js
import { takeEvery, put, call, all, fork } from "redux-saga/effects";
import actions from "../forgetpassword/actions";
import AxiosInterceptor from "../../Services/axiosInterceptor";

const forgotPasswordDetails = async (payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.put(url, body);
  return result;
};

const forgotPasswordOTPDetails = async (payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.post(url, body);
  return result;
};

const forgotPasswordOTPDetailsVerify = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.get(url);
  return result;
};

const forgotPasswordResendOTP = async (payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.post(url,body);
  return result;
};

export function* forgotPasswordRequest() {
  yield takeEvery(actions.FORGOT_PASSWORD_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(forgotPasswordDetails, payload);
      if (res) {
        yield put({
          type: actions.FORGOT_PASSWORD_SUCCESS,
          data: res,
          message: res.message,
          status_code: res.status_code,
        });
      } else {
        yield put({
          type: actions.FORGOT_PASSWORD_ERROR,
          errorMessage:
            res.data.message || "Something went wrong. Please try again later.",
        });
      }
    } catch (error) {
      yield put({
        type: actions.FORGOT_PASSWORD_ERROR,
        errorMessage: "Something went wrong. Please try again later.",
      });
    }
  });
}

export function* forgotPasswordOTPRequest() {
  yield takeEvery(actions.FORGOT_PASSWORD_OTP_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(forgotPasswordOTPDetails, payload);
      if (res) {
        yield put({
          type: actions.FORGOT_PASSWORD_OTP_SUCCESS,
          data: res,
          status_code: res.status_code,
          message: res.message,
        });
        localStorage.setItem("forgot_id",res.userData.id)
      } else {
        yield put({
          type: actions.FORGOT_PASSWORD_OTP_ERROR,
          errorMessage:
            res.data.message || "Something went wrong. Please try again later.",
        });
      }
    } catch (error) {
      yield put({
        type: actions.FORGOT_PASSWORD_OTP_ERROR,
        errorMessage: "Something went wrong. Please try again later.",
      });
    }
  });
}

export function* forgotPasswordOTPDetailsVerifyRequest() {
  yield takeEvery("FORGOT_PASSWORD_OTP_VERIFY", function* ({ payload }) {
    try {
      const res = yield call(forgotPasswordOTPDetailsVerify, payload);
      if (res) {
        yield put({
          type: actions.FORGOT_PASSWORD_OTP_VERIFY_SUCCESS,
          data: res,
          status_code: res.status_code,
          message: res.message,
        });
      } else {
        yield put({
          type: actions.FORGOT_PASSWORD_OTP_VERIFY_ERROR,
          errorMessage:
            res.message || "Something went wrong. Please try again later.",
        });
      }
    } catch (error) {
      yield put({
        type: actions.FORGOT_PASSWORD_OTP_VERIFY_ERROR,
        errorMessage: "Something went wrong. Please try again later.",
      });
    }
  });
}

export function* forgotPasswordResendOTPrequest() {
  yield takeEvery(actions.FORGOT_PASSWORD_OTP_RESEND, function* ({ payload }) {
    try {
      const res = yield call(forgotPasswordResendOTP, payload);
      if (res) {
        yield put({
          type: actions.FORGOT_PASSWORD_OTP_RESEND_SUCCESS,
          message: res.message,
          status_code: res.status_code,
        });
      } else {
        yield put({
          type: actions.FORGOT_PASSWORD_OTP_RESEND_ERROR,
          errorMessage:
            res.data.message || "Something went wrong. Please try again later.",
        });
      }
    } catch (error) {
      yield put({
        type: actions.FORGOT_PASSWORD_OTP_RESEND_ERROR,
        errorMessage: "Something went wrong. Please try again later.",
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(forgotPasswordRequest)]);
  yield all([fork(forgotPasswordOTPRequest)]);
  yield all([fork(forgotPasswordOTPDetailsVerifyRequest)]);
  yield all([fork(forgotPasswordResendOTPrequest)]);
}
