import React, { useState, useEffect, useCallback } from "react";
import {
  Form,
  Input,
  Button,
  Radio,
  Upload,
  Modal,
  Card,
  notification,
  Row,
  DatePicker,
  Col,
  message,
} from "antd";
import { UploadOutlined, UserOutlined } from "@ant-design/icons";
import moment from "moment";
import dayjs from "dayjs";
import AxiosInterceptor from "../../Services/axiosInterceptor";
import config from "../../Services/siteConfig";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import actions from "../../Redux/MobileEmailverify/actions";
import action from "../../Redux/Singleuserdetails/actions";
import actionss from "../../Redux/userDetailsupdate/actions";
import actionsss from "../../Redux/updatemobileverify/actions";
import actionssss from "../../Redux/updateemailverify/action";

const EditProfile = () => {
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [imageUrl, setImageUrl] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ismobileModalVisible, setIsmobileModalVisible] = useState(false);
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [dob, setDob] = useState(null);

  var { Singleuserdata } = useSelector(
    (state) => state.SingleuserDetailsReducer
  );
  var { actionTypes, errorMessages, successMessages, status_codes } =
    useSelector((state) => state.authemailmobileReducer);
  var { actionType, successMessage, status_code } = useSelector(
    (state) => state.userDetailsUpdateReducer
  );
  var { actionTypess, successMessagess, status_codess } = useSelector(
    (state) => state.editprofileupdatemobileReducer
  );

  var { actionTypesss, successMessagesss, status_codesss } = useSelector(
    (state) => state.editprofileupdateemailReducer
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getUserId = sessionStorage.getItem("id");
  const getdetails = useCallback(() => {
    dispatch(action.usergetdetails(`${config.getuser}?id=${getUserId}`));
  }, [getUserId, dispatch]);

  useEffect(() => {
    getdetails();
  }, [dispatch, getdetails]);

  useEffect(() => {
    if (
      actionTypesss === "EDIT_PROFILE_EMAIL_SEND_SUCCESS" &&
      status_codesss === 1
    ) {
      notification.success({
        message: successMessagesss,
      });
      navigate("/layout/profileotpverify");
      dispatch(actionssss.resetActionType());
    } else if (
      actionTypesss === "EDIT_PROFILE_EMAIL_SEND_FAILURE" &&
      status_codesss === 0
    ) {
      notification.error({
        message: successMessagesss,
      });
      dispatch(actionsss.resetActionType());
    }
  });
  useEffect(() => {
    if (
      actionTypess === "EDIT_PROFILE_MOBILE_OTP_SEND_SUCCESS" &&
      status_codess === 1
    ) {
      notification.success({
        message: successMessagess,
      });
      navigate("/layout/profileotpverify");
      dispatch(actionsss.resetActionType());
    } else if (
      actionTypess === "EDIT_PROFILE_MOBILE_OTP_SEND_SUCCESS" &&
      status_codess === 0
    ) {
      notification.error({
        message: successMessagess,
      });
      sessionStorage.removeItem("updateone");
      sessionStorage.removeItem("updatenumber");
      dispatch(actionsss.resetActionType());
    }
  });

  useEffect(() => {
    if (actionTypes === "MOBILE_OTP_SEND_SUCCESS" && status_codes === 1) {
      notification.success({
        message: successMessages,
      });
      navigate("/layout/verifyotp");
      dispatch(actions.resetActionType());
    } else if (
      actionTypes === "MOBILE_OTP_SEND_SUCCESS" &&
      status_codes === 0
    ) {
      notification.error({
        message: errorMessages,
      });
      dispatch(actions.resetActionType());
    }
  }, [
    actionTypes,
    status_codes,
    successMessages,
    errorMessages,
    dispatch,
    navigate,
  ]);

  useEffect(() => {
    if (actionTypes === "EMAIL_OTP_SEND_SUCCESS" && status_codes === 1) {
      notification.success({
        message: successMessages,
      });
      navigate("/layout/verifyotp");
      dispatch(actions.resetActionType());
    } else if (actionTypes === "EMAIL_OTP_SEND_SUCCESS" && status_codes === 0) {
      notification.error({
        message: errorMessages,
      });
      dispatch(actions.resetActionType());
    }
  }, [
    actionTypes,
    status_codes,
    successMessages,
    dispatch,
    errorMessages,
    navigate,
  ]);

  useEffect(() => {
    if (actionType === "USER_DETAILS_UPDATE_SUCCESS" && status_code === 1) {
      // notification.success({
      //   message: successMessage,
      //   style: {
      //     width: 300,
      //     fontSize: 1,
      //     height: 100,
      //   },
      // });
      message.success(successMessage);
      window.scroll(0, 0);
      dispatch(actionss.resetActionType());
      getdetails();
    } else if (
      actionType === "USER_DETAILS_UPDATE_SUCCESS" &&
      status_code === 0
    ) {
      notification.error({
        message: successMessage,
      });
      dispatch(actionss.resetActionType());
    }
  }, [actionType, status_code, successMessage, dispatch, getdetails]);

  useEffect(() => {
    if (getdetails) {
      form.setFieldsValue({
        firstName: Singleuserdata?.first_name,
        lastName: Singleuserdata?.last_name,
        email: Singleuserdata?.email,
        mobileNumber: Singleuserdata?.mobile_no,
        dob: Singleuserdata?.dob ? dayjs(new Date(Singleuserdata?.dob)) : null,
        gender: Singleuserdata?.gender,
      });
    }
  }, [Singleuserdata, form, getdetails]);

  const onFinish = async (values) => {
    form.validateFields();
    try {
      const updatePayload = {
        id: Singleuserdata.id,
        user_profile: Singleuserdata?.user_profile,
        first_name: values.firstName.trim(),
        last_name: values.lastName ? values.lastName.trim() : null, // Trim if not empty, otherwise send empty string
        dob: values.dob ? values.dob.format("YYYY-MM-DD") : null,
        email: values.email,
        mobilenumber: values.mobileNumber,
        gender: values.gender,
      };
      console.log(updatePayload);
      dispatch(actionss.updatedetails(config.update, updatePayload));
    } catch (error) {
      console.log(error);
    }
  };
  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };
  const handleUploadChange = (info) => {
    if (info.file.status === "done") {
      const uploadedFile = info.file.originFileObj;
      setImageUrl(uploadedFile);
      console.log(uploadedFile);
    }
  };

  const handleEditImage = () => {
    setIsModalVisible(true);
  };

  const dummyRequest = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
      console.log("Uploaded file information:", file);

      setIsModalVisible(false);
    }, 1000);
    try {
      const fileData = file;
      let formData = new FormData();
      formData.append("image", fileData);
      const resultData = await AxiosInterceptor.post(
        `${config.upload}`,
        formData
      );
      if (resultData.status_code === 1) {
        const updatePayload = {
          id: Singleuserdata.id,
          user_profile: resultData.data.Location,
        };
        dispatch(actionss.updatedetails(config.update, updatePayload));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      notification.error({
        message: "You can only upload JPG/PNG files!",
      });
    }
    return isJpgOrPng;
  };

  const handleMobileVerify = () => {
    localStorage.setItem("two", "2");
    localStorage.setItem("typetwo", JSON.stringify("mobile"));
    const payload = {
      verify_type: "registration",
      type: "mobile",
      user_name: Singleuserdata.email,
      id: Singleuserdata.id,
    };
    console.log(payload);

    dispatch(actions.sendMobileOTP(config.resendotp, payload));
  };

  const handleEmailVerify = () => {
    localStorage.setItem("one", "1");
    localStorage.setItem("typeone", JSON.stringify("email"));
    const payload = {
      verify_type: "registration",
      type: "email",
      user_name: Singleuserdata.email,
      id: Singleuserdata.id,
    };
    console.log(payload);

    dispatch(actions.sendEmailOTP(config.resendotp, payload));
  };

  const handleEditMobile = () => {
    setIsmobileModalVisible(true);
    form1.resetFields();
  };

  const onFinishMobileUpdate = async (values) => {
    if (Singleuserdata.mobile_no === values.mobileNumber) {
      message.error("This number is already linked with an existing account");
    } else {
      const payload = {
        user_name: values.mobileNumber,
        type: "mobile",
        verify_type: "userprofile",
        id: Singleuserdata.id,
      };
      console.log(payload);
      sessionStorage.setItem("updateone", "1");
      sessionStorage.setItem("updatenumber", values.mobileNumber);

      setIsModalVisible(false);
      dispatch(
        actionsss.sendEditProfileMobileOTP(config.forgotpasswordotp, payload)
      );
    }
  };
  const handleEditEmail = () => {
    setIsEmailModalVisible(true);
    form2.resetFields();
  };

  const handleCancelEmail = () => {
    setIsEmailModalVisible(false);
  };

  const handleCancelMobile = () => {
    setIsmobileModalVisible(false);
  };
  const onFinishEmailUpdate = async (values) => {
    const payload = {
      user_name: values.email,
      type: "email",
      verify_type: "userprofile",
      id: Singleuserdata.id,
    };
    console.log(payload);
    sessionStorage.setItem("updatetwo", "2");
    sessionStorage.setItem("updateemail", values.email);

    setIsModalVisible(false);
    dispatch(
      actionssss.sendEditProfileEmail(config.forgotpasswordotp, payload)
    );
  };

  const handleDateChange = (date, dateString) => {
    setDob(dateString);
  };

  const validateDate = () => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    if (age < 13) {
      return Promise.reject(new Error("You must be at least 13 years old."));
    }

    return Promise.resolve();
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#f7f7f7",
      }}
    >
      {" "}
      <Card
        style={{
          width: "600px",
          margin: "20px",

          alignItems: "center",
          backgroundColor: "#f7f7f7",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
        }}
      >
        <div style={{ padding: "20px", width: "100%" }}>
          <div style={{ marginBottom: "20px", textAlign: "center" }}>
            <div
              style={{
                width: "150px",
                height: "150px",
                borderRadius: "50%",
                overflow: "hidden",
                margin: "0 auto",
              }}
            >
              {Singleuserdata && (
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {Singleuserdata.user_profile ? (
                    <img
                      src={Singleuserdata.user_profile}
                      alt="Profile"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <UserOutlined
                        style={{ fontSize: "150px", color: "#ccc" }}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>

            <Upload
              name="avatar"
              customRequest={dummyRequest}
              beforeUpload={beforeUpload}
              accept="image/*"
              showUploadList={false}
            >
              <Button style={{ marginTop: "10px" }} onClick={handleEditImage}>
                Edit Profile Image
              </Button>
            </Upload>
          </div>
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            style={{ width: "100%" }}
          >
            <Form.Item name="user_profile" style={{ display: "none" }}>
              <Input type="hidden" value={imageUrl} />
            </Form.Item>

            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                { required: true, message: "Please enter your first name!" },
                {
                  pattern: /^[a-zA-Z\s]{1,30}$/,
                  message: "Please enter only letters!",
                },
              ]}
            >
              <Input maxLength={30} />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                {
                  pattern: /^[a-zA-Z\s]{1,30}$/,
                  message: "Please enter only letters!",
                  transform: (value) => value.trim(),
                },
              ]}
            >
              <Input maxLength={30} />
            </Form.Item>
            <Form.Item
              label="Date of Birth"
              name="dob"
              rules={[
                {
                  validator: validateDate,
                },
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                onChange={handleDateChange}
                disabledDate={disabledDate}
                format="YYYY-MM-DD"
                placeholder="Select DOB"
              />
            </Form.Item>

            <Form.Item
  colon={false}
  style={{ width: "100%", marginBottom: "16px" }}
>
  {/* Email Section */}
  <Form.Item
    name="email"
    label="Email"
    rules={[
      {
        type: "email",
        message: "Please enter a valid email address",
      },
    ]}
  >
    <Input
      disabled
      style={{ width: "100%" }}
      addonAfter={
        <Button
          type="primary"
          size="small"
          onClick={handleEmailVerify}
          style={{
            display:
              Singleuserdata?.is_email_verified ||
              !Singleuserdata?.email
                ? "none"
                : "inline-block",
          }}
        >
          Verify email
        </Button>
      }
      addonBefore={
        <Button size="small" onClick={handleEditEmail}>
          Edit
        </Button>
      }
    />
  </Form.Item>

  {/* Mobile Number Section */}
  <Form.Item
    label="Mobile Number"
    name="mobileNumber"
    rules={[
      {
        pattern: /^[0-9]{10}$/,
        message: "Please enter a valid 10-digit mobile number",
      },
    ]}
  >
    <Input
      disabled
      style={{ width: "100%" }}
      addonAfter={
        <Button
          type="primary"
          size="small"
          onClick={handleMobileVerify}
          style={{
            display:
              Singleuserdata?.is_mobile_verified ||
              !Singleuserdata?.mobile_no
                ? "none"
                : "inline-block",
          }}
        >
          Verify mobile
        </Button>
      }
      addonBefore={
        <Button size="small" onClick={handleEditMobile}>
          Edit
        </Button>
      }
    />
  </Form.Item>
</Form.Item>


            <Form.Item label="Gender" name="gender">
              <Radio.Group>
                <Radio value="male">Male</Radio>
                <Radio value="female">Female</Radio>
                <Radio value="other">Other</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                Save Details
              </Button>
            </Form.Item>
          </Form>
        </div>

        <Modal
          title="For better security, we will send OTP to your mobile number."
          visible={ismobileModalVisible}
          onCancel={handleCancelMobile}
          footer={null}
          centered
          width={500}
        >
          <div className="mobile-update-modal">
            <Form
              onFinish={onFinishMobileUpdate}
              layout="vertical"
              form={form1}
            >
              <Form.Item
                label="Enter your mobile number"
                name="mobileNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter your mobile number",
                  },
                  {
                    pattern: /^[6-9][0-9]{9}$/,
                    message: "Please enter a valid 10-digit mobile number",
                  },
                ]}
              >
                <Input
                  size="middle"
                  placeholder="Enter mobile number"
                  style={{ width: "100%" }}
                  maxLength={10}
                />
              </Form.Item>
              <Form.Item style={{ textAlign: "center" }}>
                <Button type="primary" htmlType="submit" size="middle">
                  Update Mobile
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Modal>

        <Modal
          title="For better security, we will send OTP to your email address."
          visible={isEmailModalVisible}
          onCancel={handleCancelEmail}
          footer={null}
          centered
          width={500}
        >
          <div className="email-update-modal">
            <Form onFinish={onFinishEmailUpdate} layout="vertical" form={form2}>
              <Form.Item
                label="Enter your email address"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter your email address",
                  },
                  {
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                ]}
              >
                <Input
                  size="middle"
                  placeholder="Enter email address"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item style={{ textAlign: "center" }}>
                <Button type="primary" htmlType="submit" size="middle">
                  Update Email
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </Card>
    </div>
  );
};

export default EditProfile;
