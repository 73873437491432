import React, { useEffect } from "react";
import { Form, Input, Button, Card, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../Redux/forgetpassword/actions";
import config from "../../Services/siteConfig";

const UpdatePassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { successMessage, actionType, status_code } = useSelector(
    (state) => state.forgotPasswordReducer
  );
  useEffect(() => {
    if (actionType === "FORGOT_PASSWORD_SUCCESS" && status_code === 1) {
      notification.success({
        message: successMessage,
      });
      dispatch(actions.resetActionType());
      navigate("/login");
    } else if (actionType === "FORGOT_PASSWORD_SUCCESS" && status_code === 0) {
      notification.success({
        message: successMessage,
      });
      dispatch(actions.resetActionType());
    }
  }, [actionType, successMessage, status_code, dispatch, navigate]);
  const onFinish = (values) => {
    const { confirm_new_password } = values;

    const payload = {
      id:localStorage.getItem("forgot_id"),
      password: confirm_new_password,
      email: localStorage.getItem("ForgotPassword input"),
    };
    console.log("Received values:", payload);
    dispatch(actions.forgotPassword(config.forgotpassword, payload));
  };

  return (
    <div
      style={{
        backgroundColor: "#f0f2f5",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ maxWidth: "500px", width: "100%", padding: "20px" }}>
        <Card
          title="Create New Password"
          style={{
            boxShadow: "0 2px 4px 0 rgba(0,0,0,0.10)",
            borderRadius: "6px",
            border: "1px solid #ddd",
            backgroundColor: "#fff",
          }}
        >
          <Form
            name="change_password_form"
            onFinish={onFinish}
            layout="vertical"
          >
            <Form.Item
              label="New Password"
              name="new_password"
              rules={[
                {
                  required: true,
                  message: "Passwords Required!",
                },
                {
                  validator(_, value) {
                    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!/@#$%^&*()_\-+={}[\]:;"'<>,.?|\\])[A-Za-z\d~`!/@#$%^&*()_\-+={}[\]:;"'<>,.?|\\]+$/;

                    if (value && !passwordRegex.test(value)) {
                      return Promise.reject(
                        new Error(
                          "Password must include uppercase, lowercase, digit, and special character!"
                        )
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input.Password
                placeholder="New Password"
                maxLength={8}
                minLength={8}
              />
            </Form.Item>
            <Form.Item
              label="Password again"
              name="confirm_new_password"
              dependencies={["new_password"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your new password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("new_password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("please make sure your password matches")
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="Confirm new password"
                maxLength={8}
                minLength={8}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                Update Password
              </Button>
            </Form.Item>
          </Form>
          <div style={{ marginTop: "20px", color: "#666", fontSize: "14px" }}>
            <p>Secure password tips:</p>
            <ul>
              <li>
                Use at least 8 characters, a combination of numbers and letters
                is best.
              </li>
              <li>
                Do not use the same password you have used with us previously.
              </li>
              <li>
                Do not use dictionary words, your name, e-mail address, mobile
                phone number, or other personal information that can be easily
                obtained.
              </li>
              <li>
                Do not use the same password for multiple online accounts.
              </li>
            </ul>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default UpdatePassword;
