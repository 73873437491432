import React, { useCallback, useEffect, useState } from "react";
import { Carousel, Card, Rate } from "antd";
import { useNavigate } from "react-router-dom";
import actions from "../../Redux/productcategories/actions";
import { useDispatch, useSelector } from "react-redux";
import config from "../../Services/siteConfig";
import { RightCircleOutlined, ShareAltOutlined } from "@ant-design/icons";
import CategoreyDetails from "../../Commonmobilescreen/categoreydetails";
import "../../Styles/homescreen.css";
import Icon from "@ant-design/icons/lib/components/Icon";

const ProductGrid = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getproductlist = useCallback(() => {
    try {
      dispatch(actions.get_ProductCategories(`${config.productcategories}`));
    } catch (err) {
      console.log(err);
    }
  }, [dispatch]);

  useEffect(() => {
    getproductlist();
  }, [getproductlist]);

  const { data, menudata } = useSelector((state) => {
    return state.PRODUCT_CATEGORIESReducer;
  });

  const navigateToElectronicsProducts = (id) => {
    navigate(`/categorydetails/${id}`);
  };

  const navigateToProductPage = (productId) => {
    navigate(`/product/${productId}`);
    window.scroll(0, 0);
  };

  const renderCategories = (categories) => {
    return categories?.map((category, index) => (
      <React.Fragment key={`category_${category?.id}`}>
        {category?.product_details?.map((product) => (
          <div key={`product_${product?.id}`} style={styles.productContainer}>
            <img
              src={product?.images[0].url}
              alt={product?.name}
              style={{ objectFit: "fill", width: "100%", height: "350px" }}
            />
            <div style={styles.productInfo}>
              <h3>{product?.name}</h3>
              <p>price - {product?.price}</p>
            </div>
          </div>
        ))}
        {renderCategories(category?.subcategories)}
      </React.Fragment>
    ));
  };

  return (
    <div style={{ backgroundColor: "#faf8f7", padding: "20px" }}>
      <Carousel autoplay>
        {data[0]?.product_details?.map((product, index) => (
          <div key={index}>
            <img
              src={product.images?.[0]?.url ?? ""}
              alt={`Slide ${index + 1}`}
              style={{ objectFit: "fill", width: "100%", height: "350px" }}
            />
          </div>
        ))}
      </Carousel>

      {data.slice(1).map((item) => (
        <Card
          title={
            <>
              {item?.name}
              <RightCircleOutlined
                onClick={() => navigateToElectronicsProducts(item?.id)}
                style={{ float: "right" }}
              />
            </>
          }
        >
          <div style={{ ...styles.container, backgroundColor: "white" }}>
            {item?.display_style === "corosol" ? (
              <Carousel autoplay>
                {item?.product_details?.map(
                  (product) =>
                    product?.images.length > 0 && (
                      <div key={`product_${product?.id}`}>
                        <img
                          src={product?.images[0].url}
                          alt={`Product ${product?.id}`}
                          style={{
                            objectFit: "contain",
                            width: "100%",
                            height: "200px",
                          }}
                        />
                      </div>
                    )
                )}
              </Carousel>
            ) : (
              // Otherwise, show product details
              item?.product_details?.map((product) => (
                <div
                  key={`product_${product?.id}`}
                  style={styles.productContainer}
                  onClick={() => navigateToProductPage(product?.id)}
                >
                  <img
                    src={product?.images[0]?.url}
                    alt={product?.name || "Product Image"}
                    style={styles.image}
                  />
                  <div style={styles.productInfo}>
                    <h3>{product?.name || "Product Name"}</h3>
                    <p>
                      Price -{" "}
                      {new Intl.NumberFormat("en-IN", {
                        maximumFractionDigits: 0,
                        style: "currency",
                        currency: "INR",
                      }).format(product?.price || 0)}
                    </p>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Rate
                        disabled
                        allowHalf
                        defaultValue={product?.rating || 0}
                        style={{
                          fontSize: 20,
                          color: "#34C759",
                        }}
                      />

                      <ShareAltOutlined
                        style={{
                          fontSize: 20,
                          marginLeft: "auto",
                          cursor: "pointer",
                          color: "black",
                        }}
                        // onClick={{}}
                      />
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </Card>
      ))}
    </div>
  );
};

const styles = {
  carouselImage: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
    gap: "10px",
    height: "100%", // Example height, adjust as needed
  },

  productContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    border: "1px solid #ccc",
    borderRadius: "10px",
  },
  image: {
    width: "100%",
    height: "250px", // This ensures the image takes up full height of its container
    objectFit: "fill", // Adjust objectFit as per your requirement
    marginBottom: "10px",
    padding: "5px",
    borderRadius: "10px",
  },

  productInfo: {
    textAlign: "center",
    marginBottom: "20px",
  },
  card: {
    width: "100%",
    marginBottom: "20px",
  },
  cardContent: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "20px",
  },

  image2: {
    width: "100%",
    objectFit: "contain",
  },
  gridContainer: {
    display: "flex",
    gap: "10px",
    marginBottom: "20px",
    backgroundColor: "white",
    padding: "20px",
    marginTop: "20px", // Corrected camel case
  },
  gridItem: {
    flex: "1",
    border: "1px solid #ccc",
    borderRadius: "5px",
    marginTop: "20px", // Corrected camel case
  },
  gridImage: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    borderRadius: "5px",
  },
};

export default ProductGrid;
