import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Modal,
  Select,
  Input,
  message,
  Button,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { HeartOutlined } from "@ant-design/icons";
import config from "../Services/siteConfig";
import actions from "../Redux/productcategories/actions";
import action from "../Redux/wishlist/actions";

const CategoreyDetails = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id } = useParams();

  const { Option } = Select;
  const { wishlist, actionType, status_code, successMessage } = useSelector(
    (state) => state.wishlistReducer
  );

  const getproductlist = useCallback(() => {
    try {
      dispatch(actions.get_ProductCategories(`${config.productcategories}`));
    } catch (err) {
      console.log(err);
    }
  }, [dispatch]);

  useEffect(() => {
    getproductlist();
  }, [getproductlist]);

  const getUserId = sessionStorage.getItem("id");
  const getwishlist = useCallback(() => {
    try {
      dispatch(
        action.getWishlist(`${config.addtowishlist}?user_id=${getUserId}`)
      );
    } catch (error) {
      console.log(error);
    }
  }, [getUserId, dispatch]);

  useEffect(() => {
    getwishlist();
  }, [getUserId, getwishlist]);

  const { data } = useSelector((state) => state.PRODUCT_CATEGORIESReducer);
  const navigate = useNavigate();

  const [isWishlisted, setIsWishlisted] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [customWishlistName, setCustomWishlistName] = useState("");
  const [selectedWishlist, setSelectedWishlist] = useState("");
  const [selectedProductId, setSelectedProductId] = useState(""); // State to hold selected product ID
  const category = data.find((category) => category.id === parseInt(id));

  const handleWishlist = (productId) => {
    setSelectedProductId(productId); // Set the selected product ID
    setIsWishlisted(!isWishlisted);
    setIsModalVisible(true);
    resetFormFields();
  };

  const resetFormFields = () => {
    setSelectedWishlist(null);
    setCustomWishlistName("");
    form.resetFields();
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setCustomWishlistName("");
  };

  const handleCustomWishlistNameChange = (e) => {
    setCustomWishlistName(e.target.value);
  };








  const addToWishlist = () => {
    closeModal();

    let payload = {
      user_id: sessionStorage.getItem("id"),
      product_id: String(selectedProductId),
      wishlist_name: selectedWishlist
        ? selectedWishlist.wishlist_name
        : customWishlistName.trim(),
      group_id: selectedWishlist ? selectedWishlist.group_id : null,
    };

    if (
      selectedWishlist &&
      selectedWishlist.wishlist_name.trim() !== customWishlistName.trim()
    ) {
      payload.wishlist_name = customWishlistName.trim();
      payload.group_id = null;
    }

    try {
      dispatch(action.addToWishlist(config.addtowishlist, payload));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (actionType === "ADD_TO_WISHLIST_SUCCESS" && status_code === 1) {
      message.success(successMessage);
      dispatch(action.resetActionType());
    } else if (actionType === "ADD_TO_WISHLIST_SUCCESS" && status_code === 0) {
      message.error(successMessage);
      dispatch(action.resetActionType());
    }
  }, [actionType, status_code, successMessage]);

  const handleSelectChange = (option) => {
    const selectedWishlist = {
      wishlist_name: option.children,
      group_id: option.key,
    };
    setSelectedWishlist(selectedWishlist);
    setCustomWishlistName(option.children);
  };

  const renderCategories = (categories, depth = 0) => {
    return categories?.map((category) => (
      <React.Fragment key={`category_${category.id}`}>
        {category.product_details?.map((product) => (
          <div key={`product_${product.id}`} style={styles.productContainer}>
            <div style={styles.iconContainer}>
              <HeartOutlined
                style={styles.wishlistIcon}
                onClick={() => handleWishlist(product.id)}
              />
            </div>
            <img
              src={product.images[0]?.url}
              alt={product.name}
              style={styles.image}
              onClick={() => navigateToProductPage(product.id, category.id)}
            />
            <div style={styles.productInfo}>
              <h3>{product.name}</h3>
              <p>price -{" "}
                    {new Intl.NumberFormat("en-IN", {
                      maximumFractionDigits: 0,
                      style: "currency",
                      currency: "INR",
                    }).format(product?.price)}</p>
            </div>
          </div>
        ))}
        {category.subcategories?.length > 0 &&
          renderCategories(category.subcategories)}
      </React.Fragment>
    ));
  };

  const navigateToProductPage = (productId, subcategoryId) => {
    navigate(`/product/${productId}?subcategory=${subcategoryId}`);
    window.scroll(0, 0);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Card title={category?.name}>
            <div style={styles.container}>
              {category?.product_details?.map((product) => (
                <div
                  key={`product_${product.id}`}
                  style={styles.productContainer}
                >
                  <div style={styles.iconContainer}>
                    <HeartOutlined
                      style={styles.wishlistIcon}
                      onClick={() => handleWishlist(product.id)}
                    />
                  </div>
                  <img
                    src={product.images[0]?.url}
                    alt={product.name}
                    style={styles.image}
                    onClick={() =>
                      navigateToProductPage(product.id, category.id)
                    }
                  />
                  <div style={styles.productInfo}>
                    <h3>{product.name}</h3>
                    <p>price -{" "}
                    {new Intl.NumberFormat("en-IN", {
                      maximumFractionDigits: 0,
                      style: "currency",
                      currency: "INR",
                    }).format(product?.price)}</p>
                  </div>
                </div>
              ))}
              {renderCategories(category?.subcategories, 0)}
            </div>
          </Card>
        </Col>
      </Row>
      <Modal
          visible={isModalVisible}
          onOk={addToWishlist}
          onCancel={closeModal}
          width={380}
        >
          <Form form={form} layout="vertical">
            <Form.Item label="Select wishlist">
              <Select
                placeholder="Select wishlist"
                style={{ width: "100%" }}
                value={selectedWishlist?.wishlist_name} // assuming selectedWishlist is an object
                onChange={(value, option) => handleSelectChange(option)}
              >
              {wishlist?.data
                ?.reduce((uniqueItems, item) => {
                  if (
                    !uniqueItems.some(
                      (uniqueItems) =>
                        uniqueItems.wishlist_name === item.wishlist_name
                    )
                  ) {
                    uniqueItems.push(item);
                  }
                  return uniqueItems;
                }, [])
                .map(
                  (item) =>
                    item.wishlist_name !== "My Wishlist" && (
                      <Option key={item.group_id} value={item.wishlist_name}>
                        {item.wishlist_name}
                      </Option>
                    )
                )}
                <Option value="My Wishlist">My Wishlist</Option>
              </Select>
            </Form.Item>

            <Form.Item label="Or create a new wishlist">
              <Input
                placeholder="Enter wishlist name"
                value={customWishlistName}
                onChange={handleCustomWishlistNameChange}
              />
            </Form.Item>
          </Form>
        </Modal>
    </>
  );
};

const styles = {
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
    gap: "20px",
  },
  productContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    position: "relative", // Ensure position relative for absolute positioning of icon
  },
  iconContainer: {
    position: "absolute",
    top: "5px",
    left: "5px",
    zIndex: 1,
  },
  wishlistIcon: {
    fontSize: "24px",
    color: "#ff4d4f", // Customize color if needed
    cursor: "pointer",
  },
  image: {
    width: "150px",
    height: "150px",
    objectFit: "contain",
    marginBottom: "10px",
    cursor: "pointer",
  },
  productInfo: {
    textAlign: "center",
  },
};

export default CategoreyDetails;
