import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import actions from "../login/actions";
import AxiosInterceptor from "../../Services/axiosInterceptor";
import { json } from "react-router-dom";

const loginDetails = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.get(url);
  return result;
};

export function* loginRequest() {
  yield takeEvery("LOGIN_REQUEST", function* ({ payload }) {
    try {
      const res = yield call(loginDetails, payload);
      console.log(res);
      if (res.status_code === 1) {
        yield put({
          type: actions.LOGIN_SUCCESS,
          data: res,
          successMessage: res.message,
        });
        sessionStorage.setItem("verify",JSON.stringify(res.is_verified))
        sessionStorage.setItem("id", JSON.stringify(res.userData.id));
        sessionStorage.setItem("Name", JSON.stringify(res.userData.first_name));
        // sessionStorage.setItem("email", res.userData.email);
      
       
      } else {
        yield put({
          type: actions.LOGIN_ERROR,
          errorMessage: res.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.LOGIN_ERROR,
        errorMessage: "invalied Login details",
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loginRequest)]);
}
