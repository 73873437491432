import React from "react";
import ProductDetails from "./product";
import { useDispatch, useSelector } from "react-redux";

const MobileScreen = () => {
  const { singleProduct } = useSelector((state) => state.SingleproductReducer);

  return (
    <div className="mobile-screen">
      <ProductDetails />
    </div>
  );
};

export default MobileScreen;
