
const actions = {
    GET_PRODUCT_CATEGORIES: "GET_PRODUCT_CATEGORIES",
    GET_PRODUCT_CATEGORIES_SUCCESS: "GET_PRODUCT_CATEGORIES_SUCCESS",
    GET_PRODUCT_CATEGORIES_ERROR: "GET_PRODUCT_CATEGORIES_ERROR",
    RESET_ACTIONTYPE:"RESET_ACTIONTYPE",
    GET_MENU_NAMES: "GET_MENU_NAMES",
    GET_MENU_NAMES_SUCCESS: "GET_MENU_NAMES_SUCCESS",
    GET_MENU_NAMES_ERROR: "GET_MENU_NAMES_ERROR",


    GET_MENU_subcategory: "GET_MENU_subcategory",
    GET_MENU_subcategory_SUCCESS: "GET_MENU_subcategory_SUCCESS",
    GET_MENU_subcategory_ERROR: "GET_MENU_subcategory_ERROR",
    RESET_ACTIONTYPE:"RESET_ACTIONTYPE",

    get_ProductCategories: (url) => ({
        type: actions.GET_PRODUCT_CATEGORIES,
        payload: { url},
      }),
      get_menunames: (url) => ({
        type: actions.GET_MENU_NAMES,
        payload: { url},
      }),

      getmenusubcategory: (url) => ({
        type: actions.GET_MENU_subcategory,
        payload: { url},
      }),
      resetActionType: () => {
        return {
          type: actions.RESET_ACTIONTYPE,
        };
      },
}
export default actions;