import actions from "../updatemobileverify/actions";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  isFetching: false,
  successMessagess: "",
  errorMessagess: "",
  actionTypess: "",
  status_codess: 0,
});

export default function editprofileupdatemobileReducer(state = initialState, action) {
  switch (action.type) {
    case actions.EDIT_PROFILE_MOBILE_OTP_SEND_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.EDIT_PROFILE_MOBILE_OTP_SEND_SUCCESS:
      return Immutable.merge(state, {
        actionTypess: "EDIT_PROFILE_MOBILE_OTP_SEND_SUCCESS",
        successMessagess: action.message,
        status_codess: action.status_code,
      });
    case actions.EDIT_PROFILE_MOBILE_OTP_SEND_FAILURE:
      return Immutable.merge(state, {
        isFetching: false,
        actionTypess: "EDIT_PROFILE_MOBILE_OTP_SEND_FAILURE",
        errorMessagess: action.errorMessage,
      });

    case actions.EDIT_PROFILE_MOBILE_OTP_RESEND_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionTypess: "EDIT_PROFILE_MOBILE_OTP_RESEND_REQUEST",
      });
    case actions.EDIT_PROFILE_MOBILE_OTP_RESEND_SUCCESS:
      return Immutable.merge(state, {
        actionTypess: "EDIT_PROFILE_MOBILE_OTP_RESEND_SUCCESS",
        successMessagess: action.message,
        status_codess: action.status_code,
      });
    case actions.EDIT_PROFILE_MOBILE_OTP_RESEND_FAILURE:
      return Immutable.merge(state, {
        isFetching: false,
        actionTypess: "EDIT_PROFILE_MOBILE_OTP_RESEND_FAILURE",
        errorMessagess: action.errorMessage,
      });
    case actions.EDIT_PROFILE_MOBILE_OTP_VERIFY_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.EDIT_PROFILE_MOBILE_OTP_VERIFY_SUCCESS:
      return Immutable.merge(state, {
      
        actionTypess: "EDIT_PROFILE_MOBILE_OTP_VERIFY_SUCCESS",
        successMessagess: action.message,
        status_codess: action.status_code,
      });
    case actions.EDIT_PROFILE_MOBILE_OTP_VERIFY_FAILURE:
      return Immutable.merge(state, {
        isFetching: false,
        actionTypess: "EDIT_PROFILE_MOBILE_OTP_VERIFY_FAILURE",
        errorMessagess: action.errorMessage,
      });

    case actions.RESET_ACTIONTYPE:
      return Immutable.merge(state, {
        actionTypess: "",
      });
    default:
      return state;
  }
}
