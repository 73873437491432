import actions from "../SingleProduct/actions";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  isFetching: false,
  errorMessage: "",
  actionType: "",
  singleProduct: {},
});

export default function SingleproductReducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_SINGLE_PRODUCT:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.GET_SINGLE_PRODUCT_SUCCESS:
      return Immutable.merge(state, {
        actionType: "GET_SINGLE_PRODUCT_SUCCESS",
        singleProduct: action.data,
      });
    case actions.GET_SINGLE_PRODUCT_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: "GET_SINGLE_PRODUCT_ERROR",
        errorMessage: action.errorMessage,
      });
    case actions.RESET_ACTIONTYPE:
      return Immutable.merge(state, {
        actionType: "",
        errorMessage: "",
      });
    default:
      return state;
  }
}
