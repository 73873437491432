import React from 'react';
import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';
const OrderConfirmation = ({ order }) => {
  return (
    <div className="order-confirmation-container">
  <Result
    status="success"
    title="Order Placed Successfully"
   
    extra={[
   <Link to="/">  <Button type="primary" key="console">
       back to home screen
      </Button>,
      <Button key="buy">view product</Button>,
      </Link> 
    ]}

  />
    </div>
  );
};

export default OrderConfirmation;