// reducers.js

import actions from "./actions";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  isFetching: false,
  successMessage: "",
  errorMessage: "",
  actionType:"",
  status_code:0

});

export default function otpReducer(state = initialState, action) {
  switch (action.type) {
    case actions.OTP_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        successMessage: "",
        errorMessage: "",
      });
    case actions.OTP_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        successMessage: action.message,
        actionType:'OTP_SUCCESS',
        status_code:action.status_code
      });
      case actions.RESET_ACTIONTYPE:
      return Immutable.merge(state, {
        actionType: "",
      });
    case actions.OTP_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        errorMessage: action.errorMessage,
         actionType:'OTP_ERROR'
      });
  
    default:
      return state;
  }
}
