import React, { useEffect,useState } from "react";
import { Input, Form, Button, Card,notification } from "antd";
import { useDispatch,useSelector} from "react-redux";
import actions from "../../Redux/Otp/actions"
import config from "../../Services/siteConfig"
import { useNavigate } from "react-router-dom";
import actions1 from "../../Redux/resendotp/actions"

const OTPVerificationScreen = () => {

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [timer, setTimer] = useState(30);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {successMessage,actionType,status_code } = useSelector((state) => {
    return state.otpReducer;
  });
  const {successMessage1,actionType1 } = useSelector((state) => {
    return state.resendReducer;
  });
  const resendata={
    id:localStorage.getItem("id"),
    verify_type:"registration"
  }
  useEffect(() => {
    if (isButtonDisabled) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 1) {
            clearInterval(interval);
            setIsButtonDisabled(false);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isButtonDisabled]);

  const handleResend=()=>{
    setIsButtonDisabled(true);
    setTimer(30);
    try{
      dispatch(actions1.resend(config.resendotp,resendata))

       }
       catch(err){
         console.log(err)
       }
  }

  const onFinish = (values) => {
    const verify_type="registration"
    const id=localStorage.getItem("id")
    console.log('Success:', values);
    try{
      dispatch(actions.otpverification(`${config.otp}?id=${id}&verify_type=${verify_type}&otp=${values.otp}`))
       }
       catch(err){
         console.log(err)
       }
  };
  const openNotificationWithIcon = (type, message) => {
    return notification[type]({
      window: window.scroll(0, 0),
      message: message,
      duration: 3,
    });
  };
  useEffect(() => {
    
    if (actionType1 === "RESEND_OTP_SUCCESS" ) {
      openNotificationWithIcon("success", successMessage1);
      dispatch(actions1.resetActionType());
     
    }
    else if (actionType === "OTP_SUCCESS" &&status_code===1) {
      openNotificationWithIcon("success", successMessage);
      dispatch(actions.resetActionType());
      navigate('/login')
     
    }
    else if (actionType === "OTP_SUCCESS" && status_code===0) {
      openNotificationWithIcon("success", successMessage);
      dispatch(actions.resetActionType());     
    }

  }, [actionType,navigate,successMessage,dispatch,successMessage1,actionType1,status_code]);


  return (
    <div style={{ minHeight: '100vh', display: "flex", alignItems: 'center', justifyContent: 'center' }}>
      <Card style={{ width: "450px", borderRadius: "8px", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2),", }}>
        <div style={{ padding: "20px",display: "flex",flexDirection:'column', alignItems: 'center', justifyContent: 'center' }}>
          <h2 style={{ marginTop: "0" }}>OTP Verification </h2>
          <p style={{ fontSize: "16px", marginBottom: "20px" }}>
            We’ve sent a One Time Password (OTP). Please
            enter it to complete verification.
          </p>
          <Form onFinish={onFinish}>
            <Form.Item
      name="otp"
      rules={[
        {
          required: true,
          message: 'Please Enter OTP!',
        },
      ]}
    >
     <Input.OTP length={6} />
    </Form.Item> 
            <Form.Item>
              <Button type="primary" htmlType="submit">Continue</Button>
              <Button style={{ marginLeft: '10px'}} onClick={handleResend} disabled={isButtonDisabled}>{isButtonDisabled ? `Resend OTP in ${timer}s` : "Resend OTP"}</Button>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default OTPVerificationScreen;
