const actions = {
  GET_SINGLE_PRODUCT: "GET_SINGLE_PRODUCT",
  GET_SINGLE_PRODUCT_SUCCESS: "GET_SINGLE_PRODUCT_SUCCESS",
  GET_SINGLE_PRODUCT_ERROR: "GET_SINGLE_PRODUCT_ERROR",

  RESET_ACTIONTYPE: "RESET_ACTIONTYPE",

  get_SingleProduct: (url) => ({
    type: actions.GET_SINGLE_PRODUCT,
    payload: { url },
  }),

  resetActionType: () => ({
    type: actions.RESET_ACTIONTYPE,
  }),
};

export default actions;
