import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import actions from "./actions";
import AxiosInterceptor from "../../Services/axiosInterceptor";

const register = async (payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.post(url, body);
  return result;
};
export function* registerRequest() {
  yield takeEvery("REGISTER_REQUEST", function* ({ payload }) {
    try {
      const res = yield call(register, payload);
      if (res) {
        yield put({
          type: actions.REGISTER_SUCCESS,
          message:res.message,
          registrationdata: res,
        });
      }
 else {
        yield put({
          type: actions.REGISTER_ERROR,
          errorMessage: "Something went wrong. Please try after some time.",
        });
      }
    } catch (error) {
      yield put({
        type: actions.REGISTER_ERROR,
        errorMessage: "Something went wrong. Please try after some time.",
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(registerRequest)]);
}
