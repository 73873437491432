const actions = {
    // Actions for sending OTP
    EDIT_PROFILE_MOBILE_OTP_SEND_REQUEST: "EDIT_PROFILE_MOBILE_OTP_SEND_REQUEST",
    EDIT_PROFILE_MOBILE_OTP_SEND_SUCCESS: "EDIT_PROFILE_MOBILE_OTP_SEND_SUCCESS",
    EDIT_PROFILE_MOBILE_OTP_SEND_FAILURE: "EDIT_PROFILE_MOBILE_OTP_SEND_FAILURE",
  
    // Actions for resending OTP
    EDIT_PROFILE_MOBILE_OTP_RESEND_REQUEST: "EDIT_PROFILE_MOBILE_OTP_RESEND_REQUEST",
    EDIT_PROFILE_MOBILE_OTP_RESEND_SUCCESS: "EDIT_PROFILE_MOBILE_OTP_RESEND_SUCCESS",
    EDIT_PROFILE_MOBILE_OTP_RESEND_FAILURE: "EDIT_PROFILE_MOBILE_OTP_RESEND_FAILURE",
  
    // Actions for verifying OTP
    EDIT_PROFILE_MOBILE_OTP_VERIFY_REQUEST: "EDIT_PROFILE_MOBILE_OTP_VERIFY_REQUEST",
    EDIT_PROFILE_MOBILE_OTP_VERIFY_SUCCESS: "EDIT_PROFILE_MOBILE_OTP_VERIFY_SUCCESS",
    EDIT_PROFILE_MOBILE_OTP_VERIFY_FAILURE: "EDIT_PROFILE_MOBILE_OTP_VERIFY_FAILURE",
  
    RESET_ACTIONTYPE: "RESET_ACTIONTYPE",
  
    // Action creator for sending OTP
    sendEditProfileMobileOTP: (url,body) => ({
      type: actions.EDIT_PROFILE_MOBILE_OTP_SEND_REQUEST,
      payload: { url,body },
    }),
  
    // Action creator for resending OTP
    resendEditProfileMobileOTP: (url,body) => ({
      type: actions.EDIT_PROFILE_MOBILE_OTP_RESEND_REQUEST,
      payload: { url,body },
    }),
  
    // Action creator for verifying OTP
    verifyEditProfileMobileOTP: (url) => ({
      type: actions.EDIT_PROFILE_MOBILE_OTP_VERIFY_REQUEST,
      payload: { url },
    }),
  
    resetActionType: () => {
      return {
        type: actions.RESET_ACTIONTYPE,
      };
    },
  };
  
  export default actions;
  