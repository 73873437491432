import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { notification } from "antd";
import actions from "../../Redux/LoginOtpverify/actions";
import config from "../../Services/siteConfig";
import action from "../../Redux/MobileEmailverify/actions";

const UserVerify = () => {
  const [otp, setOTP] = useState("");
  const [error, setError] = useState("");

  const [timer, setTimer] = useState(30);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const { status_code, actionType, successMessage } = useSelector(
    (state) => state.verifyOTPRequest
  );
  var { actionTypes, successMessages, status_codes } = useSelector(
    (state) => state.authemailmobileReducer
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (actionTypes === "EMAIL_OTP_RESEND_REQUEST" && status_codes === 1) {
      notification.success({
        message: "OTP sent successfully",
      });
      navigate("/layout/verifyotp");
    } else if (
      actionTypes === "EMAIL_OTP_RESEND_REQUEST" &&
      status_codes === 0
    ) {
      notification.success({
        message: successMessages,
      });
    }
  }, [actionTypes, status_codes]);

  useEffect(() => {
    if (actionTypes === "MOBILE_OTP_RESEND_SUCCESS" && status_codes === 1) {
      notification.success({
        message: "OTP sent successfully",
      });
      navigate("/layout/verifyotp");
    } else if (
      actionTypes === "MOBILE_OTP_RESEND_SUCCESS" &&
      status_codes === 0
    ) {
      notification.success({
        message: successMessages,
      });
    }
  }, [actionTypes, status_codes]);

  useEffect(() => {
    if (actionTypes === "EMAIL_OTP_VERIFY_ERROR" && status_codes === 1) {
      notification.success({
        message: successMessages,
      });
      dispatch(actions.resetActionType());
      localStorage.clear();
      navigate("/layout");
    } else if (actionTypes === "EMAIL_OTP_VERIFY_ERROR" && status_codes === 0) {
      notification.success({
        message: successMessages,
      });
     
      dispatch(actions.resetActionType());
    }
  }, [actionTypes, successMessages, status_codes]);

  useEffect(() => {
    if (actionTypes === "MOBILE_OTP_VERIFY_ERROR" && status_codes === 1) {
      notification.success({
        message: successMessages,
      });
      dispatch(actions.resetActionType());
      localStorage.clear();

      navigate("/layout");
    } else if (
      actionTypes === "MOBILE_OTP_VERIFY_ERROR" &&
      status_codes === 0
    ) {
      notification.success({
        message: successMessages,
      });
      dispatch(actions.resetActionType());
    }
  }, [actionTypes, successMessages, status_codes]);

  useEffect(() => {
    if (actionType === "LOGIN_OTP_VERIFY_SUCCESS" && status_code === 1) {
      notification.success({
        message: successMessage,
      });
      dispatch(actions.resetActionType());
      sessionStorage.clear();
      navigate("/login");
    } else if (actionType === "LOGIN_OTP_VERIFY_SUCCESS" && status_code === 0) {
      notification.success({
        message: successMessage,
      });
      dispatch(actions.resetActionType());
    }
  }, [actionType, successMessage, status_code, dispatch, navigate]);

  useEffect(() => {
    if (actionType === "RESEND_LOGIN_OTP_SUCCESS" && status_code === 1) {
      notification.success({
        message: "Resend Otp Send Successfully ",
      });
      navigate("/otpverification");
      dispatch(actions.resetActionType());
    }
  }, [actionType, successMessage, status_code, dispatch, navigate]);

  useEffect(() => {
    if (isButtonDisabled) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 1) {
            clearInterval(interval);
            setIsButtonDisabled(false);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isButtonDisabled]);

  const handleChange = (e) => {
    setOTP(e.target.value);
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (localStorage.getItem("one") === "1") {
      const payload = {
        id: sessionStorage.getItem("id"),
        otp: otp,
        verify_type: "registration",
        // user_name: sessionStorage.getItem("email"),
        type: localStorage.getItem("typeone"),
      };
      dispatch(
        action.verifyEmailOTP(
          `${config.forgotpasswordotpveify}?verify_type=registration&user_name=${payload.user_name}&otp=${payload.otp}&type=${payload.type}&id=${payload.id}`
        )
      );
    } else if (localStorage.getItem("two") === "2") {
      const payload = {
        id: sessionStorage.getItem("id"),
        otp: otp,
        verify_type: "registration",
        // user_name: sessionStorage.getItem("email"),
        type: localStorage.getItem("typetwo"),
      };
      dispatch(
        action.verifyMobileOTP(
          `${config.forgotpasswordotpveify}?verify_type=registration&user_name=${payload.user_name}&otp=${payload.otp}&type=${payload.type}&id=${payload.id}`
        )
      );
    } else {
      const payload = {
        id: sessionStorage.getItem("id"),
        otp: otp,
        verify_type: "registration",
        user_name: sessionStorage.getItem("email"),
        type: sessionStorage.getItem("type"),
      };
      dispatch(
        actions.loginOTPVerify(
          `${config.forgotpasswordotpveify}?verify_type=registration&user_name=${payload.user_name}&otp=${payload.otp}&type=${payload.type}&id=${payload.id}`
        )
      );
    }
  };

  const handleResendOTP = () => {
    setIsButtonDisabled(true);
    setTimer(30);

    if (localStorage.getItem("one") === "1") {
      const payload = {
        id: sessionStorage.getItem("id"),
        verify_type: "registration",
        type: "email",
      };
      dispatch(action.resendEmailOTP(config.resendotp, payload));
    } else if (localStorage.getItem("two") === "2") {
      const payload = {
        id: sessionStorage.getItem("id"),
        verify_type: "registration",
        type: "mobile",
      };
      dispatch(action.resendMobileOTP(config.resendotp, payload));
    } else {
      const payload = {
        id: sessionStorage.getItem("id"),
        verify_type: "registration",
      };
      dispatch(actions.Resend_LoginOTP(config.resendotp, payload));
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div
        style={{
          width: "380px",
          height: "380px",
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            padding: "20px",
            backgroundColor: "white",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <h2 style={{ marginTop: "0" }}>Authentication required</h2>
          <p style={{ fontSize: "16px", marginBottom: "20px" }}>
            We’ve sent a One Time Password (OTP) to the mobile number. Please
            enter it to complete verification.
          </p>

          <form onSubmit={handleSubmit}>
            <input
              style={{
                width: "calc(100% - 22px)",
                padding: "10px",
                marginBottom: "10px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
              type="text"
              value={otp}
              onChange={handleChange}
              maxLength="6"
              placeholder="Enter OTP"
            />
            {error && <p style={{ color: "red", marginTop: "5px" }}>{error}</p>}
            <button
              style={{
                width: "100%",
                padding: "10px",
                backgroundColor: "#f0c14b",
                border: "none",
                borderRadius: "4px",
                color: "#111",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              type="submit"
            >
              Continue
            </button>
            <button
              style={{
                width: "100%",
                padding: "10px",
                marginTop: "10px",
                backgroundColor: "transparent",
                cursor: isButtonDisabled ? "not-allowed" : "pointer",
                color: isButtonDisabled ? "grey" : "blue",
              }}
              type="button"
              onClick={handleResendOTP}
              disabled={isButtonDisabled}
            >
              {isButtonDisabled ? `Resend OTP in ${timer}s` : "Resend OTP"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserVerify;
