const actions = {
ADD_TO_CART: "ADD_TO_CART",
ADD_TO_CART_SUCCESS: "ADD_TO_CART_SUCCESS",
ADD_TO_CART_ERROR: "ADD_TO_CART_ERROR",
GET_CART_REQUEST: "GET_CART_REQUEST",
GET_CART_SUCCESS: "GET_CART_SUCCESS",
GET_CART_ERROR: "GET_CART_ERROR",
EDIT_CART_REQUEST: "EDIT_CART_REQUEST",
EDIT_CART_SUCCESS: "EDIT_CART_SUCCESS",
EDIT_CART_ERROR: "EDIT_CART_ERROR",
DELETE_CART_REQUEST: "DELETE_CART_REQUEST",
DELETE_CART_SUCCESS: "DELETE_CART_SUCCESS",
DELETE_CART_ERROR: "DELETE_CART_ERROR",
RESET_ACTIONTYPE: "RESET_ACTIONTYPE",

 addToCart: (url, body) => ({
  type: actions.ADD_TO_CART,
  payload: { url, body },
}),
getCartItem: (url) => ({
  type: actions.GET_CART_REQUEST,
  payload: { url}
}),
deleteCartItem: (url) => ({
  type: actions.DELETE_CART_REQUEST,
  payload: { url}
}),
editCartItem:(url,body)=>({
  type: actions.EDIT_CART_REQUEST,
  payload: { url, body },
}),
resetActionType: () => {
  return {
    type: actions.RESET_ACTIONTYPE,
  };
},
}
export default actions;