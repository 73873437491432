const actions = {

  
  
  
    SINGLE_USER_DETAILS_REQUEST: "SINGLE_USER_DETAILS_REQUEST",
    SINGLE_USER_DETAILS_SUCCESS: "SINGLE_USER_DETAILS_SUCCESS",
    SINGLE_USER_DETAILS_FAILURE: "SINGLE_USER_DETAILS_FAILURE",
  
    RESET_ACTIONTYPE: "RESET_ACTIONTYPE",
  
  
 
    usergetdetails: (url) => ({
      type: actions.SINGLE_USER_DETAILS_REQUEST,
      payload: { url },
    }),
  
    resetActionType: () => {
      return {
        type: actions.RESET_ACTIONTYPE,
      };
    },
  };
  
  export default actions;
  