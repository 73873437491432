import actions from "../userDetailsupdate/actions"; // Assuming this is the correct path
import Immutable from "seamless-immutable";

const initialState = Immutable({
  isUpdating: false,
  successMessage: "",
  errorMessage: "",
  actionType: "",

  status_code: 0,
});

export default function userDetailsUpdateReducer(state = initialState, action) {
  switch (action.type) {
    case actions.USER_DETAILS_UPDATE_REQUEST:
      return Immutable.merge(state, {
        isUpdating: true,
        actionType: "USER_DETAILS_UPDATE_REQUEST",
      });
    case actions.USER_DETAILS_UPDATE_SUCCESS:
      return Immutable.merge(state, {
        isUpdating: false,
        actionType: "USER_DETAILS_UPDATE_SUCCESS",
        successMessage: action.successMessage,
        status_code: action.status_code,
      });
    case actions.USER_DETAILS_UPDATE_FAILURE:
      return Immutable.merge(state, {
        isUpdating: false,
        actionType: "USER_DETAILS_UPDATE_FAILURE",
        errorMessage: action.errorMessage,
      });

    case actions.RESET_ACTIONTYPE:
      return Immutable.merge(state, {
        actionType: "",
        status_code:0,
      });
    default:
      return state;
  }
}
