import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import actions from "../wishlist/actions";
import AxiosInterceptor from "../../Services/axiosInterceptor";

const Addtowishlist = async (payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.post(url, body);
  return result;
};

const deleteFromWishlistAPI = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.delete(url);
  return result;
};

const updateWishlistAPI = async (payload) => {
  const { url, body } =  payload ;
  const result = await AxiosInterceptor.put(url, body);
  return result;
};

const getWishlistAPI = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.get(url);
  return result;
};

export function* WishlistSaga() {
  yield takeEvery(actions.ADD_TO_WISHLIST, function* ({ payload }) {
    try {
      const res = yield call(Addtowishlist, payload);
      if (res) {
        yield put({
          type: actions.ADD_TO_WISHLIST_SUCCESS,
          data: res,
          message: res.message,
          status_code: res.status_code,
        });
      }
    } catch (error) {
      yield put({
        type: actions.ADD_TO_WISHLIST_ERROR,
      });
    }
  });
}

export function* deleteWishlistSaga() {
  yield takeEvery(actions.DELETE_FROM_WISHLIST, function* ({ payload }) {
    try {
      const res = yield call(deleteFromWishlistAPI, payload);
      if (res) {
        yield put({
          type: actions.DELETE_FROM_WISHLIST_SUCCESS,
          data: res,
          message: res.message,
          status_code: res.status_code,
        });
      }
    } catch (error) {
      yield put({
        type: actions.DELETE_FROM_WISHLIST_ERROR,
        errorMessage: "Failed to fetch product details.",
      });
    }
  });
}

export function* updateWishlistsaga() {
  yield takeEvery(actions.UPDATE_WISHLIST, function* ({ payload }) {
    try {
      const res = yield call(updateWishlistAPI, payload);
      if (res) {
        yield put({
          type: actions.UPDATE_WISHLIST_SUCCESS,
          status_code: res.status_code,
          message: res.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPDATE_WISHLIST_ERROR,
        errorMessage: "Failed to fetch product details.",
      });
    }
  });
}

export function* getWishlistsaga() {
  yield takeEvery(actions.GET_WISHLIST, function* ({ payload }) {
    try {
      const res = yield call(getWishlistAPI, payload);
      if (res) {
        yield put({
          type: actions.GET_WISHLIST_SUCCESS,
          data: res,
        });
      }
    } catch (error) {
      yield put({
        type: actions.GET_WISHLIST_ERROR,
        errorMessage: "Failed to fetch product details.",
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(WishlistSaga)]);
  yield all([fork(getWishlistsaga)]);
  yield all([fork(updateWishlistsaga)]);
  yield all([fork(deleteWishlistSaga)]);
}
