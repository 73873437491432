import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import actions from "../SingleProduct/actions";
import AxiosInterceptor from "../../Services/axiosInterceptor";

const getSingleProduct = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.get(url);
  return result;
};

export function* getSingleProductSaga() {
  yield takeEvery(actions.GET_SINGLE_PRODUCT, function* ({ payload }) {
    try {
      const res = yield call(getSingleProduct, payload);
      if (res) {
        yield put({
          type: actions.GET_SINGLE_PRODUCT_SUCCESS,
          data: res.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.GET_SINGLE_PRODUCT_ERROR,
        errorMessage: "Failed to fetch product details.",
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getSingleProductSaga)]);
}
