import React, { useCallback, useEffect } from "react";
import { Layout } from "antd";
import "../Styles/layout.css";

import Header from "../Components/Header/header";
import Footer from "../Components/Footer/footer";
import "../Styles/header.css";
import { Outlet } from "react-router-dom";
import actions from "./../Redux/productcategories/actions";
import { useDispatch} from "react-redux";
import config from "../Services/siteConfig";

const {Content } = Layout;
const CustomLayout = ({ children }) => {
  const dispatch = useDispatch();
  const getmenus = useCallback(() => {
    try {
      dispatch(actions.get_menunames(`${config.getcategory}`));
    } catch (err) {
      console.log(err);
    }
  }, [dispatch]);

  useEffect(() => { 
    getmenus();
  }, [getmenus]);
  return (
    <Layout style={{ display: "flex", height: "auto", width: "100%" }}>
      <Header />
      <Content>
        <Outlet />
      </Content>
     <Footer/>
    </Layout>
  );
};

export default CustomLayout;
