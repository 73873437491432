const actions = {
    // Mobile OTP Verification
    MOBILE_OTP_SEND_REQUEST: "MOBILE_OTP_SEND_REQUEST",
    MOBILE_OTP_SEND_SUCCESS: "MOBILE_OTP_SEND_SUCCESS",
    MOBILE_OTP_SEND_ERROR: "MOBILE_OTP_SEND_ERROR",
  
    MOBILE_OTP_RESEND_REQUEST: "MOBILE_OTP_RESEND_REQUEST",
    MOBILE_OTP_RESEND_SUCCESS: "MOBILE_OTP_RESEND_SUCCESS",
    MOBILE_OTP_RESEND_ERROR: "MOBILE_OTP_RESEND_ERROR",
  
    MOBILE_OTP_VERIFY_REQUEST: "MOBILE_OTP_VERIFY_REQUEST",
    MOBILE_OTP_VERIFY_SUCCESS: "MOBILE_OTP_VERIFY_SUCCESS",
    MOBILE_OTP_VERIFY_ERROR: "MOBILE_OTP_VERIFY_ERROR",
  
    // Email OTP Verification
    EMAIL_OTP_SEND_REQUEST: "EMAIL_OTP_SEND_REQUEST",
    EMAIL_OTP_SEND_SUCCESS: "EMAIL_OTP_SEND_SUCCESS",
    EMAIL_OTP_SEND_ERROR: "EMAIL_OTP_SEND_ERROR",
  
    EMAIL_OTP_RESEND_REQUEST: "EMAIL_OTP_RESEND_REQUEST",
    EMAIL_OTP_RESEND_SUCCESS: "EMAIL_OTP_RESEND_SUCCESS",
    EMAIL_OTP_RESEND_ERROR: "EMAIL_OTP_RESEND_ERROR",
  
    EMAIL_OTP_VERIFY_REQUEST: "EMAIL_OTP_VERIFY_REQUEST",
    EMAIL_OTP_VERIFY_SUCCESS: "EMAIL_OTP_VERIFY_SUCCESS",
    EMAIL_OTP_VERIFY_ERROR: "EMAIL_OTP_VERIFY_ERROR",



    RESET_ACTIONTYPE: "RESET_ACTIONTYPE",
  
    // Action Creators for Mobile OTP Verification
    sendMobileOTP: (url,body) => ({
      type: actions.MOBILE_OTP_SEND_REQUEST,
      payload: { url,body },
    }),
  
    resendMobileOTP: (url,body) => ({
      type: actions.MOBILE_OTP_RESEND_REQUEST,
      payload: { url,body },
    }),
  
    verifyMobileOTP: (url) => ({
      type: actions.MOBILE_OTP_VERIFY_REQUEST,
      payload: { url},
    }),
  
    // Action Creators for Email OTP Verification
    sendEmailOTP: (url,body) => ({
      type: actions.EMAIL_OTP_SEND_REQUEST,
      payload: { url,body },
    }),
  
    resendEmailOTP: (url,body) => ({
      type: actions.EMAIL_OTP_RESEND_REQUEST,
      payload: { url,body },
    }),
  
    verifyEmailOTP: (url) => ({
      type: actions.EMAIL_OTP_VERIFY_REQUEST,
      payload: { url},
    }),



  resetActionType: () => {
    return {
      type: actions.RESET_ACTIONTYPE,
    };
  },
  };
  
  export default actions;
  