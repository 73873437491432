const actions = {
  // Actions for sending email
  EDIT_PROFILE_EMAIL_SEND_REQUEST: "EDIT_PROFILE_EMAIL_SEND_REQUEST",
  EDIT_PROFILE_EMAIL_SEND_SUCCESS: "EDIT_PROFILE_EMAIL_SEND_SUCCESS",
  EDIT_PROFILE_EMAIL_SEND_FAILURE: "EDIT_PROFILE_EMAIL_SEND_FAILURE",

  // Actions for resending email
  EDIT_PROFILE_EMAIL_RESEND_REQUEST: "EDIT_PROFILE_EMAIL_RESEND_REQUEST",
  EDIT_PROFILE_EMAIL_RESEND_SUCCESS: "EDIT_PROFILE_EMAIL_RESEND_SUCCESS",
  EDIT_PROFILE_EMAIL_RESEND_FAILURE: "EDIT_PROFILE_EMAIL_RESEND_FAILURE",

  // Actions for verifying email
  EDIT_PROFILE_EMAIL_VERIFY_REQUEST: "EDIT_PROFILE_EMAIL_VERIFY_REQUEST",
  EDIT_PROFILE_EMAIL_VERIFY_SUCCESS: "EDIT_PROFILE_EMAIL_VERIFY_SUCCESS",
  EDIT_PROFILE_EMAIL_VERIFY_FAILURE: "EDIT_PROFILE_EMAIL_VERIFY_FAILURE",

  RESET_ACTIONTYPE: "RESET_ACTIONTYPE",

  // Action creator for sending email
  sendEditProfileEmail: (url, body) => ({
    type: actions.EDIT_PROFILE_EMAIL_SEND_REQUEST,
    payload: { url, body },
  }),

  // Action creator for resending email
  resendEditProfileEmail: (url, body) => ({
    type: actions.EDIT_PROFILE_EMAIL_RESEND_REQUEST,
    payload: { url, body },
  }),

  // Action creator for verifying email
  verifyEditProfileEmail: (url) => ({
    type: actions.EDIT_PROFILE_EMAIL_VERIFY_REQUEST,
    payload: { url },
  }),

  resetActionType: () => {
    return {
      type: actions.RESET_ACTIONTYPE,
    };
  },
};

export default actions;
