import { takeEvery, put, call, all, fork } from "redux-saga/effects";
import actions from "../updateemailverify/action";
import AxiosInterceptor from "../../Services/axiosInterceptor";

const editemailOtpSend = async (payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.post(url, body);
  return result;
};

const editemailOtpresend = async (payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.post(url, body);
  return result;
};

const editemailOtpVerify = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.get(url);
  return result;
};

export function* editemailOTPSendRequest() {
  yield takeEvery(
    actions.EDIT_PROFILE_EMAIL_SEND_REQUEST,
    function* ({ payload }) {
      try {
        const res = yield call(editemailOtpSend, payload);
        if (res) {
          yield put({
            type: actions.EDIT_PROFILE_EMAIL_SEND_SUCCESS,
            status_code: res.status_code,
            message: res.message,
          });
        } else {
          yield put({
            type: actions.EDIT_PROFILE_EMAIL_SEND_FAILURE,
            errorMessage: res.data.message,
          });
        }
      } catch (error) {
        yield put({
          type: actions.EDIT_PROFILE_EMAIL_SEND_FAILURE,
          errorMessage: "Something went wrong. Please try again later.",
        });
      }
    }
  );
}

export function* editemailOTPResendRequest() {
  yield takeEvery(
    actions.EDIT_PROFILE_EMAIL_RESEND_REQUEST,
    function* ({ payload }) {
      try {
        const res = yield call(editemailOtpresend, payload);
        if (res) {
          yield put({
            type: actions.EDIT_PROFILE_EMAIL_RESEND_SUCCESS,
            data: res,
            status_code: res.status_code,
            message: res.message,
          });
        } else {
          yield put({
            type: actions.EDIT_PROFILE_EMAIL_RESEND_FAILURE,
            errorMessage: res.data.message,
          });
        }
      } catch (error) {
        yield put({
          type: actions.EDIT_PROFILE_EMAIL_RESEND_FAILURE,
          errorMessage: "Something went wrong. Please try again later.",
        });
      }
    }
  );
}

export function* editemailOTPVerifyRequest() {
  yield takeEvery(
    actions.EDIT_PROFILE_EMAIL_VERIFY_REQUEST,
    function* ({ payload }) {
      try {
        const res = yield call(editemailOtpVerify, payload);
        if (res) {
          yield put({
            type: actions.EDIT_PROFILE_EMAIL_VERIFY_SUCCESS,
            data: res,
            status_code: res.status_code,
            message: res.message,
          });
        } else {
          yield put({
            type: actions.EDIT_PROFILE_EMAIL_VERIFY_FAILURE,
            errorMessage: res.message,
            status_code: res.status_code,
          });
        }
      } catch (error) {
        yield put({
          type: actions.EDIT_PROFILE_EMAIL_VERIFY_FAILURE,
          errorMessage: "Something went wrong. Please try again later.",
        });
      }
    }
  );
}

export default function* rootSaga() {
  yield all([fork(editemailOTPSendRequest)]);
  yield all([fork(editemailOTPResendRequest)]);
  yield all([fork(editemailOTPVerifyRequest)]);
}
