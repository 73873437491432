import React, { useCallback, useState, useEffect } from "react";
import {
  Button,
  Card,
  Popconfirm,
  notification,
  Row,
  Col,
  Radio,
  Checkbox,
  message,
} from "antd";

import { DeleteOutlined, EditOutlined, HomeOutlined } from "@ant-design/icons";
import "../../../Styles/useraddress.css";
import Adduseraddress from "./adduseraddress";
import Editaddress from "./edituseraddress";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../Redux/address/actions";
import config from "../../../Services/siteConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";

const Useraddress = () => {
  const dispatch = useDispatch();
  const [addAddress, setAddAddress] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [singleaddress, setSingleaddress] = useState({});
  const [filterType, setFilterType] = useState("All");
  const [type, setType] = useState("shipping"); // State for address type (shipping or billing)
const [intialaddress,setIntialaddress]=useState();
const[deleteaddress,setdeleteaddress]=useState();
  

  const { actionType, successMessage, useraddressdetails } = useSelector(
    (state) => {
      return state.useraddress;
    }
  );

  const getstates = useCallback(() => {

    try {
      dispatch(actions.getstates(config.getstates));
    } catch (err) {
      console.log(err);
    }
  }, [dispatch]);

  useEffect(() => {
    getstates();
  }, [getstates]);

  // Function to handle radio button change (shipping or billing)
  const handleFilterChange = (e) => {
    const selectedType = e.target.value === "billingaddress" ? "billing" : "shipping";
    setType(selectedType);
    setFilterType("All"); // Reset filter type to "All" when changing address type
  };

  const addAddressvisible = () => {
    dispatch(actions.resetActionType());
    setAddAddress(!addAddress);
    setEditAddress(false);
  };

  const editAddressvisible = (address) => {
    dispatch(actions.resetActionType());
    setEditAddress(!editAddress);
    setAddAddress(false);
    setSingleaddress(address);
    dispatch(
      actions.sendpincode(
        `https://api.postalpincode.in/pincode/${address?.Pin_Code}`
      ))
  };

  const onClose = () => {
    setEditAddress(false);
    setAddAddress(false);
  };

  const getuseraddres = useCallback(() => {
    const id = sessionStorage.getItem("id");
    try {
      dispatch(
        actions.getuseraddress(`${config.getuseraddress}?user_id=${id}&address_type=${type}`)
      );
    } catch (err) {
      console.log(err);
    }
  }, [dispatch, type]);

  useEffect(() => {
    getuseraddres();
  }, [getuseraddres, type]);
  
  useEffect(() => {
    if (useraddressdetails?.length <= 0) {
      setIntialaddress(true);
    } else {
      setIntialaddress(false); 
    }
    if (useraddressdetails?.length <= 1) {
      setdeleteaddress(true);
    }
      else {
        setdeleteaddress(false); 
    } 
  }, [useraddressdetails]);

  useEffect(() => {
    if (actionType === "DELETE_ADDRESS_SUCCESS" || actionType === "ADD_ADDRESS_SUCCESS" || actionType === "EDIT_ADDRESS_SUCCESS") {
      notification.success({
        message: successMessage,
      });
      getuseraddres();
      dispatch(actions.resetActionType());
    }
  }, [actionType, dispatch, successMessage, getuseraddres]);

  const confirm = (id) => {
    if(deleteaddress){
      message.error("Please maintain at least one address.");
    }
    else{
    try {
      dispatch(
        actions.deleteuseradress(`${config.deleteuseraddress}?id=${id}&address_type=${type}`)
      );
      getuseraddres();
    } catch (err) {
      console.log(err);
    }
  }
  };

  const cancel = (e) => {
    console.log(e);
  };

  const justifyContent = () => {
    const homeCount = useraddressdetails?.filter(
      (address) => address.Type_Of_Address === "home"
    ).length;
    const officeCount = useraddressdetails?.filter(
      (address) => address.Type_Of_Address === "Office"
    ).length;

    if (useraddressdetails?.length === 1 || homeCount === 1 || officeCount === 1) {
      return "center";
    } else {
      return "start";
    }
  };

  // Sorting addresses by default address
  const sortedAddresses = [...useraddressdetails]?.sort((a, b) =>
    b.default_address ? 1 : a.default_address ? -1 : 0
  );

  const handleAddressCheckboxChange = (address) => {
    const updatedAddress = {
      ...address,
      default_address: true,
      address_type:type,
      user_id: sessionStorage.getItem("id")
    };
    try {
      dispatch(actions.edituseraddress(config.edituseraddress, updatedAddress));
      getuseraddres();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="useraddress-container">
      <div className="address-type-selection" style={{ marginTop: "8px", marginBottom: "8px" }}>
        <Radio.Group
          buttonStyle="solid"
          value={type === "billing" ? "billingaddress" : "shippingaddress"}
          onChange={handleFilterChange}
        >
          <Radio.Button value="shippingaddress">Shipping Address</Radio.Button>
          <Radio.Button value="billingaddress">Billing Address</Radio.Button>
        </Radio.Group>
      </div>
      <Card
        className="useraddress-content"
        title={
          <div className="useraddress-header">
            {useraddressdetails?.length === 0 ? (
              <p style={{ float: "left" }}>SAVE YOUR ADDRESSES NOW</p>
            ) : (
              <p style={{ float: "left" }}>Saved Addresses</p>
            )}

            <div className="address-type-selection" style={{ marginTop: "8px" }}>
              <Radio.Group
                buttonStyle="solid"
                value={filterType}
                onChange={(e) => setFilterType(e.target.value)}
              >
                <Radio.Button value="All">All</Radio.Button>
                <Radio.Button value="home">Home</Radio.Button>
                <Radio.Button value="Office">Office</Radio.Button>
              </Radio.Group>
            </div>
            <Button
              type="primary"
              onClick={addAddressvisible}
              style={{ float: "right", marginTop: "10px" }}
            >
              Add New Address
            </Button>
          </div>
        }
      >
        <Row
          gutter={[16, 16]}
          justify={justifyContent()}
        >
          {sortedAddresses
            ?.filter(
              (address) =>
                filterType === "All" || address.Type_Of_Address === filterType
            )
            .map((address) => (
              <Col xs={24} sm={24} md={24} lg={12} key={address.id}>
                <Card
                  title={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ marginTop: "10px" }}>
                        {address.Type_Of_Address === "home" ? (
                          <span>
                            <HomeOutlined /> Home
                          </span>
                        ) : address.Type_Of_Address === "Office" ? (
                          <span>
                            <FontAwesomeIcon icon={faBuilding} fontSize={22} />{" "}
                            Office
                          </span>
                        ) : (
                          address.Type_Of_Address // Display the text if it doesn't match "home" or "Office"
                        )}
                      </div>
                      {address.default_address ? (
                        <p>{"Default Address"}</p>
                      ) : (
                        <div style={{ marginTop: "10px" }}>
                          <Checkbox
                            onChange={() =>
                              handleAddressCheckboxChange(address)
                            }
                          />
                          <label htmlFor={`default-${address.id}`} style={{ marginLeft: "10px" }}>
                            Make as Default
                          </label>
                        </div>
                      )}
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{ marginRight: 20 }}
                          onClick={() => editAddressvisible(address)}
                        >
                          <EditOutlined />
                        </div>
                        <Popconfirm
                          title="Delete the address"
                          description="Are you sure you want to delete?"
                          onConfirm={() => confirm(address.id)}
                          onCancel={cancel}
                          okText="Yes"
                          cancelText="No"
                        >
                          <div>
                            <DeleteOutlined />
                          </div>
                        </Popconfirm>
                      </div>
                    </div>
                  }
                  hoverable
                  className="useraddress-card"
                >
                  <table className="address-table">
                    <tbody>
                      <tr>
                        <th>Name</th>
                        <td style={{ wordWrap: "break-word" }}>
                          {address.Name}
                        </td>
                      </tr>
                      <tr>
                        <th>Address</th>
                        <td style={{ wordWrap: "break-word" }}>
                          {address.Address}
                        </td>
                      </tr>
                      <tr>
                        <th>Landmark</th>
                        <td style={{ wordWrap: "break-word" }}>
                          {address.Landmark}
                        </td>
                      </tr>
                      <tr>
                        <th>Locality/Town</th>
                        <td style={{ wordWrap: "break-word" }}>
                          {address.Locality_Town}
                        </td>
                      </tr>
                      <tr>
                        <th>City/District,State</th>
                        <td>
                          {address.City_District}, {address.State}-
                          {address.Pin_Code}
                        </td>
                      </tr>
                      <tr>
                        <th>Mobile Number</th>
                        <td>{address.Mobile_number}</td>
                      </tr>
                    </tbody>
                  </table>
                </Card>
              </Col>
            ))}
        </Row>
      </Card>

      {addAddress && (
        <Adduseraddress
          open={addAddress}
          onClose={onClose}
          getuseraddres={getuseraddres}
          setAddAddress={setAddAddress}
          type={type}
          intialaddress={intialaddress}
        />
      )}
      {editAddress && (
        <Editaddress
          open={editAddress}
          onClose={onClose}
          address={singleaddress}
          setEditAddress={setEditAddress}
          type={type}
        />
      )}
    </div>
  );
};

export default Useraddress;

