const actions = {
    CREATE_QUESTION: "CREATE_QUESTION",
    CREATE_QUESTION_SUCCESS: "CREATE_QUESTION_SUCCESS",
    CREATE_QUESTION_ERROR:"CREATE_QUESTION_ERROR",
    GET_QUES_AND_ANS: "GET_QUES_AND_ANS",
    GET_QUES_AND_ANS_SUCCESS: "GET_QUES_AND_ANS_SUCCESS",
    GET_QUES_AND_ANS_ERROR: "GET_QUES_AND_ANS_ERROR",
    CREATE_ANSWER: "CREATE_ANSWER",
    CREATE_ANSWER_SUCCESS: "CREATE_ANSWER_SUCCESS",
    CREATE_ANSWER_ERROR: "EDIT_PRODUCT_REVIEW_ERROR",
    CREATE_VOTE:"CREATE_VOTE",
    CREATE_VOTE_SUCCESS:"CREATE_VOTE_SUCCESS",
    CREATE_VOTE_ERROR:"CREATE_VOTE_ERROR",
    RESET_ACTIONTYPE: "RESET_ACTIONTYPE",
    
    createQuestion: (url, body) => ({
      type: actions.CREATE_QUESTION,
      payload: { url, body },
    }),
    getQuestionsAndAnswers: (url) => ({
      type: actions.GET_QUES_AND_ANS,
      payload: { url}
    }),
    createAnswers: (url,body) => ({
      type: actions.CREATE_ANSWER,
      payload: { url,body}
    }),
    createVote: (url,body) => ({
      type: actions.CREATE_VOTE,
      payload: { url,body}
    }),
    resetActionType: () => {
      return {
        type: actions.RESET_ACTIONTYPE,
      };
    },
    }
    export default actions;