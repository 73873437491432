import { all } from "redux-saga/effects";
import loginSaga from "./login/saga";
import forgotPasswordRequest from "./forgetpassword/saga";
import requestOTPSaga from "./Otp/saga";
import registerRequestSaga from "./register/saga";
import resend_Otp from "./resendotp/saga";
import verifyOTPRequest from "./LoginOtpverify/saga";
import changepasswordRequest from "./changepassword/saga" 
import LogoutRequest from "../Redux/Logout/saga"
import useraddress from "../Redux/address/saga"
import emailOTPRequest from "../Redux/MobileEmailverify/saga"
import userDetailsUpdateRequest from "../Redux/userDetailsupdate/saga"
import singleusergetRequest from "../Redux/Singleuserdetails/saga"
import editmobileOTPSendRequest from "../Redux/updatemobileverify/saga"
import editemailOTPVerifyRequest from "../Redux/updateemailverify/saga"
import productcatagorySaga from "../Redux/productcategories/saga"
import getSingleProductSaga from "../Redux/SingleProduct/saga"
import WishlistSaga from "../Redux/wishlist/saga"
import Addtocart from "./addtocart/saga"
import orderdetails from "./orderhistory/saga"
import searchProductsRequest from "./search/saga"
import productreviewSaga from "./reviews/saga"
import questionandanswers from "./questionanswer/saga"

export default function* rootSaga() {
  yield all([
    loginSaga(),
    forgotPasswordRequest(),
    requestOTPSaga(),
    verifyOTPRequest(),
    registerRequestSaga(),
    resend_Otp(),
    changepasswordRequest(),
    LogoutRequest(),
    useraddress(),
    emailOTPRequest(),
    userDetailsUpdateRequest(),
    singleusergetRequest(),
    editmobileOTPSendRequest(),
    editemailOTPVerifyRequest(),
    productcatagorySaga(),
    getSingleProductSaga(),
    WishlistSaga(),
    Addtocart(),
    orderdetails(),
    searchProductsRequest(),
    productreviewSaga(),
    questionandanswers(),
  ]);
}
