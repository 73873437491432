import React, { useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Form,
  Input,
  Space,
  Radio,
  Select,
  Checkbox,
  Spin,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../Redux/address/actions";
import config from "../../../Services/siteConfig";
import { LoadingOutlined } from "@ant-design/icons";

const Adduseraddress = ({
  open,
  onClose,
  setAddAddress,
  type,
  intialaddress
}) => {
  const { Option } = Select;
  const [Pincode, setPincode] = useState();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [district, setDistrict] = useState("");
  const [state, setState] = useState("");
  const {  stateslist } = useSelector((state) => {
    return state.useraddress;
  });

  const { data, isFetching } = useSelector((state) => state.useraddress);
  const [showOtherInput, setShowOtherInput] = useState(false);

  const [uniqueName,setuniqueName] = useState([]);

  const handleSubmit = (values) => {
    const id = sessionStorage.getItem("id");
    values["user_id"] = id;
    values["Name"] = values["Name"].trim();
    values["Address"] = values["Address"].trim();
    values["Landmark"] = values["Landmark"]?.trim();
    values["City_District"] = values["City_District"].trim()
    values["address_type"] = type;
    if (intialaddress) {
      values["default_address"] = true;
    } 
    if (values["Locality_Town"] === "Others") {
      values["Locality_Town"] = values["OtherLocality"].trim();
      delete values["OtherLocality"]; // Remove OtherLocality field
    } else {
      if ("OtherLocality" in values) {
        delete values["OtherLocality"];
      }
    }
    try {
      dispatch(actions.adduseraddress(config.adduseraddress, values));
      form.resetFields();
      setAddAddress(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handlePinCodeChange = (e) => {
    setShowOtherInput(false)
    setDistrict("");
    setState("")
    setuniqueName([])
    form.setFieldsValue({
      Locality_Town:"",
      OtherLocality:""
    });
    dispatch(actions.resetActionType());

    const pinCodeValue = e.target.value;
    if (pinCodeValue.length === 6) {
      setPincode(pinCodeValue);
      dispatch(
        actions.sendpincode(
          `https://api.postalpincode.in/pincode/${pinCodeValue}`
        )
      );
    }
  };

  useEffect(() => {
    if (data && data?.length > 0) {
      setDistrict(data[0]?.District || "");
      setState(data[0]?.State || "");
      setuniqueName([...new Set(data?.map((postOffice) => postOffice?.Name))])
    }
  }, [data]);

  useEffect(() => {
    form.setFieldsValue({
      City_District: district,
      State: state,
    });
  }, [Pincode, district, state,form]);

  const handleLocalityChange = (value) => {
    setShowOtherInput(value === "Others");
    // Reset the form field if switching away from "Others"
    if (value !== "Others") {
      form.setFieldsValue({ OtherLocality: "" });
    }
  };

  return (
    <div>
      <Drawer
        title="Add New Address"
        width={500}
        onClose={onClose}
        open={open}
        className="useraddress-drawer"
        footer={
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Space>
              <Button
                onClick={onClose}
                style={{ width: "180px", borderRadius: "10px" }}
              >
                Cancel
              </Button>
              <Button
                htmlType="submit"
                form="addressForm"
                type="primary"
                style={{
                  width: "180px",
                  marginLeft: "10px",
                  borderRadius: "10px",
                }}
              >
                Save
              </Button>
            </Space>
          </div>
        }
      >
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          id="addressForm"
          className="useraddress-form"
          form={form}
        >
          <Form.Item
            name="Name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Name required!",
              },
              {
                max: 30,
                message: "Name must not exceed 30 characters",
              },
              {
                pattern: /^[A-Za-z]+(?:\s+[A-Za-z]+)*$/,
                message: "Enter valid name",
                transform: (value) => value.trim(),
              },
            ]}
          >
            <Input
              className="useraddress-input"
              placeholder="Enter your name"
              minLength={3}
              maxLength={31}
            />
          </Form.Item>
          <Form.Item
            name="Mobile_number"
            label="Mobile Number"
            rules={[
              { required: true, message: "Mobile number required!" },
              { pattern: /^[6-9]\d*$/, message: "Enter valid number" },
            ]}
          >
            <Input
              className="useraddress-input"
              maxLength={10}
              minLength={10}
              placeholder="Enter your mobile number"
            />
          </Form.Item>
          <Form.Item
            name="Pin_Code"
            label="Pincode"
            rules={[
              { required: true, message: "Pincode required!" },
              { pattern: /^[1-9][0-9]{5}$/, message: "Invalid pincode" },
            ]}
          >
            <Input
              className="useraddress-input"
              onChange={handlePinCodeChange}
              maxLength={6}
              placeholder="Enter your 6-digit pincode"
              suffix={
                isFetching ? (
                  <Spin indicator={<LoadingOutlined spin />} />
                ) : null
              }
            />
          </Form.Item>
          <Form.Item label="State" name="State"
           
           rules={[
             { required: true, message: "State is required!" },
             { min: 3, message: "State minimum length: 3" ,
             transform: (value) => value.trim()}
             
           ]}
         >
           <Select placeholder="Select your state">
             {stateslist.map((state) => (
               <Option 
                 value={state.attributes.state_name}
               >
                 {state.attributes.state_name}
               </Option>
             ))}
           </Select>
         </Form.Item>
          <Form.Item
            name="Address"
            label="Address [House No,Building,Street,Area]"
            rules={[
              { required: true, message: "Address required!" },
              {
                max: 70,
                message: "Address must not exceed 70 characters",
              },
              {
                pattern:   /^[a-zA-Z0-9\s\-,&:'()+;@=./#[\]]*$/,
                transform: (value) => value.trim(),
                message: "Enter valid address",
              },
            ]}
          >
            <Input
              className="useraddress-input"
              maxLength={71}
              placeholder="Enter your address"
            />
          </Form.Item>
          <Form.Item
            name="Locality_Town"
            label="Locality/Town"
            rules={[
              { required: true, message: "Locality/Town required!" },
              {
                pattern: /^[a-zA-Z0-9\s\-,:().`'&/#]*$/,
                message: "Enter valid Locality/Town",
              },
              {
                max: 49,
                message: "Name must not exceed 50 characters",
              },
            ]}
          >
            <Select
              placeholder="Select your locality/town"
              onChange={handleLocalityChange}
            >
              {uniqueName?.map((name) => (
                <Option key={name} value={name}>
                  {name}
                </Option>
              ))}
              <Option value={"Others"}>Other</Option>
            </Select>
          </Form.Item>

          {/* Conditional rendering of the Other input field */}
          {showOtherInput && (
            <Form.Item
              name="OtherLocality"
              label="Other Locality/Town"
              rules={[
                {
                  required: true,
                  message: "Please enter the locality/town",
                },
                {
                  max: 49,
                  message: "Name must not exceed 50 characters",
                },
                { 
                  pattern:/^[a-zA-Z0-9\s\-,:().`'&/#]*$/,
                  message: "Enter valid Locality/Town",
                }
              ]}
            >
              <Input
                className="useraddress-input"
                placeholder="Enter your locality/town"
                maxLength={50}
              />
            </Form.Item>
          )}

          <Form.Item
            label="City/District"
            name="City_District"
            rules={[{ required: true, message: "City/District required!" }]}
          >
            <Input
              className="useraddress-input"
              placeholder="Enter your city/district"
              maxLength={50}
            />
          </Form.Item>
          <Form.Item
            name="Landmark"
            label="Landmark"
            rules={[
              {
                max: 49,
                message: "Landmark must not exceed 50 characters",
              },
              {
                pattern: /^[a-zA-Z0-9\s\-,:()'.&+;/#]*$/,
                message: "Enter valid landmark",
              },
            ]}
          >
            <Input
              className="useraddress-input"
              maxLength={50}
              placeholder="Enter your landmark"
            />
          </Form.Item>
          <Form.Item
            name="Type_Of_Address"
            label="Type of Address"
            rules={[{ required: true, message: "Type of address required!" }]}
          >
            <Radio.Group className="useraddress-radio-group">
              <Radio value="home">Home</Radio>
              <Radio value="Office">Office</Radio>
            </Radio.Group>
          </Form.Item>
          {!intialaddress && (
          <Form.Item
            name="default_address"
            valuePropName="checked"
            className="useraddress-checkbox"
          >
            <Checkbox>Make this my default address</Checkbox>
          </Form.Item>
          )}
        </Form>
      </Drawer>
    </div>
  );
};

export default Adduseraddress;
