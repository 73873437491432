import React, { useEffect, useState } from "react";
import { Layout, Card, Spin, Tooltip, Form,Modal,message,Select,Input } from "antd";
import { HeartOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import action from "../../Redux/search/action";
import actions from "../../Redux/wishlist/actions";
import config from "../../Services/siteConfig";

const { Content } = Layout;

const SearchScreen = () => {
  const { searchResult } = useSelector((state) => state.searchReducer);
  const { wishlist, actionType, status_code, successMessage } = useSelector(
    (state) => state.wishlistReducer
  );
  const [loading, setLoading] = useState(false);
  const { searchTerm } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isWishlisted, setIsWishlisted] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(""); // State to hold selected product ID
  const [customWishlistName, setCustomWishlistName] = useState("");
  const [selectedWishlist, setSelectedWishlist] = useState("");

  const { Option } = Select;

  const [form] = Form.useForm();

  useEffect(() => {
    try {
      dispatch(
        action.searchProducts(
          `${config.search}?search=${localStorage.getItem("searchTerm")}`
        )
      );
    } catch (error) {
      console.error("Error dispatching searchProducts:", error);
    }
  }, [dispatch, searchTerm]);



  const navigateToProductPage = (productId) => {
    navigate(`/product/${productId}`);
    window.scroll(0, 0);
  };
  const handleSelectChange = (option) => {
    const selectedWishlist = {
      wishlist_name: option.children,
      group_id: option.key,
    };
    setSelectedWishlist(selectedWishlist);
    setCustomWishlistName(option.children);
  };
  const handleWishlist = (productId) => {
    setIsWishlisted(!isWishlisted);
    setIsModalVisible(true);
    resetFormFields();
    setSelectedProductId(productId);
  };

  const resetFormFields = () => {
    setSelectedWishlist(null);
    setCustomWishlistName("");
    form.resetFields();
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setCustomWishlistName("");
  };

  const handleCustomWishlistNameChange = (e) => {
    setCustomWishlistName(e.target.value);
  };

  const addToWishlist = () => {
    closeModal();

    let payload = {
      user_id: sessionStorage.getItem("id"),
      product_id: String(selectedProductId),
      wishlist_name: selectedWishlist
        ? selectedWishlist.wishlist_name
        : customWishlistName.trim(),
      group_id: selectedWishlist ? selectedWishlist.group_id : null,
    };

    if (
      selectedWishlist &&
      selectedWishlist.wishlist_name.trim() !== customWishlistName.trim()
    ) {
      payload.wishlist_name = customWishlistName.trim();
      payload.group_id = null;
    }
    console.log(payload);
    try {
      dispatch(actions.addToWishlist(config.addtowishlist, payload));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (actionType === "ADD_TO_WISHLIST_SUCCESS" && status_code === 1) {
      message.success(successMessage);
      dispatch(actions.resetActionType());
    } else if (actionType === "ADD_TO_WISHLIST_SUCCESS" && status_code === 0) {
      message.error(successMessage);
      dispatch(actions.resetActionType());
    }
  }, [actionType, status_code, successMessage]);
  return (
    <Layout>
      <Content style={{ padding: "0 24px", minHeight: 280 }}>
        <Spin spinning={loading}>
          {searchResult.length === 0 ? (
            <div style={{ textAlign: "center", padding: "24px" }}>
              <img
                src="https://cdn.dribbble.com/userupload/2905364/file/original-c1597c0c2a6e0456d362549e47988f1b.png?resize=400x300&vertical=center"
                alt="No results found"
                style={{ maxWidth: "100%", maxHeight: "100%", height: "auto" }}
              />
              <h2 style={{ fontSize: "24px", fontWeight: "bold" }}>
                No results found
              </h2>
            </div>
          ) : (
            <div>
              {searchResult.map((category) => (
                <div key={category.categoryId}>
                  <h2>{category.name}</h2>
                  <div
                    style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}
                  >
                    {category.products.map((product) => (
                      <Card
                        key={product.id}
                        hoverable
                        style={{
                          width: "calc(20% - 16px)",
                          marginBottom: "16px",
                        }}
                        cover={
                          <img
                            alt={product.name}
                            src={product.images[0].url}
                            style={{
                              objectFit: "contain",
                              height: "200px",
                              width: "100%",
                              padding: "20px",
                            }}
                            onClick={()=>navigateToProductPage(product.id)}
                          />
                        }
                        actions={[
                          <Tooltip title="Add to Wishlist">
                            <HeartOutlined
                              key="wishlist"
                              onClick={() => handleWishlist(product.id)}
                              style={{ fontSize: "20px" }}
                            />
                          </Tooltip>,
                        ]}
                      >
                        <Card.Meta
                          title={product.name}
                          description={`₹${product.price}`}
                        />
                      </Card>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </Spin>
      </Content>
      <Modal
          visible={isModalVisible}
          onOk={addToWishlist}
          onCancel={closeModal}
          width={380}
        >
          <Form form={form} layout="vertical">
            <Form.Item label="Select wishlist">
              <Select
                placeholder="Select wishlist"
                style={{ width: "100%" }}
                value={selectedWishlist?.wishlist_name} // assuming selectedWishlist is an object
                onChange={(value, option) => handleSelectChange(option)}
              >
                {wishlist?.data
                  ?.reduce((uniqueItems, item) => {
                    if (
                      !uniqueItems.some(
                        (uniqueItems) =>
                          uniqueItems.wishlist_name === item.wishlist_name
                      )
                    ) {
                      uniqueItems.push(item);
                    }
                    return uniqueItems;
                  }, [])
                  .map(
                    (item) =>
                      item.wishlist_name !== "My Wishlist" && (
                        <Option key={item.group_id} value={item.wishlist_name}>
                          {item.wishlist_name}
                        </Option>
                      )
                  )}
                <Option value="My Wishlist">My Wishlist</Option>
              </Select>
            </Form.Item>

            <Form.Item label="Or create a new wishlist">
              <Input
                placeholder="Enter wishlist name"
                value={customWishlistName}
                onChange={handleCustomWishlistNameChange}
              />
            </Form.Item>
          </Form>
        </Modal>
    </Layout>
  );
};

export default SearchScreen;
