import actions from "./actions";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  id:0,
  isFetching: false,
  successMessage: "",
  errorMessage: "",
  actionType: "",
  registrationdata:{},
});

export default function registerReducer(state = initialState, action) {
  switch (action.type) {
    case actions.REGISTER_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.REGISTER_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: "REGISTER_SUCCESS",
        successMessage: action.message,
        registrationdata:action.registrationdata
      });
      case actions.RESET_ACTIONTYPE:
        return Immutable.merge(state, {
          actionType: "",
        });
    case actions.REGISTER_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: "REGISTER_ERROR",
        errorMessage: action.errorMessage,
      });
    default:
      return state;
  }
}
