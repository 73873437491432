import React, { useState } from "react";
import { Button } from "antd";

const AccountCreatedMessage = () => {
  const [resent, setResent] = useState(false);
  const email = "xyz@studio@gmail.com"; // Replace with the actual email address

  const handleResend = () => {
    setResent(true);
    console.log(resent)
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        fontFamily: "Arial, sans-serif",
        padding: "20px",
        boxSizing: "border-box",
        backgroundColor: "rgba(24, 61, 70, 255)",
      
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: "5px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          padding: "20px",
          textAlign: "center",
          maxWidth: "560px",
          width: "100%",
        }}
      >
        <img
          src="https://www.kingswaysoft.com/Frontend/Images/ssis-integration-components/email-data-integration.png"
          alt="email-icon"
          style={{ height: "110px" }}
        />
        <p style={{ fontSize: "2rem", color: "#333" }}>Verify Your Email Address</p>
        <p style={{ fontSize: "1rem", color: "#555" }}>
          A verification email has been sent to your email{" "}
          <span style={{ color: "green" }}>{email}.</span> Please check your email
          and click the link provided in the email to complete account registration.
        </p>
        <p style={{ fontSize: "1rem", color: "#555" }}>
          If you don't receive the email within 5 minutes, use the button below to
          resend the email.
        </p>
        <Button
          type="primary"
          htmlType="submit"
          style={{
            width: "200px",
            height: "40px",
            borderRadius: "10px",
            backgroundColor: "#45eb7f",
            borderColor: "#45eb7f",
            fontSize: "1rem",
          }}
          onClick={handleResend}
        >
          Resend Email
        </Button>
        <p style={{ fontSize: "1rem", color: "#555", marginTop: "20px" }}>
          <a
            href="/signin"
            style={{ color: "#0066c0", textDecoration: "underline" }}
          >
            Return to Sign In
          </a>
        </p>
      </div>
    </div>
  );
};

export default AccountCreatedMessage;
