// actions.js
const actions = {
    CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
    CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
    CHANGE_PASSWORD_ERROR: "CHANGE_PASSWORD_ERROR",
    RESET_ACTIONTYPE:"RESET_ACTIONTYPE",
    changePasssword: (url, body) => ({
        type: actions.CHANGE_PASSWORD_REQUEST,
        payload: { url, body },
      }),
      resetActionType: () => {
        return {
          type: actions.RESET_ACTIONTYPE,
        };
      },
}
export default actions;