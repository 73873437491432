// sagas.js
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import actions from "./actions";
import AxiosInterceptor from "../../Services/axiosInterceptor";

const allOrderDetails = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.get(url);
  return result;
};
const singleOrderDetails = async (payload) => {
    const { url } = payload;
    const result = await AxiosInterceptor.get(url);
    return result;
  };
  const updateOrder = async (payload) => {
    const { url } = payload;
    const result = await AxiosInterceptor.put(url);
    return result;
  };
  const cancelOrder = async (payload) => {
    const { url } = payload;
    const result = await AxiosInterceptor.delete(url);
    return result;
  };
export function* getAllorderdetailsSaga() {
  yield takeEvery("GET_ORDER_DETAILS", function* ({ payload }) {
    try {
      const res = yield call(allOrderDetails, payload);
      if (res?.status_code===1) {
        yield put({
          type: actions.GET_ORDER_DETAILS_SUCCESS,
          data:res
        });}

    } catch (error) {
      yield put({
        type: actions.GET_ORDER_DETAILS_ERROR,
        errorMessage: "Something went wrong. Please try after some time.",
      });
    }
  });
}
export function* getsingleorderdetailsSaga() {
    yield takeEvery("GET_DETAILED_0RDERINFOMRATION", function* ({ payload }) {
      try {
        const res = yield call(singleOrderDetails, payload);
        if (res?.status_code===1) {
          yield put({
            type: actions.GET_DETAILED_0RDERINFOMRATION_SUCCESS,
            message: res.message,
            data:res,
          });}
  
      } catch (error) {
        yield put({
          type: actions.GET_DETAILED_0RDERINFOMRATION_ERROR,
          errorMessage: "Something went wrong. Please try after some time.",
        });
      }
    });
  }
  export function* updateOrderRequestSaga() {
    yield takeEvery("UPDATE_ORDER_DETAILS", function* ({ payload }) {
      try {
        const res = yield call(updateOrder, payload);
        if (res?.status_code===1) {
          yield put({
            type: actions.UPDATE_ORDER_DETAILS_SUCCESS,
            message: res.message,
            status_code:res.status_code,
            data:res
          });}
  
      } catch (error) {
        yield put({
          type: actions.UPDATE_ORDER_DETAILS_ERROR,
          errorMessage: "Something went wrong. Please try after some time.",
        });
      }
    });
  }

  export function* cancelorderRequestSaga() {
    yield takeEvery("CANCEL_ORDER", function* ({ payload }) {
      try {
        const res = yield call(cancelOrder, payload);
        if (res) {
          yield put({
            type: actions.CANCEL_ORDER_SUCCESS,
            message: res.message,
            status_code:res.status_code
          });}
  
      } catch (error) {
        yield put({
          type: actions.CANCEL_ORDER_ERROR,
          errorMessage: "Something went wrong. Please try after some time.",
        });
      }
    });
  }

export default function* rootSaga() {
  yield all([fork(getAllorderdetailsSaga)]);
  yield all([fork(getsingleorderdetailsSaga)]);
  yield all([fork(updateOrderRequestSaga)]);
  yield all([fork(updateOrderRequestSaga)]);
}
