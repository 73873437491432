const actions = {
    GET_ORDER_DETAILS: "GET_ORDER_DETAILS",
    GET_ORDER_DETAILS_SUCCESS: "GET_ORDER_DETAILS_SUCCESS",
    GET_ORDER_DETAILS_ERROR: "GET_ORDER_DETAILS_ERROR",
    GET_DETAILED_0RDERINFOMRATION:"GET_DETAILED_0RDERINFOMRATION",
    GET_DETAILED_0RDERINFOMRATION_SUCCESS:"GET_DETAILED_0RDERINFOMRATION_SUCCESS",
    GET_DETAILED_0RDERINFOMRATION_ERROR:"GET_DETAILED_0RDERINFOMRATION__ERROR",
    UPDATE_ORDER_DETAILS:"UPDATE_ORDER_DETAILS",
    UPDATE_ORDER_DETAILS_SUCCESS:"UPDATE_ORDER_DETAILS_SUCCESS",
    UPDATE_ORDER_DETAILS_ERROR:"UPDATE_ORDER_DETAILS_ERROR",
    CANCEL_ORDER:"CANCEL_ORDER",
    CANCEL_ORDER_SUCCESS:"CANCEL_ORDER_SUCCESS",
    CANCEL_ORDER_ERROR:"CANCEL_ORDER_ERROR",
    RESET_ACTIONTYPE:"RESET_ACTIONTYPE",

    getAllOrder: (url) => ({
        type: actions.GET_ORDER_DETAILS,
        payload: { url },
      }),
      getIndividualOrderDetails: (url) => ({
        type: actions.GET_DETAILED_0RDERINFOMRATION,
        payload: { url },
      }),
      
      updateOrder: (url) => ({
        type: actions.UPDATE_ORDER_DETAILS,
        payload: { url },
      }),
      cancelOrder: (url) => ({
        type: actions.CANCEL_ORDER,
        payload: { url },
      }),
      resetActionType: () => {
        return {
          type: actions.RESET_ACTIONTYPE,
        };
      },
}
export default actions;