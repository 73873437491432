// actions.js
const actions = {
  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_ERROR: "FORGOT_PASSWORD_ERROR",

  FORGOT_PASSWORD_OTP_REQUEST: "FORGOT_PASSWORD_OTP_REQUEST",
  FORGOT_PASSWORD_OTP_SUCCESS: "FORGOT_PASSWORD_OTP_SUCCESS",
  FORGOT_PASSWORD_OTP_ERROR: "FORGOT_PASSWORD_OTP_ERROR",

  FORGOT_PASSWORD_OTP_VERIFY: "FORGOT_PASSWORD_OTP_VERIFY",
  FORGOT_PASSWORD_OTP_VERIFY_SUCCESS: "FORGOT_PASSWORD_OTP_VERIFY_SUCCESS",
  FORGOT_PASSWORD_OTP_VERIFY_ERROR: "FORGOT_PASSWORD_OTP_VERIFY_ERROR",

  FORGOT_PASSWORD_OTP_RESEND: "FORGOT_PASSWORD_OTP_RESEND",
  FORGOT_PASSWORD_OTP_RESEND_SUCCESS: "FORGOT_PASSWORD_OTP_RESEND_SUCCESS",
  FORGOT_PASSWORD_OTP_RESEND_ERROR: "FORGOT_PASSWORD_OTP_RESEND_ERROR",

  RESET_ACTIONTYPE: "RESET_ACTIONTYPE",

 
 
  forgotPassword: (url, body) => ({
    type: actions.FORGOT_PASSWORD_REQUEST,
    payload: { url, body },
  }),


  forgotPasswordOTP: (url, body) => ({
    type: actions.FORGOT_PASSWORD_OTP_REQUEST,
    payload: { url, body },
  }),


  forgotPassword_OTP_Verify: (url) => ({
    type: actions.FORGOT_PASSWORD_OTP_VERIFY,
    payload: { url },
  }),

 
  forgotPassword_OTP_Resend: (url,body) => ({
    type: actions.FORGOT_PASSWORD_OTP_RESEND,
    payload: { url ,body},
  }),

  
  resetActionType: () => {
    return {
      type: actions.RESET_ACTIONTYPE,
    };
  },
};

export default actions;
