import { takeEvery, put, call, all, fork } from "redux-saga/effects";
import actions from "../Singleuserdetails/actions";
import AxiosInterceptor from "../../Services/axiosInterceptor"; // Import AxiosInterceptor

const singleuserget = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.get(url);
  return result;
};

export function* singleusergetRequest() {
  yield takeEvery(actions.SINGLE_USER_DETAILS_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(singleuserget, payload);
      if (res) {
        yield put({
          type: actions.SINGLE_USER_DETAILS_SUCCESS,

          data: res?.userData,
        });
      } else {
        yield put({
          type: actions.SINGLE_USER_DETAILS_FAILURE,
          successMessage: res.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.SINGLE_USER_DETAILS_FAILURE,
        errorMessage: "Failed to update profile image",
      });
    }
  });
}
export default function* rootSaga() {
  yield all([fork(singleusergetRequest)]);
}
