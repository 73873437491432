const actions = {
  RESET_ACTIONTYPE: "RESET_ACTIONTYPE",

  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_ERROR: "LOGOUT_ERROR",

  logout: (url) => ({
    type: actions.LOGOUT_REQUEST,
    payload:{url}
  }),

  resetActionType: () => {
    return {
      type: actions.RESET_ACTIONTYPE,
    };
  },
};

export default actions;
