import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import actionsss from "../../Redux/updatemobileverify/actions";
import actionssss from "../../Redux/updateemailverify/action"
import config from "../../Services/siteConfig";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";

const ProfileOtpVerify = () => {
  const [otp, setOTP] = useState("");
  const [error, setError] = useState("");
  const [timer, setTimer] = useState(30);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  var { actionTypess, successMessagess, errorMessagess, status_codess } =
    useSelector((state) => state.editprofileupdatemobileReducer);

    var { actionTypesss, successMessagesss, errorMessagesss, status_codesss } =
    useSelector((state) => state.editprofileupdateemailReducer);

  useEffect(() => {
    if (
      actionTypess === "EDIT_PROFILE_MOBILE_OTP_RESEND_SUCCESS" &&
      status_codess === 1
    ) {
      notification.success({
        message: successMessagess,
      });
      dispatch(actionsss.resetActionType());
    } else if (
      actionTypess === "EDIT_PROFILE_MOBILE_OTP_RESEND_SUCCESS" &&
      status_codess === 0
    ) {
      notification.error({
        message: errorMessagess,
      });
    }
  });

  useEffect(() => {
    if (
      actionTypess === "EDIT_PROFILE_MOBILE_OTP_VERIFY_SUCCESS" &&
      status_codess === 1
    ) {
      notification.success({
        message: successMessagess,
      });
      sessionStorage.removeItem("updateone")
      sessionStorage.removeItem("updatenumber")
      dispatch(actionsss.resetActionType());
      navigate("/layout");
    } else if (
      actionTypess === "EDIT_PROFILE_MOBILE_OTP_VERIFY_SUCCESS" &&
      status_codess === 0
    ) {
      notification.error({
        message: successMessagess,
      });

      dispatch(actionsss.resetActionType());
    }

  });

  useEffect(() => {
    if (
      actionTypesss === "EDIT_PROFILE_EMAIL_VERIFY_SUCCESS" &&
      status_codesss === 1
    ) {
      notification.success({
        message: successMessagesss,
      });
      sessionStorage.removeItem("updatetwo")
      sessionStorage.removeItem("updateemail")
      dispatch(actionssss.resetActionType());
        navigate("/layout");
    } else if (
      actionTypesss === "EDIT_PROFILE_EMAIL_VERIFY_SUCCESS" &&
      status_codesss === 0
    ) {
      notification.error({
        message: successMessagesss,
      });
      dispatch(actionssss.resetActionType());
    }
  });

  useEffect(() => {
    if (
      actionTypesss === "EDIT_PROFILE_EMAIL_RESEND_SUCCESS" &&
      status_codesss === 1
    ) {
      notification.success({
        message: successMessagesss,
      });
      dispatch(actionssss.resetActionType());
   
    } else if (
      actionTypesss === "EDIT_PROFILE_EMAIL_RESEND_SUCCESS" &&
      status_codesss === 0
    ) {
      notification.error({
        message: errorMessagesss,
      });
    }
  });

  useEffect(() => {
    if (isButtonDisabled) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 1) {
            clearInterval(interval);
            setIsButtonDisabled(false);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isButtonDisabled]);

  const handleChange = (e) => {
    setOTP(e.target.value);
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!otp) {
      setError("Please enter the OTP.");
      return;
    }
    if (sessionStorage.getItem("updateone") === "1") {
      const payload = {
        user_name: sessionStorage.getItem("updatenumber"),
        otp: otp,
        verify_type: "userprofile",
        type: "mobile",
        id: sessionStorage.getItem("id"),
      };
      console.log(payload);
      dispatch(
        actionsss.verifyEditProfileMobileOTP(
          `${config.forgotpasswordotpveify}?verify_type=userprofile&user_name=${payload.user_name}&otp=${payload.otp}&type=${payload.type}&id=${payload.id}`
        )
      );
    }
    else if(sessionStorage.getItem("updatetwo") === "2") {
      const payload = {
        user_name: sessionStorage.getItem("updateemail"),
        otp: otp,
        verify_type: "userprofile",
        type: "email",
        id: sessionStorage.getItem("id"),
      };
      console.log(payload);
      dispatch(
        actionssss.verifyEditProfileEmail(
          `${config.forgotpasswordotpveify}?verify_type=userprofile&user_name=${payload.user_name}&otp=${payload.otp}&type=${payload.type}&id=${payload.id}`
        )
      );
    }
  };

  const handleResendOTP = () => {
    setIsButtonDisabled(true);
    setTimer(30);
    if (sessionStorage.getItem("updateone") === "1") {
      const payload = {
        user_name: sessionStorage.getItem("updatenumber"),
        verify_type: "userprofile",
        type: "mobile",
        id: sessionStorage.getItem("id"),
      };
      console.log(payload)

      dispatch(
        actionsss.resendEditProfileMobileOTP(config.forgotpasswordotp, payload)
      );
    }
     else if(sessionStorage.getItem("updatetwo") === "2") {
      const payload = {
        user_name: sessionStorage.getItem("updateemail"),
        verify_type: "userprofile",
        type: "email",
        id: sessionStorage.getItem("id"),
      };
      console.log(payload)

      dispatch(
        actionssss.resendEditProfileEmail(config.forgotpasswordotp, payload)
      );
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div
        style={{
          width: "380px",
          height: "380px",
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            padding: "20px",
            backgroundColor: "white",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <h2 style={{ marginTop: "0" }}>Authentication required</h2>
          <p style={{ fontSize: "16px", marginBottom: "20px" }}>
            We’ve sent a One Time Password (OTP) to the mobile number. Please
            enter it to complete verification.
          </p>

          <form onSubmit={handleSubmit}>
            <input
              style={{
                width: "calc(100% - 22px)",
                padding: "10px",
                marginBottom: "10px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
              type="text"
              value={otp}
              onChange={handleChange}
              maxLength="6"
              placeholder="Enter OTP"
            />
            {error && <p style={{ color: "red", marginTop: "5px" }}>{error}</p>}
            <button
              style={{
                width: "100%",
                padding: "10px",
                backgroundColor: "#f0c14b",
                border: "none",
                borderRadius: "4px",
                color: "#111",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              type="submit"
            >
              Continue
            </button>
            <button
              style={{
                width: "100%",
                padding: "10px",
                marginTop: "10px",
                backgroundColor: "transparent",
                cursor: isButtonDisabled ? "not-allowed" : "pointer",
                color: isButtonDisabled ? "grey" : "blue",
              }}
              type="button"
              onClick={handleResendOTP}
              disabled={isButtonDisabled}
            >
              {isButtonDisabled ? `Resend OTP in ${timer}s` : "Resend OTP"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProfileOtpVerify;
