const actions = {
    CREATE_REVIEW: "CREATE_REVIEW",
    CREATE_REVIEW_SUCCESS: "CREATE_REVIEW_SUCCESS",
    CREATE_REVIEW_ERROR:"CREATE_REVIEW_ERROR",
    GET_PRODUCT_REVIEWS: "GET_PRODUCT_REVIEWS",
    GET_PRODUCT_REVIEW_SUCCESS: "GET_PRODUCT_REVIEW_SUCCESS",
    GET_PRODUCT_REVIEW_ERROR: "GET_PRODUCT_REVIEW_ERROR",
    EDIT_PRODUCT_REVIEW: "EDIT_PRODUCT_REVIEW",
    EDIT_PRODUCT_REVIEW_SUCCESS: "EDIT_PRODUCT_REVIEW_SUCCESS",
    EDIT_PRODUCT_REVIEW_ERROR: "EDIT_PRODUCT_REVIEW_ERROR",
    DELETE_PRODUCT_REVIEW: "DELETE_PRODUCT_REVIEW",
    DELETE_PRODUCT_REVIEW_SUCCESS: "DELETE_PRODUCT_REVIEW_SUCCESS",
    DELETE_PRODUCT_REVIEW_ERROR: "DELETE_PRODUCT_REVIEW_ERROR",
    CREATE_VOTE:"CREATE_VOTE",
    CREATE_VOTE_SUCCESS:"CREATE_VOTE_SUCCESS",
    CREATE_VOTE_ERROR:"CREATE_VOTE_ERROR",
    RESET_ACTIONTYPE: "RESET_ACTIONTYPE",
    
    createReview: (url, body) => ({
      type: actions.CREATE_REVIEW,
      payload: { url, body },
    }),
    getProductReview: (url) => ({
      type: actions.GET_PRODUCT_REVIEWS,
      payload: { url}
    }),
    deleteProductReview: (url) => ({
      type: actions.DELETE_PRODUCT_REVIEW,
      payload: { url}
    }),
    editProductReview:(url,body)=>({
      type: actions.EDIT_PRODUCT_REVIEW,
      payload: { url, body },
    }),
    resetActionType: () => {
      return {
        type: actions.RESET_ACTIONTYPE,
      };
    },
    }
    export default actions;