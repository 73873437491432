import actions from "./actions";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  isFetching: false,
  quessuccessMessage: "",
  errorMessage: "",
  questionactionType: "",
 data1:{}

});

export default function questionAnswers(state = initialState, action) {
  switch (action.type) {
    case actions.CREATE_QUESTION:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.CREATE_QUESTION_SUCCESS:
      return Immutable.merge(state, {
        questionactionType: "CREATE_QUESTION_SUCCESS",
        quessuccessMessage: action.message,
      });
    case actions.CREATE_QUESTION_ERROR:
      return Immutable.merge(state, {
        questionactionType: "CREATE_QUESTION_ERROR",
        errorMessage: action.errorMessage,
      });

    case actions.GET_QUES_AND_ANS:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.GET_QUES_AND_ANS_SUCCESS:
      return Immutable.merge(state, {
        questionactionType: "GET_QUES_AND_ANS_SUCCESS",
        data1:action.data,
      });
    case actions.GET_QUES_AND_ANS_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        questionactionType: "GET_QUES_AND_ANS_ERROR",
        errorMessage: action.errorMessage,
      });

    case actions.CREATE_ANSWER:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.CREATE_ANSWER_SUCCESS:
      return Immutable.merge(state, {
        questionactionType: "CREATE_ANSWER_SUCCESS",
        quessuccessMessage: action.message,
      });
    case actions.CREATE_ANSWER_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        questionactionType: "CREATE_ANSWER_ERROR",
        errorMessage: action.errorMessage,
      });

      case actions.CREATE_VOTE:
        return Immutable.merge(state, {
          isFetching: true,
        });
      case actions.CREATE_VOTE_SUCCESS:
        return Immutable.merge(state, {
          questionactionType: "CREATE_VOTE_SUCCESS",
          quessuccessMessage: action.message,
        });
      case actions.CREATE_VOTE_ERROR:
        return Immutable.merge(state, {
          isFetching: false,
          questionactionType: "CREATE_VOTE_ERROR",
          errorMessage: action.errorMessage,
        });

      case actions.RESET_ACTIONTYPE:
        return Immutable.merge(state, {
          questionactionType: "",
          quessuccessMessage: "",
          errorMessage:"",          
        });
    default:
      return state;
  }
}