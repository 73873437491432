import React from 'react';
import { Button, Card, Divider, List, Typography } from 'antd';
import {  useSelector } from "react-redux";
const { Title, Text } = Typography;

const OrderReview = ({  }) => {
  const handleConfirm = () => {

  };
  const {currentUser } = useSelector(
    (state) => state.auth
  );
  const address = currentUser?.shippingAddress;
  return (
    <div style={{ maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
      <Title level={2} style={{ textAlign: 'center', marginBottom: '30px' }}>
        Order Review
      </Title>

      <Card
        style={{ marginBottom: '20px' }}
        title={<Title level={4}>Shipping Address</Title>}
      >
               {/* <List
          itemLayout="horizontal"
          dataSource={[address]} // Wrap the single address in an array
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={<h3 style={{ margin: 0 }}>Delivery Address</h3>}
                description={`${item?.Name}, ${item?.Address}, ${item?.Locality_Town}, ${item?.City_District}, ${item?.Pin_Code}`} // Combine name and address
                style={{ fontSize: '16px', color: '#555' }}
              />
               
            </List.Item>
                      )}
                      /> */}
        
      </Card>
      <Card
        style={{ marginBottom: '20px' }}
        title={<Title level={4}>order details</Title>}
      >
        <br />
        <Text>{"patancheru"}</Text>
        <br />
        <Text></Text>
      </Card>
      <Divider />

      <div style={{ display: 'flex', justifyContent:'end', marginTop: '20px' }}>
  
        <Button size="large" type="primary" onClick={handleConfirm}>
          proceed to make payment
        </Button>
      </div>
    </div>
  );
};

export default OrderReview;