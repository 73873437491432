import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import actions from "./actions";
import AxiosInterceptor from "../../Services/axiosInterceptor";

const createReview = async(payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.post(url, body);
  return result;
};
const getReviews = async(payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.get(url);
  return result;
};
const deleteReview = async(payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.delete(url);
  return result;
};
const editReview = async(payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.put(url, body);
  return result;
};

export function* CREATE_REVIEW_SAGA() {
  yield takeEvery("CREATE_REVIEW", function* ({ payload }) {
    try {
      const res = yield call(createReview, payload);
      if (res.status_code===1) {
        yield put({
          type: actions.CREATE_REVIEW_SUCCESS,
          message: res.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.CREATE_REVIEW_ERROR,
        errorMessage: "Something went wrong. Please try after some time.",
      });
    }
  });
}
export function* GET_REVIEWS_SAGA() {
  yield takeEvery("GET_PRODUCT_REVIEWS", function* ({ payload }) {
    try {
      const res = yield call(getReviews, payload);
      if (res.status_code===1) {
        yield put({
          type: actions.GET_PRODUCT_REVIEW_SUCCESS,
          data: res?.data,
        });
      }
      
    } catch (error) {
      yield put({
        type: actions.GET_PRODUCT_REVIEW_ERROR,
        errorMessage: "Something went wrong. Please try after some time.",
      });
    }
  });
}
export function* EDIT_REVIEW_SAGA() {
  yield takeEvery("EDIT_PRODUCT_REVIEW", function* ({ payload }) {
    try {
      const res = yield call(editReview, payload);
      if (res.status_code===1) {
        yield put({
          type: actions.EDIT_PRODUCT_REVIEW_SUCCESS,
          message: res.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.EDIT_PRODUCT_REVIEW_ERROR,
        errorMessage: "Something went wrong. Please try after some time.",
      });
    }
  });
}
export function* DELETE_REVIEW_SAGA() {
  yield takeEvery("DELETE_PRODUCT_REVIEW", function* ({ payload }) {
    try {
      const res = yield call(deleteReview, payload);
      if (res.status_code===1) {
        yield put({
          type: actions.DELETE_PRODUCT_REVIEW_SUCCESS,
          message: res.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.DELETE_PRODUCT_REVIEW_ERROR,
        errorMessage: "Something went wrong. Please try after some time.",
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(CREATE_REVIEW_SAGA)]);
  yield all([fork(GET_REVIEWS_SAGA)]);
  yield all([fork(EDIT_REVIEW_SAGA)]);
  yield all([fork(DELETE_REVIEW_SAGA)]);
}
