// reducer.js
import actions from "./actions";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  isFetching: false,
  successMessage: "",
  errorMessage: "",
  actionType: "",
  status_code: 0,
  useraddressdetails: [],
  data:[]
});

export default function userAddress(state = initialState, action) {
  switch (action.type) {
    case actions.ADD_ADDRESS_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.ADD_ADDRESS_SUCCESS:
      return Immutable.merge(state, {
        actionType: "ADD_ADDRESS_SUCCESS",
        successMessage: action.message,
      });
    case actions.ADD_ADDRESS_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: "ADD_ADDRESS_ERROR",
        errorMessage: action.errorMessage,
      });

    case actions.GET_ADDRESS_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.GET_ADDRESS_SUCCESS:
      return Immutable.merge(state, {
        actionType: "GET_ADDRESS_SUCCESS",
        successMessage: action.message,
        useraddressdetails: action.data,
      });
    case actions.GET_ADDRESS_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: "GET_ADDRESS_ERROR",
        errorMessage: action.errorMessage,
      });

    case actions.EDIT_ADDRESS_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.EDIT_ADDRESS_SUCCESS:
      return Immutable.merge(state, {
        actionType: "EDIT_ADDRESS_SUCCESS",
        successMessage: action.message,
      });
    case actions.EDIT_ADDRESS_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: "EDIT_ADDRESS_ERROR",
        errorMessage: action.errorMessage,
      });

    case actions.DELETE_ADDRESS_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.DELETE_ADDRESS_SUCCESS:
      return Immutable.merge(state, {
        actionType: "DELETE_ADDRESS_SUCCESS",
        successMessage: action.message,
      });
    case actions.DELETE_ADDRESS_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: "DELETE_ADDRESS_ERROR",
        errorMessage: action.errorMessage,
      });



    case actions.SEND_PINCODE_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.SEND_PINCODE_SUCESS:
      return Immutable.merge(state, {
        actionType: "SEND_PINCODE_SUCESS",
        data:action.data,
        successMessage: action.message,
        isFetching: false,
      });
    case actions.SEND_PINCODE_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: "SEND_PINCODE_ERROR",
        errorMessage: action.errorMessage,
      });

      case actions.GET_STATE_REQUEST:
        return Immutable.merge(state, {
          isFetching: true,
        });
      case actions.GET_STATE_SUCCESS:
        return Immutable.merge(state, {
          actionType: "GET_STATE_SUCCESS",
          successMessage: action.message,
          stateslist: action.data,
        });
      case actions.GET_STATE_ERROR:
        return Immutable.merge(state, {
          isFetching: false,
          actionType: "GET_STATE_ERROR",
          errorMessage: action.errorMessage,
        });
  
    case actions.RESET_ACTIONTYPE:
      return Immutable.merge(state, {
        actionType: "",
        successMessage: "",
        citylist: [],
        isFetching: false,
        data:[]
      });
    default:
      return state;
  }
}
