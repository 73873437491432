// sagas.js
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import actions from "./actions";
import AxiosInterceptor from "../../Services/axiosInterceptor";
import axios from "axios";

const addAddress = async(payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.post(url, body);
  return result;
};
const getAddress = async(payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.get(url);
  return result;
};
const deleteaddress = async(payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.delete(url, body);
  return result;
};
const editaddress = async(payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.put(url, body);
  return result;
};
const getstates = async(payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.get(url);
  return result;
};
const sendpincode = async(payload) => {
  const { url } = payload;
  const result = axios.get(url)
  return result;
};

export function* useraddAddressSaga() {
  yield takeEvery("ADD_ADDRESS_REQUEST", function* ({ payload }) {
    try {
      const res = yield call(addAddress, payload);
      if (res) {
        yield put({
          type: actions.ADD_ADDRESS_SUCCESS,
          message: res.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.ADD_ADDRESS_ERROR,
        errorMessage: "Something went wrong. Please try after some time.",
      });
    }
  });
}
export function* userGetAddressSaga() {
  yield takeEvery("GET_ADDRESS_REQUEST", function* ({ payload }) {
    try {
      const res = yield call(getAddress, payload);
      if (res) {
        yield put({
          type: actions.GET_ADDRESS_SUCCESS,
          message: res.message,
          data: res.user,
        });
      }
    } catch (error) {
      yield put({
        type: actions.GET_ADDRESS_ERROR,
        errorMessage: "Something went wrong. Please try after some time.",
      });
    }
  });
}
export function* usereditAddressSaga() {
  yield takeEvery("EDIT_ADDRESS_REQUEST", function* ({ payload }) {
    try {
      const res = yield call(editaddress, payload);
      if (res) {
        yield put({
          type: actions.EDIT_ADDRESS_SUCCESS,
          message: res.message,
          status_code: res.status_code,
        });
      }
    } catch (error) {
      yield put({
        type: actions.EDIT_ADDRESS_ERROR,
        errorMessage: "Something went wrong. Please try after some time.",
      });
    }
  });
}
export function* userdeleteAddressSaga() {
  yield takeEvery("DELETE_ADDRESS_REQUEST", function* ({ payload }) {
    try {
      const res = yield call(deleteaddress, payload);
      if (res) {
        yield put({
          type: actions.DELETE_ADDRESS_SUCCESS,
          message: res.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.DELETE_ADDRESS_ERROR,
        errorMessage: "Something went wrong. Please try after some time.",
      });
    }
  });
}
export function* usergetstatesSaga() {
  yield takeEvery("GET_STATE_REQUEST", function* ({ payload }) {
    try {
      const res = yield call(getstates, payload);
      if (res) {
        yield put({
          type: actions.GET_STATE_SUCCESS,
          data: res.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.GET_STATE_ERROR,
        errorMessage: "Something went wrong. Please try after some time.",
      });
    }
  });
}
export function* usergetpincodeSaga() {
  yield takeEvery("SEND_PINCODE_REQUEST", function* ({ payload }) {
    try {
      const res = yield call(sendpincode, payload);
      if (res) {
        yield put({
          type: actions.SEND_PINCODE_SUCESS,
          data:res?.data[0]?.PostOffice,
          message: res.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.SEND_PINCODE_ERROR,
        errorMessage: "Something went wrong. Please try after some time.",
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(userGetAddressSaga)]);
  yield all([fork(useraddAddressSaga)]);
  yield all([fork(usereditAddressSaga)]);
  yield all([fork(userdeleteAddressSaga)]);
  yield all([fork(usergetstatesSaga)]);
  yield all([fork(usergetpincodeSaga)]);
}
