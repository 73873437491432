import actions from "../wishlist/actions";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  isFetching: false,
  errorMessage: "",
  successMessage: "",
  actionType: "",
  status_code: 0,
  wishlist: {},
});

export default function wishlistReducer(state = initialState, action) {
  switch (action.type) {
    case actions.ADD_TO_WISHLIST:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.ADD_TO_WISHLIST_SUCCESS:
      return Immutable.merge(state, {
        actionType: "ADD_TO_WISHLIST_SUCCESS",

        successMessage: action.message,
        status_code: action.status_code,
      });
    case actions.ADD_TO_WISHLIST_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: "ADD_TO_WISHLIST_ERROR",
        errorMessage: action.errorMessage,
        successMessage: action.message,
        status_code: action.status_code,
      });

    case actions.DELETE_FROM_WISHLIST:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.DELETE_FROM_WISHLIST_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: "DELETE_FROM_WISHLIST_SUCCESS",
        successMessage: action.message,
        // wishlist: action.data,
        status_code: action.status_code,
      });
    case actions.DELETE_FROM_WISHLIST_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: "DELETE_FROM_WISHLIST_ERROR",
        errorMessage: action.payload.error,
      });
    case actions.UPDATE_WISHLIST:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.UPDATE_WISHLIST_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: "UPDATE_WISHLIST_SUCCESS",
        status_code:action.status_code,
        successMessage:action.message
      });
    case actions.UPDATE_WISHLIST_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: "UPDATE_WISHLIST_ERROR",
        errorMessage: action.payload.error,
      });
    case actions.GET_WISHLIST:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case actions.GET_WISHLIST_SUCCESS:
      return Immutable.merge(state, {
        wishlist: action.data,
        isFetching: false,
        actionType: "GET_WISHLIST_SUCCESS",
      });
    case actions.GET_WISHLIST_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: "GET_WISHLIST_ERROR",
      });
    case actions.RESET_ACTIONTYPE:
      return Immutable.merge(state, {
        actionType: "",
        errorMessage: "",
      });
    default:
      return state;
  }
}
