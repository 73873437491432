import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import actions from "../Logout/actions";
import AxiosInterceptor from "../../Services/axiosInterceptor";

const logout = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.get(url);
  return result;
};
export function* LogoutRequest() {
  yield takeEvery("LOGOUT_REQUEST", function* ({ payload }) {
    try {
      const res = yield call(logout, payload);
      if (res) {
        yield put({
          type: actions.LOGOUT_SUCCESS,
          status_code: res.status_code,
          successMessage: res.message,
        });
        console.log(res);
      } else {
        yield put({
          type: actions.LOGOUT_ERROR,
          errorMessage: "OTP verification failed. Please try again.",
        });
      }
    } catch (error) {
      yield put({
        type: actions.LOGIN_OTP_VERIFY_ERROR,
        errorMessage:
          "Something went wrong during OTP verification. Please try again.",
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(LogoutRequest)]);
}
