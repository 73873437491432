import React from 'react'
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => {
  
    const isAuthenticated = sessionStorage.getItem("verify");
  
    if (!!isAuthenticated) {
      return <Component {...rest} />;
    }
    return <Navigate to="/login" />;
  };

export default PrivateRoute
